import React, { useState, useEffect } from "react";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type CommentModalProps ={
  highlight:any,
  show:boolean,
  onCancel:()=>void,
  onSave: Function 
}
const CommentModalComponent = ({ highlight, show, onCancel, onSave }:CommentModalProps) => {
  
  /* ********* State ********* */
  const [displayComment, setDisplayComment] = useState(
    highlight && highlight.comment != null ? highlight.comment : ""
  );

  /* ********* Effects ********* */
  useEffect(() => {
    if (highlight != null) setDisplayComment(highlight.comment);
    else setDisplayComment("");
  }, [highlight]);

  return (
    show ? (
      <>
      <Modal
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={onCancel}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          <Translate>Highlight.Modal.Header</Translate>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className="highlight-modal">{highlight.text}</div>
          <div className="d-flex flex-row top-20">
            <label>
              <Translate>Comment.Modal.Header</Translate>
            </label>
            <textarea
            className="w-100"
              value={displayComment}
              onChange={(e) => setDisplayComment(e.target.value)}
            ></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer>
        <Button  
          className="btn-danger"
          onClick={onCancel}>
          <Translate>close</Translate>
        </Button>
        <Button
          className="btn-success"
          onClick={() => onSave(displayComment)}
        >
          <Translate>accept</Translate>
        </Button>
        </Modal.Footer>
      </Modal>



      {/* <div className="commenModal">
        <p>{highlight.text}</p>
        <div className="container">
          <div className="modal-header">
            <h2>
              <Translate>Highlight.Modal.Header</Translate>
            </h2>
            <button className="modal-close" onClick={onCancel}>
              <span className="material-icons">close</span>
            </button>
          </div>
          <div className="highlight-modal">{highlight.text}</div>
          <div className="commentText">
            <label>

              <Translate>Comment.Modal.Header</Translate>
            </label>
            <textarea
              value={displayComment}
              onChange={(e) => setDisplayComment(e.target.value)}
            ></textarea>

            <div className="buttonCommands">
              <a className="btn btn-warning" onClick={onCancel}>
                <Translate>close</Translate>
              </a>
              <a
                className="btn btn-success"
                onClick={() => onSave(displayComment)}
              >
                <Translate>accept</Translate>
              </a>
            </div>
          </div>
        </div>
      </div> */}
      
      </>


    )
    :null
  )
};

export default CommentModalComponent;
