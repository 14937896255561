import React from "react";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Translate from "translate-components";

type AddressCollectorProps = {
  screenTitle: any;
  businessName: string;
  setBusinessName: Function;
  name: string;
  setName: Function;
  lastName: string;
  setLastName: Function;
  address: string;
  setAddress: Function;
  address2: string;
  setAddress2: Function;
  email: string;
  setEmail: Function;
  city: string;
  setCity: Function;
  selectedCountry: string;
  selectedState: string;
  setSelectedCountry: Function;
  setSelectedState: Function;
  zipCode: string;
  setZipCode: Function;
  displayEmail: boolean;
  lang: string;
  countriesFilter: Array<any>;
  preferredLanguage: string;
  setPreferredLanguage: Function;
  emailConfirmation: string;
  setEmailConfirmation: Function;
  showNameError: boolean;
  showEmailNotMatchError: boolean;
  showShippingCountryError: boolean;
  showLastNameError: boolean;
};

const AddressCollector = (props: AddressCollectorProps) => {
  const countries = [
    { name: "Other", short: "ot", lang: "en" },
    { name: "Otro", short: "ot", lang: "es" },
    { name: "United States of America", short: "us", lang: "en" },
    { name: "Estados Unidos de América", short: "us", lang: "es" },
    { name: "Mexico", short: "mx", lang: "en" },
    { name: "México", short: "mx", lang: "es" },
    { name: "China", short: "cn", lang: "en" },
    { name: "China", short: "cn", lang: "es" },
    { name: "Singapore", short: "sg", lang: "en" },
    { name: "Singapore", short: "sg", lang: "es" },
    { name: "Costa Rica", short: "cr", lang: "en" },
    { name: "Costa Rica", short: "cr", lang: "es" },
    { name: "Panama", short: "pa", lang: "en" },
    { name: "Panamá", short: "pa", lang: "es" },
    { name: "Nicaragua", short: "ni", lang: "en" },
    { name: "Nicaragua", short: "ni", lang: "es" },
    { name: "Honduras", short: "hn", lang: "en" },
    { name: "Honduras", short: "hn", lang: "es" },
    { name: "El Salvador", short: "sv", lang: "en" },
    { name: "El Salvador", short: "sv", lang: "es" },
    { name: "Guatemala", short: "gt", lang: "en" },
    { name: "Guatemala", short: "gt", lang: "es" },
    { name: "Belize", short: "bz", lang: "en" },
    { name: "Belize", short: "bz", lang: "es" },
    { name: "Dominican Replic", short: "do", lang: "en" },
    { name: "República Dominicana", short: "do", lang: "es" },
    { name: "Cuba", short: "cu", lang: "en" },
    { name: "Cuba", short: "cu", lang: "es" },
    { name: "Puerto Rico", short: "pr", lang: "en" },
    { name: "Puerto Rico", short: "pr", lang: "es" },
  ];

  const languages = [
    { name: "English", value: "en" },
    { name: "Español", value: "es" },
  ];

  const filtered =
    props.countriesFilter.length === 0
      ? countries.filter((item) => item.lang === props.lang)
      : countries
          .filter((item) => item.lang === props.lang)
          .filter((item) => props.countriesFilter.indexOf(item.short) !== -1);

  return (
    <div className="card width-100p padding-all-10 bottom-10">
      <h4 className="bottom-0">{props.screenTitle}</h4>
      <hr className="top-0" />
      <div className="row">
        <div className="col-12 col-md-6">
          <Form.Group>
            <Form.Label className="mb-0 mt-3">
              <Translate>
                subscription.plan.address.collector.first.name
              </Translate>
              :&nbsp;
            </Form.Label>
            {props.showNameError && (
              <Form.Label className="mb-0 mt-3">
                <span className="text-warning">
                  <Translate>
                    subscription.error.message.name.is.required
                  </Translate>
                </span>
              </Form.Label>
            )}
            <Form.Control
              autoFocus
              required
              value={props.name}
              onChange={(e) => props.setName(e.target.value)}
            />
          </Form.Group>
        </div>
        <div className="col-12 col-md-6">
          <Form.Group>
            <Form.Label className="mb-0 mt-3">
              <Translate>
                subscription.plan.address.collector.last.name
              </Translate>
              :&nbsp;
            </Form.Label>
            {props.showLastNameError && (
              <Form.Label className="mb-0 mt-3">
                <span className="text-warning">
                  <Translate>
                    subscription.error.message.lastname.is.required
                  </Translate>
                </span>
              </Form.Label>
            )}
            <Form.Control
              required
              value={props.lastName}
              onChange={(e) => props.setLastName(e.target.value)}
            />
          </Form.Group>
        </div>
        {props.displayEmail ? (
          <>
            <div className="col-12">
              <Form.Group>
                <Form.Label className="mb-0 mt-3">Email:</Form.Label>
                <Form.Control
                  type="email"
                  required
                  value={props.email}
                  onChange={(e) => props.setEmail(e.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                    return false;
                  }}
                />
                <Form.Text
                  id="passwordHelpBlock"
                  className="inputInstruction text-warning font-weight-bold mb-0 mt-3"
                ></Form.Text>
              </Form.Group>
            </div>
            <div className="col-12">
              <Form.Group>
                <Form.Label className="mb-0 mt-3">
                  <Translate>subscription.plan.email.confirmation</Translate>
                  :&nbsp;
                </Form.Label>
                {props.showEmailNotMatchError && (
                  <Form.Label className="mb-0 mt-3">
                    <span className="text-warning">
                      <Translate>
                        subscription.error.message.emails.dont.match
                      </Translate>
                    </span>
                  </Form.Label>
                )}
                <Form.Control
                  type="email"
                  required
                  value={props.emailConfirmation}
                  onChange={(e) => props.setEmailConfirmation(e.target.value)}
                  onCopy={(e) => {
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                    return false;
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    e.nativeEvent.stopImmediatePropagation();
                    return false;
                  }}
                />
                <Form.Text
                  id="passwordHelpBlock"
                  className="inputInstruction text-warning font-weight-bold mt-0"
                >
                  <Translate>
                    subscription.plan.address.collector.email.message
                  </Translate>
                </Form.Text>
              </Form.Group>
            </div>
          </>
        ) : null}
        <div className="col-12 col-md-6">
          <Form.Group>
            <Form.Label className="mb-0 mt-3">
              <Translate>subscription.plan.address.collector.country</Translate>
              :
            </Form.Label>
            <Form.Control
              as="select"
              value={props.selectedCountry}
              isValid={props.selectedCountry !== ""}
              onChange={(e) => props.setSelectedCountry(e.target.value)}
            >
              {filtered.map((item) => {
                return (
                  <option key={item.short} value={item.short}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>

        <div className="col-12 col-md-6">
          <Form.Group>
            <Form.Label className="mb-0 mt-3">
              <Translate>
                subscription.plan.address.collector.preferred.language
              </Translate>
              :
            </Form.Label>
            <Form.Control
              as="select"
              value={props.preferredLanguage}
              isValid={props.preferredLanguage !== ""}
              onChange={(e) => props.setPreferredLanguage(e.target.value)}
            >
              {languages.map((item) => {
                return (
                  <option key={item.value} value={item.value}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>

        <div className="col-12 col-md-12 hidden">
          <div className="form-check">
            <input className="form-check-input" type="checkbox" />
            <label className="form-check-label" htmlFor="flexCheckDefault">
              &nbsp;
              <Translate>
                subscription.plan.address.collector.buy.without.phase.1
              </Translate>
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

AddressCollector.propTypes = {
  screenTitle: PropTypes.object,
  businessName: PropTypes.string,
  setBusinessName: PropTypes.func.isRequired,
  name: PropTypes.string,
  setName: PropTypes.func.isRequired,
  lastName: PropTypes.string,
  setLastName: PropTypes.func.isRequired,
  address: PropTypes.string,
  setAddress: PropTypes.func.isRequired,
  address2: PropTypes.string,
  setAddress2: PropTypes.func.isRequired,
  email: PropTypes.string,
  setEmail: PropTypes.func.isRequired,
  city: PropTypes.string,
  setCity: PropTypes.func.isRequired,
  selectedCountry: PropTypes.string,
  selectedState: PropTypes.string,
  setSelectedCountry: PropTypes.func.isRequired,
  setSelectedState: PropTypes.func.isRequired,
  zipCode: PropTypes.string,
  setZipCode: PropTypes.func.isRequired,
  displayEmail: PropTypes.bool.isRequired,
  lang: PropTypes.string,
  countriesFilter: PropTypes.array,
  preferredLanguage: PropTypes.string,
  setPreferredLanguage: PropTypes.func.isRequired,
  emailConfirmation: PropTypes.string,
  setEmailConfirmation: PropTypes.func,
  showNameError: PropTypes.bool,
  showEmailNotMatchError: PropTypes.bool,
  showShippingCountryError: PropTypes.bool,
  showLastNameError: PropTypes.bool,
};

export default AddressCollector;
