import moment from 'moment';

export function getFormattedDateTime(date = new Date()) {
  return `${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${padLeadingZero(date.getMinutes())}:${padLeadingZero(date.getSeconds())}`;
}

export function padLeadingZero(value) {
  return value > 9 ? value : `0${value}`;
}

export function HasExpired(expireDate) {
  const expirationDate = moment(new Date(expireDate));
  return expirationDate.isBefore(moment());
}