import { HasExpired } from "../../utils/dates";

const applicationHelper = {
  isLoggedIn(accessToken, expireDate, userId, email) {
    if (String(userId).length <= 0) {
      return false;
    }

    if (
      accessToken === undefined ||
      expireDate === undefined ||
      userId === undefined ||
      userId === "" ||
      email === null ||
      email === undefined
    ) {
      return false;
    }

    return (
      Boolean(accessToken) &&
      String(userId).length > 0 &&
      !HasExpired(expireDate) &&
      email !== ""
    );
  }
};

export default applicationHelper;
