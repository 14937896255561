import React, { isValidElement, useEffect, useState } from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Translate from "translate-components";
import ProductItemUsedLicense from "./ProductItemUsedLicense";
//-----------Components------//
import * as actions from "../../data/EmployeeLicense/employeeLicenseActions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
const ProductItemComponent = ({ product }) => {

  const requestDeparted = useSelector(
    (state: RootStateOrAny) => state.employeeLicense.requestDeparted
  );
  
  const [employeeFirstName, setEmployeeFirstName] = useState("");
  const [employeeLastName, setEmployeeLastName] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [isEmailvalid, setIsEmailValid] = useState(false);
  const [isFirstNamevalid, setIsFirstNamevalid] = useState(false);
  const [isLastNamevalid, setIsLastNamevalid] = useState(false);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  const dispath = useDispatch();
  const enrollUser = () => {
    if (isFormValid()) {
      const employee = {
        FirstName: employeeFirstName,
        LastName: employeeLastName,
        Email: employeeEmail,
      };
      setEmployeeEmail("");
      setEmployeeLastName("");
      setEmployeeFirstName("");
      dispath(actions.enrollEmployeeSeat(product.idEmployeeLicense, employee));
    }
  };

  useEffect(() => {
    setIsEmailValid(validateEmail(employeeEmail) != null);
    setIsFirstNamevalid(employeeFirstName.length >= 1);
    setIsLastNamevalid(employeeLastName.length >= 1);
  }, [employeeEmail, employeeFirstName, employeeLastName]);

  const isFormValid = () => {
    return (
      product.availableSeats > 0 &&
      validateEmail(employeeEmail) &&
      employeeFirstName.length >= 1 &&
      employeeLastName.length >= 1 && 
      product.employeeSeats.filter((es) => es.email == employeeEmail).length == 0
    );
  };
  return (
    <>
      <div className="row Productlicense">
        <div className="col-12">
          <h1 className="dash smaller">{product.productName}</h1>
          <div className="d-flex">
            <div>
              <Translate>Seats_AvailableLincenses</Translate>

              <span className="text-success fw-bold">
                {product.availableSeats}
              </span>
            </div>
            <div className="mx-2">|</div>
            <div>
              <Translate>Used_Licenses</Translate>
              <span className="text-warning fw-bold">
                {product.employeeSeats.length}
              </span>
            </div>
          </div>
        </div>
      {!requestDeparted && <>
          <div className="col-12 mt-3">
            <h4>
              <Translate>Assign_License</Translate>
            </h4>

            <div className="d-flex flex-wrap flex-md-nowrap">
              <input
                type="text"
                className={
                  isFirstNamevalid
                    ? "mx-2 my-2 width-100p"
                    : "mx-2 my-2 width-100p line-warning"
                }
                placeholder="First Name"
                value={employeeFirstName}
                onChange={(v) => setEmployeeFirstName(v.target.value)}
              />
              <input
                type="text"
                className={
                  isLastNamevalid
                    ? "mx-2 my-2 width-100p"
                    : "mx-2 my-2 width-100p line-warning"
                }
                placeholder="Last Name"
                value={employeeLastName}
                onChange={(v) => setEmployeeLastName(v.target.value)}
              />
              <input
                type="text"
                className={
                  isEmailvalid
                    ? "mx-2 my-2 width-100p"
                    : "mx-2 my-2 width-100p line-warning"
                }
                placeholder="Email"
                value={employeeEmail}
                onChange={(v) => setEmployeeEmail(v.target.value)}
              />
              <a
                href="#"
                className={
                  isFormValid()
                    ? "mx-2 my-2 btn btn-flat-papergray line-lightgray text-success"
                    : "mx-2 my-2 btn btn-flat-papergray text-lightgray"
                }
                onClick={enrollUser}
              >
                <Translate>Assign_License</Translate>
              </a>
            </div>
          </div>
          <div className="col-12 mt-3">
            <h4>
              <Translate>Used_Licenses</Translate>
            </h4>
            <div className="row">
              {product.employeeSeats?.map((es, i) => (
                <ProductItemUsedLicense
                  key={i}
                  employeeSeat={es}
                  productId={product.fkInstituteProduct}
                />
              ))}
            </div>
          </div>
        </>
        }
         {requestDeparted &&  <><Translate>Loading</Translate>   <div className="loading-icon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                </>
        }
      </div>
    </>
  );
};

export default ProductItemComponent;
