import React, { useEffect, useState } from "react";
import * as userSessionActions from "../../data/UserSession/userSessionActions";
import * as resetTokenActions from "../../data/ResetTokenPassword/resetTokenPasswordActions";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Button from "@mui/material/Button";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
const PasswordChange = (props) => {
  const dispatch = useDispatch();

  /* ********* Reducer Variables ********* */
  const errorState = useSelector(
    (state: RootStateOrAny) => state.userSession.error
  );
  const userId = useSelector((state: RootStateOrAny) => state.userSession.uid);
  const [userSession, setUserSession] = useState({
    newPassword: "",
    newPasswordConfirmation: "",
  });
  const [errorMessage, setErrorMessage] = useState(errorState);
  const [isPasswordCharactersValid, setIsPasswordCharactersValid] = useState(false);
  const [isPasswordConfirmationValid, setIsPasswordConfirmationValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordNumberOrSpecialCharValid, setIsPasswordNumberOrSpecialCharValid] = useState(false);
  const [isPasswordUpperAndLowerCaseValid, setIsPasswordUpperAndLowerCaseValid] = useState(false);
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  /* ********* Functions ********* */
  const onNewPasswordChange = (event) => {
    setUserSession({
      newPassword: event.target.value as string,
      newPasswordConfirmation: userSession.newPasswordConfirmation,
    });
    setErrorMessage("");
  };

  const onNewPasswordConfirmationChange = (event) => {
    setUserSession({
      newPassword: userSession.newPassword,
      newPasswordConfirmation: event.target.value,
    });
    setErrorMessage("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleUpdate();
    }
  };

  const handleUpdate = () => {
    if(!checkIsPasswordValid(userSession.newPassword, userSession.newPasswordConfirmation))
    {
      return;
    }
    
    if (props.pvalidation != null) {
      let params = {
        UserId: props.pvalidation.userId,
        NewPassword: userSession.newPassword,
        NewPasswordConfirmation: userSession.newPasswordConfirmation,
      };
      dispatch(resetTokenActions.updatePassword(props.ptoken, params));
    } else {
      dispatch(
        userSessionActions.updatePassword(
          userId,
          userSession.newPassword,
          userSession.newPasswordConfirmation
        )
      );
    }
    setUserSession({ newPassword: "", newPasswordConfirmation: "" });
    setErrorMessage("");
    props.onClose();
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return (
        <div className="badLogin">
          <Translate>{errorMessage}</Translate>
        </div>
      );
    }
  };

  useEffect(()=>{

    setIsPasswordUpperAndLowerCaseValid(checkDoesPasswordContainUpperAndLowerCase(userSession.newPassword));
    setIsPasswordNumberOrSpecialCharValid(checkDoesPasswordContainNumberOrSpecialChar(userSession.newPassword));
    setIsPasswordLengthValid(checkPasswordLengthValid(userSession.newPassword));
    setIsPasswordConfirmationValid(checkIsPasswordConfirmationValid(userSession.newPassword, userSession.newPasswordConfirmation));
    setIsPasswordValid(checkIsPasswordValid(userSession.newPassword, userSession.newPasswordConfirmation));

  },[userSession.newPassword,userSession.newPasswordConfirmation, userSession])


  const checkPasswordLengthValid = (password) => password.length >= 8;
  const checkIsPasswordConfirmationValid = (password, passwordConfirmation) => password === passwordConfirmation;
  const checkDoesPasswordContainUpperAndLowerCase = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    return hasUpperCase && hasLowerCase;
  };
  const checkDoesPasswordContainNumberOrSpecialChar = (password: string) => {
    const hasNumberOrSpecialChar = /[0-9!@#$%^&*]/.test(password);
    return hasNumberOrSpecialChar;
  };
  const checkIsPasswordValid = (password: string, passwordConfirmation: string) => {
    return checkPasswordLengthValid(password) && 
           checkIsPasswordConfirmationValid(password, passwordConfirmation) &&
           checkDoesPasswordContainUpperAndLowerCase(password) &&
           checkDoesPasswordContainNumberOrSpecialChar(password);
  };

  return (
    <>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={props.show ?? true}
        onHide={props.onClose}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h1 className="forgot-title">
            <Translate>login.forgotpassword.title</Translate>
          </h1>

          <label>
            <Translate>Enter.New.Password.title</Translate>
          </label>
          <input
            id="txtNewPassword"
            name="NewPassword"
            className="passworText"
            required={true}
            type="password"
            onChange={onNewPasswordChange}
            onKeyPress={handleKeyPress}
            value={userSession.newPassword}
          />
          <label className="top-20">
            <Translate>Enter.Confirmation.title</Translate>
          </label>
          <input
            id="txtNewPasswordConfirm"
            name="NewPasswordConfirm"
            className="passworText"
            required={true}
            type="password"
            onChange={onNewPasswordConfirmationChange}
            onKeyPress={handleKeyPress}
            value={userSession.newPasswordConfirmation}
          />

          <ul className="passwordCheckList">
            <li className={isPasswordUpperAndLowerCaseValid?"valid":"invalid"}><span className="icon"></span><span> </span> Include both lower and upper case characters</li>
            <li className={isPasswordNumberOrSpecialCharValid?"valid":"invalid"}><span className="icon"></span><span> </span>  At least one number or special character</li>
            <li className={isPasswordLengthValid?"valid":"invalid"}><span className="icon"></span><span> </span>  A least 8 characters long</li>
            <li className={isPasswordConfirmationValid?"valid":"invalid"}><span className="icon"></span><span> </span>  Match confirmation</li>
          </ul>
          {renderErrorMessage()}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="contained"
            size="large"
            style= { isPasswordValid ? { backgroundColor: "#face30", color: "#292929" } : { backgroundColor: "#808080"}}
            onClick={handleUpdate}
          >
            <Translate>Update.password.title</Translate>
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Dialog
      open={props.show ?? true}
      onClose={props.onClose}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogContent>

      </DialogContent>
      <DialogActions className="passwor-button-submit bottom-20">
        <Button
          variant="contained"
          size="large"
          style={{ backgroundColor: "#face30", color: "#292929" }}
          onClick={handleUpdate}
        >
          <Translate>Update.password.title</Translate>
        </Button>
      </DialogActions>
          </Dialog> */}
    </>
  );
};

export default PasswordChange;
