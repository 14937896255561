import React, { useEffect, useState } from "react";
import Header from "../shared/Header";
import Footer from "../shared/Footer";
import Translate from "translate-components";

import * as actions from "../../data/EmployeeLicense/employeeLicenseActions";

import ProductItemComponent from "./ProductItem";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
//-----------Components------//

const UserAdmin = () => {
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const productItems = useSelector(
    (state: RootStateOrAny) => state.employeeLicense.products
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    if (!userSession.isLoggedIn()) {
      navigate("/");
    }
    dispatch(actions.retrieveEmployeeLincense(userSession.userId));
  }, []);

  return (
    <>
      <Header />

      <div className="padding-top-100 bkg-papergray border-b-lightgray">
        <div className="container">
          <div className="row">
            <div className="col-12">
              {/* <h1 className="dash">
                Admin
              </h1> */}
            </div>
            <div className="d-flex flex-wrap">
              <a className="dashTabs active">
                {/* <Translate>Dashboard_All_My_Courses</Translate> */}
                <Translate>License</Translate>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="adminLicenses">
        <div className="container">
          {productItems?.map((p, i) => (
            <ProductItemComponent key={i} product={p} />
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default UserAdmin;
