import React from "react";
import PropTypes from "prop-types";
import Translate from "translate-components";

/* ********* Components ********* */
import CourseMaterialTab from "./MaterialsTabs";
import CourseMaterialsCards from "./MaterialsCards";

type CourseMaterialsProps={
  id:string
  module?:any,
  selectedGrade?:any,
  version:number,
  data:any,
  hidelinks?:boolean
}
const CourseMaterialsComponent = ({
  id,
  module,
  selectedGrade,
  version,
  data,
  hidelinks,
}: CourseMaterialsProps) => (
  <>
    <div id={id} className="container">
      <div className="row">
        {module == null && selectedGrade != null && (
          <div className="col-12 col-md-6 bottom-20">
            <CourseMaterialsCards
              key="curriculumns"
              name={
                <div>
                  <Translate>{selectedGrade.name}</Translate>
                </div>
              }
              items={selectedGrade.curriculumns}
              version={version}
              module={module}
            ></CourseMaterialsCards>
          </div>
        )}

        {module != null && (
          <div className="col-12 bottom-20">
            <CourseMaterialsCards
              key={"otherMaterials"}
              name={
                <Translate>
                  system1_dashboard_tgr_vive_other_materials
                </Translate>
              }
              items={data.othersMaterials}
              module={module}
              version={undefined}
            ></CourseMaterialsCards>
          </div>
        )}

        {(hidelinks == null || hidelinks==false) && (
          <div className="col-12 col-md-6 bottom-20">
            <CourseMaterialsCards
              key={"yesAppLinks"}
              name={<Translate>system1_dashboard_tgr_vive_app_links</Translate>}
              items={data.tgrAppLinks}
              module={undefined}
              version={undefined}
            ></CourseMaterialsCards>
          </div>
        )}
      </div>
    </div>
  </>
);


export default CourseMaterialsComponent;
