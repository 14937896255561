import imgFacebookLetter from "../assets/images/social-media/001-facebook-letter-logo.svg";
import imgTwitter from "../assets/images/social-media/003-twitter-logo-silhouette.svg";
import imgInstagram from "../assets/images/social-media/005-instagram.svg";

const stopDriftingTheme = {
  background: "#e1e1e1",
  color: "#0B0B0B",
  //Header Menu  & Menu Items
  header: {
    color: "#0B0B0B",
    background: "#e1e1e1",
    menu_item: {
      color: "#0B0B0B",
    },
    menu_item: {
      color: "#0B0B0B",
      background: "#e1e1e1",
    },
    inner_item: {
      color: "#0B0B0B",
      background: "#e1e1e1",
    },
  },
  footer: "LandingPage.Footer.PrivacyPolicy.Stopdrifting",
  mediaLinks: [
    {
      href: "https://www.facebook.com/DavidIbarraStopDrifting",
      target: "_blank",
      title: "facebook",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgFacebookLetter,
    },
    {
      href: "https://twitter.com/davidibarra",
      target: "_blank",
      title: "twitter",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgTwitter,
    },
    {
      href: "https://www.instagram.com/david.r.ibarra/",
      target: "_blank",
      title: "instagram",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgInstagram,
    },
  ],
  showBrowseCategories: false,
};
export default stopDriftingTheme;
