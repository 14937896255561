import * as types from "../constants/actionTypes";

const initialState = {
  error: null,
  instituteQuizPage: null,
  timeRemaining: null,
  answerQuestionByQuiz: new Map(),
  isLoaded: false,
  disableFinishButton: true,

  //Completed QUIZ
  isCompleted: false,
  instituteQuizResults: null,
};

export default function quizReducer(state = initialState, action) {
  switch (action.type) {
    case types.QUIZ_CREATE_QUIZ: {
      const { data } = action.payload;

      const answers = new Map();
      if (data != null && data.instituteQuestionsByQuiz != null) {
        data.instituteQuestionsByQuiz.forEach((a) => {
          if (a.fkInstituteQuizAnswer > 0) {
            answers.set(a.id, {
              IdInstituteQuestionByQuiz: a.id,
              IdInstituteQuizAnswer: a.fkfkInstituteQuizAnswer,
            });
          }
        });
      }

      return {
        ...state,
        instituteQuizPage: data,
        answerQuestionByQuiz: answers,
        isLoaded: true,
      };
    }

    case types.QUIZ_RETRIEVE_TIME_REMAINING: {
      const { data } = action.payload;
      return {
        ...state,
        timeRemaining: data,
      };
    }

    case types.QUIZ_ANSWER_QUESTIONS: {
      const {
        fkInstituteQuizQuestion,
        fkfkInstituteQuizAnswer,
      } = action.payload;
      const answers = new Map(state.answerQuestionByQuiz);
      answers.set(fkInstituteQuizQuestion, {
        IdInstituteQuestionByQuiz: fkInstituteQuizQuestion,
        IdInstituteQuizAnswer: fkfkInstituteQuizAnswer,
      });
      return {
        ...state,
        answerQuestionByQuiz: answers,
        disableFinishButton: false,
      };
    }

    case types.QUIZ_COMPLETE_QUIZ_BY_ID: {
      const { data, isBackButton } = action.payload;
      return {
        ...state,
        isCompleted: true,
        instituteQuizResults: data,
        isBackButton: isBackButton,
        disableFinishButton: false,
        instituteQuizPage: null,
      };
    }

    case types.RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_FULFILLED: {
      return {
        ...state,
        instituteQuizPage: action.payload.data,
      };
    }
    case types.RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_EJECTED: {
      return {
        ...state,
        instituteQuizPage: null,
      };
    }

    default:
      return state;
  }
}
