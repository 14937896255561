import React, { useEffect, useState } from "react";
import * as userSessionActions from "../../data/UserSession/userSessionActions";
import { connect, useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { ClipLoader } from "react-spinners";
import Button from "@mui/material/Button";
import Modal from "react-bootstrap/Modal";
import userSessionExtension from "../../data/UserSession/userSessionExtension";
import queryString from "query-string";
import PasswordUpdate from "./PasswordChange";
import Translate from "translate-components";
import { Navigate, useLocation, useParams } from "react-router-dom";
import ELTComponent from "../shared/ELTComponent";
const imgLogo: string =
  require("../../assets/images/svg/LogoWhite.svg").default;

const LoginPage = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const { search } = useLocation();
  let { refreshtokenId } = useParams();

  /* ********* Reducer Variables ********* */
  const token = useSelector((state: RootStateOrAny) => state.userSession.token);
  const expire = useSelector(
    (state: RootStateOrAny) => state.userSession.expire
  );
  const appErrorMessage = useSelector(
    (state: RootStateOrAny) => state.userSession.error
  );
  const userHasLogged = useSelector(
    (state: RootStateOrAny) => state.userSession.userHasLogged
  );
  const userIsLoggedIn = useSelector((state: RootStateOrAny) =>
    state.userSession.isLoggedIn()
  );
  const userId = useSelector((state: RootStateOrAny) => state.userSession.uid);
  const selectedLanguageCode = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const isLoading = useSelector(
    (state: RootStateOrAny) => state.application.isLoading
  );

  const [userSession, setUserSession] = useState({
    userName: "",
    password: "",
  });

  /* ********* Local State ********* */
  const [errorMessage, setErrorMessage] = useState(appErrorMessage);
  const [comeFromHandleLogin, setComeFromHandleLogin] = useState(false);
  const [comeFromTextBoxes, setComeFromTextBoxes] = useState(false);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [forgotPasswordUserName, setForgotPasswordUserName] = useState("");
  const [afterForgotPasswordOpen, setAfterForgotPasswordOpen] = useState(false);
  const [isLastStep, setIsLastStep] = useState(false);
  const [disableResetPasswordButton, setDisableResetPasswordButton] =
    useState(false);
  const [showPasswordUpdate, setShowPasswordUpdate] = useState(!userHasLogged);

  /* ********* Effects ********* */
  useEffect(() => {
    if (refreshtokenId != null) {
      dispatch(userSessionActions.authenticateUserCASToken(refreshtokenId));
    }

    //Get token using querystring
    if (search != null) {
      const { token } = queryString.parse(search);
      if (token != null && token != null) {
        dispatch(userSessionActions.authenticateUserTGRToken(token));
      }
    }

    if (token && expire && userSessionExtension.hasExpired(expire)) {
      dispatch(userSessionActions.authenticateWithToken());
    }
  }, []);

  useEffect(() => {
    setErrorMessage(appErrorMessage);
  }, [appErrorMessage]);

  useEffect(() => {
    setShowPasswordUpdate(userIsLoggedIn && !userHasLogged);
  }, [userIsLoggedIn, userHasLogged]);

  /* ********* Functions ********* */
  const onUserNameChange = (event) => {
    setUserSession({
      userName: event.target.value,
      password: userSession.password,
    });
    setErrorMessage("");
    dispatch(userSessionActions.setErrorMessage({ data: { message: "" } }));
    setComeFromHandleLogin(false);
    setComeFromTextBoxes(true);
  };

  const onPasswordChange = (event) => {
    setUserSession({
      userName: userSession.userName,
      password: event.target.value,
    });
    setErrorMessage("");
    dispatch(userSessionActions.setErrorMessage({ data: { message: "" } }));
    setComeFromHandleLogin(false);
    setComeFromTextBoxes(true);
  };

  const onForgotPasswordUserNameChange = (event) => {
    setForgotPasswordUserName(event.target.value);
    if (userSession.userName !== null || userSession.userName !== "") {
      setDisableResetPasswordButton(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  const handleForgotPasswordKeyPress = (e) => {
    if (e.key === "Enter") {
      handleForgotPassword();
    }
  };

  const handleLogin = () => {
    if (userSession.userName === null || userSession.userName === "") {
      setErrorMessage(
        "Please enter the Username in the text box above and try again."
      );
      dispatch(
        userSessionActions.setErrorMessage({
          data: {
            message:
              "Please enter the Username in the text box above and try again.",
          },
        })
      );
      setComeFromHandleLogin(true);

      return;
    }

    if (userSession.password === null || userSession.password === "") {
      setErrorMessage(
        "Please enter the Password in the text box above and try again."
      );
      dispatch(
        userSessionActions.setErrorMessage({
          data: {
            message:
              "Please enter the Password in the text box above and try again.",
          },
        })
      );
      setComeFromHandleLogin(true);
      return;
    }

    dispatch(
      userSessionActions.authenticateUser(
        userSession.userName,
        userSession.password
      )
    );
  };

  const handleForgotPasswordClose = () => {
    setForgotPasswordOpen(false);
  };

  const handleAfterForgotPasswordClose = () => {
    setAfterForgotPasswordOpen(false);
  };

  const handleForgotPasswordOpen = () => {
    setForgotPasswordOpen(true);
    setAfterForgotPasswordOpen(false);
    setForgotPasswordUserName("");
  };

  const handleForgotPassword = () => {
    if (forgotPasswordUserName === null || forgotPasswordUserName === "") {
      setErrorMessage(
        "Please enter the Username in the text box above and try again."
      );
      dispatch(
        userSessionActions.setErrorMessage({
          data: {
            message:
              "Please enter the Username in the text box above and try again.",
          },
        })
      );
      setComeFromHandleLogin(true);
      return;
    }

    dispatch(
      userSessionActions.resetPassword(
        forgotPasswordUserName,
        selectedLanguageCode,
        process.env.REACT_APP_SUPPORTED_SYSTEM
      )
    );

    handleForgotPasswordClose();
    setAfterForgotPasswordOpen(true);
  };

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <div className="badLogin"> {errorMessage} </div>;
    }
  };

  const onClosePasswordUpdate = () => {
    setShowPasswordUpdate(false);
  };

  return userIsLoggedIn && userHasLogged === true ? (
    <Navigate replace to="/dashboard" />
  ) : (
    <ELTComponent showLogin={false}>
      <div className="login-container">
        <PasswordUpdate
          show={showPasswordUpdate}
          onClose={showPasswordUpdate}
          userId={userId}
          toDashBoard={true}
        />

        <div className="container-fluid logindiv login-page">
          <img className="LogoLogin" src={imgLogo} alt="" />
          <br />
          <p>
            <Translate>Reset_Token_Login</Translate>
          </p>
          <div className="row-fluid">
            <div className="span12">
              <div className="loginTxt">
                <input
                  id="txtLoginUserName"
                  name="Username"
                  placeholder="Email"
                  required={true}
                  onChange={onUserNameChange}
                  onKeyPress={handleKeyPress}
                  value={userSession.userName}
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="row-fluid">
            <div className="span12">
              <div className="loginTxt">
                <input
                  id="txtPassword"
                  name="Password"
                  placeholder="Password"
                  required={true}
                  type="password"
                  onChange={onPasswordChange}
                  onKeyPress={handleKeyPress}
                  value={userSession.password}
                />
              </div>
            </div>
          </div>
          <div className="form-actions loginButtonContainer">
            <div className="loginCenterButton loginButtonContainer">
              <Button
                variant="contained"
                size="large"
                // className="text-background"
                // style={{ backgroundColor: "#face30", color: "#292929" }}
                className="login-btn btn btn-secondary text-background"
                disabled={isLoading}
                onClick={handleLogin}
              >
                Login &nbsp;
                <ClipLoader
                  size={15}
                  // color={"#292929"}
                  loading={isLoading}
                />
              </Button>
            </div>
            <Button
              className="resetPassBtn"
              onClick={() => handleForgotPasswordOpen()}
            >
              <Translate>login.forgotpassword.title</Translate>
            </Button>

            {renderErrorMessage()}
          </div>
          <div className="bottom-login" />
        </div>

        <Modal
          centered
          aria-labelledby="contained-modal-title-vcenter"
          show={forgotPasswordOpen}
          onHide={handleForgotPasswordClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Translate>login.forgotpassword.title</Translate>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Translate>login.forgotpassword.text</Translate>
            <div className="regularTxt">
              <input
                id="txtForgotPasswordUserName"
                name="Username"
                placeholder="Email"
                required={true}
                onChange={onForgotPasswordUserNameChange}
                onKeyPress={handleForgotPasswordKeyPress}
                value={forgotPasswordUserName}
                type="text"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={handleForgotPassword}
              color="primary"
              disabled={forgotPasswordUserName == ""}
            >
              <Translate>login.forgotpassword.title</Translate>
            </Button>
            <Button onClick={handleForgotPasswordClose}>
              <Translate>close</Translate>
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Dialog
          open={forgotPasswordOpen}
          onClose={handleForgotPasswordClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogContent>
            <div className="main-container-modal">
              <div className="home-center-modal">
                <h1 className="forgot-title">Forgot password?</h1>
                <Translate>login.forgotpassword.text</Translate>
                <div className="regularTxt">
                  <input
                    id="txtForgotPasswordUserName"
                    name="Username"
                    placeholder="Email"
                    required={true}
                    onChange={onForgotPasswordUserNameChange}
                    onKeyPress={handleForgotPasswordKeyPress}
                    value={forgotPasswordUserName}
                    type="text"
                  />
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleForgotPassword}
              color="primary"
              disabled={forgotPasswordUserName == ""}
            >
              <Translate>login.forgotpassword.title</Translate>
            </Button>
            <Button onClick={handleForgotPasswordClose}>Close</Button>
          </DialogActions>
        </Dialog> */}

        <Modal
          centered
          aria-labelledby="contained-modal-title-vcenter"
          show={afterForgotPasswordOpen}
          onHide={handleAfterForgotPasswordClose}
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              <Translate>login.password.reset</Translate>
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button onClick={handleAfterForgotPasswordClose}>
              <Translate>close</Translate>
            </Button>
          </Modal.Footer>
        </Modal>

        {/* <Dialog
          open={afterForgotPasswordOpen}
          onClose={handleAfterForgotPasswordClose}
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
        >
          <DialogContent>
            <div className="main-container-modal">
              <div className="home-modal">
                <div className="reset-body">
                  <Translate>login.password.reset</Translate>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleAfterForgotPasswordClose}>Close</Button>
          </DialogActions>
        </Dialog> */}
      </div>
    </ELTComponent>
  );
};
export default LoginPage;
