import React from "react";
import Translate from "translate-components";

/* ********* Components ********* */
import ELTComponent from "../shared/ELTComponent";


const Company = () => {
  return (
    <ELTComponent>
      <div id="company">
        <div className="company-back padding-top-50 padding-bottom-50">
          <div className="container">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="text-background top-20 bottom-20">
                  <Translate>about.section.Company</Translate>
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-sm-12 col-md-7 col-lg-8">
                <p className="text-tgrContrast">
                  <Translate>about.section.about.text2</Translate>
                </p>
              </div>
              <div className="col-12 col-sm-12 col-md-5 col-lg-4 text-center">
                <img src={"../../assets/images/ThinkGrowRichBook.png"} className="img-fluid" />
              </div>
            </div>
          </div>
        </div>

        <div className="container top-20 bottom-20">
          <div className="row">
            <div className="col text-center">
              <h1 className="text-tgrMain">
                <Translate>company.section.products.title</Translate>
              </h1>
              <hr />
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-8 col-sm-4 col-md-3">
              <img src={"../../assets/images/tgrProducts/whatYouGetTGRComposition-3-03.png"} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <h3 className="text-tgrSecondary">
                <Translate>
                  company.section.products.beyondTGRweb.title
                </Translate>
              </h3>
              <p>
                <Translate>
                  company.section.products.beyondTGRweb.description
                </Translate>
              </p>
              <button className="btn btn-success btn-sm mb-3 hidden">
                <span className="material-icons mr-1 align-middle">
                  play_circle_filled
                </span>
                <span>
                  <Translate>company.section.products.previewBtn</Translate>
                </span>
              </button>
            </div>
          </div>

          <div className="row align-items-center justify-content-center bkg-accent2">
            <div className="col-8 col-sm-4 col-md-3">
              <img src={"../../assets/images/tgrProducts/whatYouGetTGRComposition-3-06.png"} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <h3 className="text-tgrSecondary">
                <Translate>
                  company.section.products.beyondTGRmanual.title
                </Translate>
              </h3>
              <p>
                <Translate>
                  company.section.products.beyondTGRmanual.description
                </Translate>
              </p>
              <button className="btn btn-success btn-sm mb-3 hidden">
                <span className="material-icons mr-1 align-middle">
                  play_circle_filled
                </span>
                <span>
                  <Translate>company.section.products.previewBtn</Translate>
                </span>
              </button>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-8 col-sm-4 col-md-3">
              <img src={"../../assets/images/tgrProducts/whatYouGetTGRComposition-3-07.png"} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <h3 className="text-tgrSecondary">
                <Translate>company.section.products.viveListo.title</Translate>
              </h3>
              <p>
                <Translate>
                  company.section.products.viveListo.description
                </Translate>
              </p>
              <button className="btn btn-success btn-sm mb-3 hidden">
                <span className="material-icons mr-1 align-middle">
                  play_circle_filled
                </span>
                <span>
                  <Translate>company.section.products.previewBtn</Translate>
                </span>
              </button>
            </div>
          </div>

          <div className="row align-items-center justify-content-center bkg-accent2">
            <div className="col-8 col-sm-4 col-md-3">
              <img src={"../../assets/images/tgrProducts/whatYouGetTGRComposition-3-05.png"} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <h3 className="text-tgrSecondary">
                <Translate>company.section.products.NewBook.title</Translate>
              </h3>
              <p>
                <Translate>
                  company.section.products.NewBook.description
                </Translate>
              </p>
              <button className="btn btn-success btn-sm mb-3 hidden">
                <span className="material-icons mr-1 align-middle">
                  play_circle_filled
                </span>
                <span>
                  <Translate>company.section.products.previewBtn</Translate>
                </span>
              </button>
            </div>
          </div>

          <div className="row align-items-center justify-content-center">
            <div className="col-8 col-sm-4 col-md-3">
              <img src={"../../assets/images/tgrProducts/whatYouGetTGRComposition-3-02.png"} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <h3 className="text-tgrSecondary">
                <Translate>company.section.products.podcast.title</Translate>
              </h3>
              <p>
                <Translate>
                  company.section.products.podcast.description
                </Translate>
              </p>
              <button className="btn btn-success btn-sm mb-3 hidden">
                <span className="material-icons mr-1 align-middle">
                  play_circle_filled
                </span>
                <span>
                  <Translate>company.section.products.previewBtn</Translate>
                </span>
              </button>
            </div>
          </div>

          <div className="row align-items-center justify-content-center bkg-accent2">
            <div className="col-8 col-sm-4 col-md-3">
              <img src={"../../assets/images/tgrProducts/whatYouGetTGRComposition-3-04.png"} className="img-fluid" alt="" />
            </div>
            <div className="col-12 col-sm-8 col-md-9">
              <h3 className="text-tgrSecondary">
                <Translate>
                  company.section.products.stopDrifting.title
                </Translate>
              </h3>
              <p>
                <Translate>
                  company.section.products.stopDrifting.description
                </Translate>
              </p>
              <h4>
                <Translate>
                  company.section.products.stopDrifting.available
                </Translate>
              </h4>

              <a
                href="https://www.amazon.com/Stop-Drifting-Become-Thought-Positive/dp/1733915095/ref=tmm_hrd_swatch_0?_encoding=UTF8&qid=1626461019&sr=8-2"
                className="btn btn-sm btn-outlined-secondary right-10 text-main"
                target="blank"
              >
                Amazon
              </a>

              <a
                href="https://books.apple.com/us/book/stop-drifting/id1548609407"
                className="btn btn-sm btn-outlined-main text-main"
                target="blank"
              >
                iTunes
              </a>

              <button className="btn btn-success btn-sm mb-3 hidden">
                <span className="material-icons mr-1 align-middle">
                  play_circle_filled
                </span>
                <span>
                  <Translate>company.section.products.previewBtn</Translate>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </ELTComponent>
  );
};

export default Company;
