import * as types from "../constants/actionTypes";
import Service from "../services/subscriptionPlanServices";

export function getByDescriptionSubscriptionPlan(description) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });
    let subscriptionService = new Service();
    subscriptionService
      .getByDescriptionSubscriptionPlan(description)
      .then((response) => {
        dispatch({
          type: types.GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_FULFILLED,
          payload: response.data,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        let data = {};
        if (!error.response) {
          data.detail = "Please check your internet connection.";
        } else {
          data = error.response.data;
        }
        dispatch({
          type: types.GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_REJECTED,
          payload: data,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}

export function getByIdSeminar(idSeminar) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });
    let subscriptionService = new Service();
    subscriptionService
      .getByIdSeminar(idSeminar)
      .then((response) => {
        dispatch({
          type: types.GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_FULFILLED,
          payload: response.data,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        let data = {};
        if (!error.response) {
          data.detail = "Please check your internet connection.";
        } else {
          data = error.response.data;
        }
        dispatch({
          type: types.GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_REJECTED,
          payload: data,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}

export function getProductPrices() {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });
    let subscriptionService = new Service();
    subscriptionService
      .getProductPrices()
      .then((response) => {
        dispatch({
          type: types.GET_PRODUCT_PRICES_FULFILLED,
          payload: response.data,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        let data = {};
        if (!error.response) {
          data.detail = "Please check your internet connection.";
        } else {
          data = error.response.data;
        }
        dispatch({
          type: types.GET_PRODUCT_PRICES_REJECTED,
          payload: data,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}

// legacy code

export function setSubmittingOrderFlag(flagValue) {
  return function(dispatch) {
    dispatch({
      type: types.SUBMITTING_ORDER_FLAG,
      payload: flagValue,
    });
  };
}

export function setPurchaseSuccessfulFlag(flagValue) {
  return function(dispatch) {
    dispatch({
      type: types.PURCHASE_SUCCESSFUL_FLAG,
      payload: flagValue,
    });
  };
}

export function clearCart() {
  return function(dispatch) {
    dispatch({
      type: types.CLEAR_CART,
    });
  };
}

export function clearOrder() {
  return function(dispatch) {
    dispatch({
      type: types.CLEAR_ORDER,
    });
  };
}

export function clearSignUpConfirmation() {
  return function(dispatch) {
    dispatch({
      type: types.CLEAR_SIGN_UP_CONFIRMATION,
    });
  };
}

export function clearBackEndError() {
  return function(dispatch) {
    dispatch({
      type: types.CLEAR_BACKEND_ERROR,
    });
  };
}

export function deleteProductFromCart(productId) {
  return function(dispatch) {
    dispatch({
      type: types.DELETE_PRODUCT_FROM_CART,
      payload: { productId: productId },
    });
  };
}

export function updateCart(productId, newQuantity) {
  return function(dispatch) {
    dispatch({
      type: types.UPDATE_CART_QUANTITY,
      payload: { productId: productId, quantity: newQuantity },
    });
  };
}

export function addToCart(quantity) {
  return function(dispatch) {
    dispatch({ type: types.ADD_SELECTED_PRODUCT_TO_CART, payload: quantity });
  };
}

export function updateSelectedProduct(productId) {
  return function(dispatch) {
    dispatch({ type: types.UPDATE_SELECTED_PRODUCT, payload: productId });
  };
}

export function resetShippingFee() {
  return function(dispatch) {
    dispatch({ type: types.RESET_SHIPPING_FEE });
  };
}

export function clearVideoIncreasedFlag() {
  return function(dispatch) {
    dispatch({ type: types.INCREASE_VIDEO_VIEWS_REJECTED });
  };
}

export function setIsFirstSubscriptionPlanRender(value) {
  return function(dispatch) {
    dispatch({
      type: types.SET_IS_FIRST_SUBSCRIPTION_PLAN_RENDER,
      payload: value,
    });
  };
}
