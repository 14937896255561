import React from "react";
import ReactDOM from "react-dom/client";
import store from "./store/store";
import "bootstrap/dist/css/bootstrap.min.css";
import Root from "./components/Root";
// Yep, that's right. You can import SASS/CSS files too! Webpack will run the associated loader and plug this into the page.
import "./styles/styles.scss";

require("./favicon.ico"); // Tell webpack to load favicon.ico

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Root store={store} />);
