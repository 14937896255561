import React from "react";
import PropTypes from "prop-types";
import Translate from "translate-components";
import { encodeNumber } from "../../utils/strings";

type CourseQuizItemProps={
  idComponent:string | undefined,
  refComponent:any,
  course:any,
  goToReference:string,
  coursesList:[any]
}

const CourseQuizItemComponent = ({idComponent, refComponent,course, goToReference, coursesList}:CourseQuizItemProps) => {

  /* ********* Constants ********* */

  const timestamp = course.lengthTime * 60;
  const hours = Math.floor(timestamp / 60 / 60),
    minutes = Math.floor((timestamp - hours * 60 * 60) / 60),
    seconds = timestamp % 60;
  var formatted =
    (hours % 24).toString().padStart(2, "0") +
    ":" +
    minutes.toString().padStart(2, "0") +
    ":" +
    seconds.toString().padStart(2, "0");
  const isLockedStyle = course.isLocked || course.readyToDisplay == false;

/* ********* Functions ********* */

  const prerequisiteText = (c) => {
    if (typeof c == "undefined" || c === null) return "";
    var type = "";
    var icon = "";
    switch (c.fkInstituteCourseType) {
      case 1:
        type = "course_type_video";
        icon = "ondemand_video";
        break;
      case 2:
        type = "course_type_reading";
        icon = "menu_book";
        break;
      case 3:
        type = "course_type_quiz";
        icon = "fact_check";
        break;
      case 4:
        type = "course_type_discution";
        icon = "supervised_user_circle";
        break;
      case 5:
        type = "course_type_donwload_material";
        icon = "download";
        break;        
    }
    return (
      <>
        <i className="material-icons">{icon}</i> <Translate>{type}</Translate>
        {"  "}
        <span>{c?.name}</span>
      </>
    );
  };
  const  appendRequisites = (previousValue, currentValue, index) =>[
    ...previousValue,
    <li key={index}>
      {prerequisiteText(
        coursesList.find((v) => v.id == currentValue)
      )}
    </li>,
  ]
  const prerequisites = String(course.prerequisites)
    .split(",")
    .reduce(
      appendRequisites,
      []
    );
  const readyToDisplay = (v, defaultDisplay) => {
    if (
      course.readyToDisplay == null ||
      course.readyToDisplay == true
    )
      return defaultDisplay;
    else
      return (
        <div className="upcoming-icon">
          <i className="material-icons">
            <img height="60px" src={"../../assets/images/ComingSoon.pn"}></img>
          </i>
          <Translate>upcoming</Translate>
        </div>
      );
  };

  return (
    <div
      id={idComponent}
      ref={refComponent}
      className={
        isLockedStyle
          ? "list-item locked" +
            " indentation-" +
            course.indentation +
            " " +
            course.style
          : "list-item" +
            " indentation-" +
            course.indentation +
            " " +
            course.style
      }
    >
      <div className="quiz-mark" />

      <div className="video-icons" id="video">
      {course.isLocked ?
        <div className="video-image">  <img src={course.img} className="splash" alt="" /></div>:
        <a href={goToReference} className="video-image">
          <img src={course.img} className="splash" alt="" />
        </a>
      }
      </div>
      <div className="item-description">
        <h1>{course.name}</h1>
        <div className="course-title">
          <i className="material-icons text-secondary">fact_check</i>
          &nbsp;<Translate>title_quiz</Translate>
        </div>
        <div className="course-description">{course.description}</div>
        <div className="video-amount-info">
          <div className="videos-ammount">
            <span>{course.numberQuestions}</span>
            <p> &nbsp; </p>
            <Translate>questions_count</Translate>{" "}
          </div>

          <div className="videos-ammount">
            <span>{formatted}</span>
            <p> &nbsp; </p>
            <Translate>duration</Translate>
          </div>
          <div className="videos-ammount">
            <span>70% </span>
            <p> &nbsp; </p>
            <Translate>minscoretopass</Translate>
          </div>
        </div>
      </div>
      {course.isLocked ? (
        <div className="lock-info">
          <div className="lock-title">
            <span className="material-icons">info</span>
            <span>
              <Translate>require_unlock</Translate>
            </span>
          </div>
          <div className="requirements">
            <ul>{prerequisites}</ul>
          </div>
        </div>
      ) : (
        <>
          {course.isCompleted ? (
            <>
              <div className="course-completion">
                <div className="completion">{course.maxScore + "%"}</div>
                <span className="completion-title">
                  {" "}
                  <Translate>yourscore</Translate>
                </span>
              </div>
              <div className="course-completion">
                <div className="completion">
                  <i className="material-icons text-success">
                    check_circle_outline
                  </i>
                  <span className="completion-title">
                    <Translate>completed</Translate>
                  </span>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="course-completion"></div>
              <div className="course-completion"></div>
            </>
          )}
        </>
      )}

      {readyToDisplay(
        course,
        course.isLocked || course.numberQuestions == 0 ? (
          <div className="access-course">
            <i className="material-icons text-warning">lock</i>
            <span>
              <Translate>Locked</Translate>
            </span>
          </div>
        ) : (
          <a className="access-course" href={goToReference}>
            <span className="material-icons">arrow_circle_right</span>
            <span>
              <Translate>start_quiz</Translate>
            </span>
          </a>
        )
      )}
    </div>
  );
};

export default CourseQuizItemComponent;
