import React, { useEffect, useState } from "react";

import Header from "../shared/Header";
import Footer from "../shared/Footer";

import { Accordion, Modal } from "react-bootstrap";
import VideoPlayer from "../course-video-player-components/VideoPlayer";
import { useParams } from "react-router-dom";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

//---------------------Translation
import Translate from "translate-components";

/* ********* Actions ********* */
import * as landingActions from "../../data/Landing/landingActions";
import * as actions from "../../data/Dashboard/dashBoardActions";
import * as subscriptionActions from "../../data/SubscriptionPlan/subscriptionPlanActions";
import CourseDetailsItem from "./CourseDetailsItem";

import { encodeNumber } from "../../utils/strings";
const CourseDetails = () => {
  const dispatch = useDispatch();
  const initArray: Array<Array<Object>> = [];
  const initArrayString: Array<string> = [];

  const [modalShow, setModalShow] = useState(false);
  const [sections, setSections] = useState(initArray);

  const [expadedAccordions, setExpadedAccordions] = React.useState(
    initArrayString
  );
  let { courseId } = useParams();

  const course = useSelector(
    (state: RootStateOrAny) => state.landing.selectedCourse
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const cart = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.cart
  );
  const userId = userSession?.userId;

  useEffect(() => {
    const employeeId = userId != null && userId > 0 ? userId : -1;
    dispatch(landingActions.getCourseById(courseId, employeeId, lang));
  }, [courseId, lang]);
  useEffect(() => {
    if (course == null || course.courses.length == 0) return;

    const splitArrays: Array<Array<Object>> = [[]];
    course.courses.forEach((v) => {
      if (v.indentation == 0) {
        splitArrays.push([]);
      }

      splitArrays[splitArrays.length - 1].push(v);
    });
    setSections(splitArrays);

    var idSeminar = null;
    idSeminar =
      course.buyInformation != null && course.buyInformation.length > 0
        ? course.buyInformation[0].buyGuid
        : null;
    if (idSeminar != null && idSeminar != "") {
      //Get the Course Payment Information
      dispatch(subscriptionActions.getByIdSeminar(idSeminar));
    }
  }, [course]);

  var marketingInfo =
    course != null
      ? course.instituteModuleMarketing.filter((im) => im.lang == lang)
      : null;
  const buyUrl =
    course == null
      ? ""
      : course.buyInformation != null && course.buyInformation.length > 0
      ? course.buyInformation[0].buyUrl
      : "";

  const price = cart != null && cart.length > 0 ? cart[0].price : "n/a";
  return (
    <>
      <Header />
      <div className="padding-top-100 top-20 bkg-papergray">
        <div className="container padding-bottom-20 padding-top-20">
          <div className="row flex-md-row-reverse justify-content-between overflow-visible">
            <div className="col-12 col-md-4 position-relative">
              <div className="card position-relative position-md-absolute end-0">
                <div className="dashPrevThumb">
                  <a onClick={() => setModalShow(true)}>
                    <img src={course?.img} className="splash" />
                  </a>
                </div>
                <div className="padding-all-30 ">
                  <p className="top-0 bottom-0 fs-6">
                    <Translate>subscription.plan.order.price</Translate>
                  </p>
                  <h2 className="fs-4 fw-bold text-success">
                    $
                    {price != "n/a"
                      ? (parseInt(price) / 100).toFixed(2)
                      : price}
                  </h2>

                  {course != null && !course.isEnrolled && (
                    <a
                      href={buyUrl}
                      className="btn btn-flat-success d-block top-20"
                    >
                      <Translate>Dashboard_Buy_Course</Translate>
                    </a>
                  )}
                  {userSession &&
                    userSession.isLoggedIn() &&
                    course != null &&
                    course.isEnrolled && (
                      <a
                        href={"/course/" + encodeNumber(course.id)}
                        className="btn btn-flat-success d-block top-20"
                      >
                        <Translate>GoToCourse</Translate>
                      </a>
                    )}

                  <p className="top-20 fw-bold">
                    <Translate>Landing_This_Course_Includes</Translate>
                  </p>

                  {marketingInfo != null && marketingInfo.length > 0 && (
                    <div className="d-flex">
                      <span className="material-icons text-success right-4">
                        smart_display
                      </span>
                      <p> {marketingInfo[0].videoLength}</p>
                    </div>
                  )}

                  {marketingInfo != null && marketingInfo.length > 0 && (
                    <div className="d-flex">
                      <span className="material-icons text-success right-4">
                        file_download
                      </span>
                      <p>{marketingInfo[0].mediaResourcesCount}</p>
                    </div>
                  )}

                  <div className="d-flex">
                    <span className="material-icons text-success right-4">
                      smartphone
                    </span>
                    <p>
                      <Translate>Landing_Access_Mobile</Translate>
                    </p>
                  </div>

                  <div className="d-flex">
                    <span className="material-icons text-success right-4">
                      all_inclusive
                    </span>
                    <p>
                      <Translate>Landing_Full_Lifetime</Translate>
                    </p>
                  </div>

                  <div className="d-flex">
                    <span className="material-icons text-success right-4">
                      flag
                    </span>
                    <p>
                      <Translate>Landing_Certificate_Completion</Translate>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-md-8 col-lg-7 padding-top-20 padding-bottom-20">
              <h1 className="dash">
                {marketingInfo != null && marketingInfo.length > 0
                  ? marketingInfo[0].title
                  : course?.name}
              </h1>
              <p className="top-10 bottom-10">
                {marketingInfo != null && marketingInfo.length > 0
                  ? marketingInfo[0].text
                  : course?.description}
              </p>
              <div className="d-flex">
                <span className="material-icons">language</span>
                <p>English / Español</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container padding-bottom-20 padding-top-20 course-details-container">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-7 padding-top-20 padding-bottom-20">
            <h1 className="dash smaller">{course?.name}</h1>
            <div className="d-flex justify-content-between">
              <p>{sections?.length} sections</p>
              <a
                className="text-success fw-bold cursorPointer"
                onClick={() => {
                  if (expadedAccordions.length == sections.length) {
                    setExpadedAccordions([]);
                  } else {
                    var sectionsToAdd: Array<string> = [];
                    for (var i = 0; i < sections.length; i++) {
                      sectionsToAdd.push("" + i);
                    }
                    setExpadedAccordions(sectionsToAdd);
                  }
                }}
              >
                <Translate>
                  {expadedAccordions.length == sections.length
                    ? "Landing_Collapse_Sections"
                    : "Landing_Expand_Sections"}
                </Translate>
              </a>
            </div>
            <Accordion activeKey={expadedAccordions}>
              {sections?.map((s, i) => (
                <CourseDetailsItem
                  key={i}
                  index={"" + i}
                  item={s}
                  changeToggle={(k) => {
                    const workingList = [...expadedAccordions];
                    const index = workingList.indexOf(k);
                    if (index > -1) {
                      workingList.splice(index, 1);
                      setExpadedAccordions(workingList);
                    } else {
                      workingList.push(k);
                      setExpadedAccordions(workingList);
                    }
                  }}
                ></CourseDetailsItem>
              ))}
            </Accordion>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-8 col-lg-7 padding-top-20 padding-bottom-20">
            <h1 className="dash smaller">
              <Translate>Landing_Requeriments</Translate>
            </h1>
            <ul>
              <li>Mac or PC</li>
              <li>
                <Translate>Landing_Free_Text_Editor</Translate>
              </li>
              <li>
                <Translate>Landing_Web_Broser</Translate>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Footer />

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Body className="padding-all-0">
          <div>
            <button
              type="button"
              className="btn-close"
              onClick={() => setModalShow(false)}
            ></button>
            <VideoPlayer
              videoSource={course?.previewVideoUrl}
              mustCompleteCourse={false}
            ></VideoPlayer>
          </div>
        </Modal.Body>
        <Modal.Body>
          <h2>{course?.name}</h2>
          <p>{course?.description}</p>
        </Modal.Body>

        <Modal.Footer>
          <div className="w-100 d-flex justify-content-between">
            <a
              className="btn btn-flat-papergray text-warning"
              onClick={() => setModalShow(false)}
            >
              <Translate>close</Translate>
            </a>

            {userSession &&
              userSession.isLoggedIn() &&
              course != null &&
              !course.isInWishlist &&
              !course.isEnrolled && (
                <a
                  onClick={() => {
                    dispatch(actions.addToWishlist(userId, course.id));
                  }}
                  className="btn btn-flat-papergray text-secondary"
                >
                  <Translate>Dashboard_Add_Wishlist</Translate>
                </a>
              )}

            {course != null && !course.isEnrolled && (
              <a href={buyUrl} className="btn btn-flat-papergray text-success">
                <Translate>Dashboard_Buy_Course</Translate>
              </a>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default CourseDetails;
