import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import Form from "react-bootstrap/Form";
import { useParams } from "react-router-dom";

import ELTComponent from "../shared/ELTComponent";
import AddressCollector from "./AddressCollector";
import OrderSummary from "./OrderSummary";
import CustomSubscriptionPlan from "./CustomSubscriptionPlan";

import Translate from "translate-components";

import * as actions from "../../data/SubscriptionPlan/subscriptionPlanActions";
import * as applicationActions from "../../data/Application/applicationActions";

const SubscriptionPlan = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const { idSeminar, countries } = useParams();
  const childRef = useRef() as any;

  /* ********* Reducer Variables ********* */
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const cart = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.cart
  );

  const loggedUserEmail = useSelector(
    (state: RootStateOrAny) => state.userSession.email
  );
  const loggedUserFirstName = useSelector(
    (state: RootStateOrAny) => state.userSession.firstName
  );
  const loggedUserLastName = useSelector(
    (state: RootStateOrAny) => state.userSession.lastName
  );
  const errorManager = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.errorManager
  );
  const taxAmountInCents = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.taxAmountInCents
  );
  const salesTaxError = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.errorFromSalesTaxService
  );
  const shippingFeeInCents = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.shippingFeeInCents
  );
  const requestDeparted = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.requestDeparted
  );
  const requestArrived = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.requestArrived
  );
  const isFirstSubscriptionPlanRender = useSelector(
    (state: RootStateOrAny) =>
      state.subscriptionPlan.isFirstSubscriptionPlanRender
  );

  const productPrices = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.productPrices
  );

  let subTotal = 0;

  // Errors
  const [orderErrors, setOrderErrors] = useState([] as Array<string>);

  const [shippingBusinessName, setShippingBusinessName] = useState("");
  const [shippingName, setShippingName] = useState(loggedUserFirstName);
  const [shippingLastName, setShippingLastName] = useState(loggedUserLastName);
  const [shippingAddress, setShippingAddress] = useState("");
  const [shippingAddress2, setShippingAddress2] = useState("");
  const [shippingEmail, setShippingEmail] = useState(loggedUserEmail);
  const [shippingEmailConfirmation, setShippingEmailConfirmation] = useState(
    loggedUserEmail
  );
  const [shippingCity, setShippingCity] = useState("");
  const [shippingSelectedState, setShippingSelectedState] = useState("");
  const [shippingZipCode, setShippingZipCode] = useState("");
  const [shippingSelectedCountry, setShippingSelectedCountry] = useState("ot");
  const [shippingPreferredLanguage, setShippingPreferredLanguage] = useState(
    "en"
  );
  /* eslint-disable no-unused-vars */
  const [showNameError, setShowNameError] = useState(false);
  const [showEmailNotMatchError, setShowEmailNotMatchError] = useState(false);
  const [showLastNameError, setShowLastNameError] = useState(false);
  const [showShippingCountryError, setShowShippingCountryError] = useState(
    false
  );
  const [errorsWhenSubmitting] = useState("");
  const [isShippingSameAsBilling] = useState(true);
  const [shippingMethod, setShippingMethod] = useState("standardShipping");
  const [supportLargeOrders, setSupportLargeOrder] = useState(false);
  const [validated, setValidated] = useState(false);
  const [isDigitalProduct] = useState(false);
  const [countriesFilter, setCountriesFilter] = useState([] as Array<string>);

  /* ********* Effects ********* */
  /* eslint-enable no-unused-vars */
  useEffect(() => {
    if (typeof idSeminar !== "undefined" && idSeminar != null) {
      dispatch(actions.getProductPrices());
      dispatch(actions.getByIdSeminar(idSeminar));
    } else {
      dispatch(
        actions.getByDescriptionSubscriptionPlan(
          "TGR Live Ready Training - Full Access"
        )
      );
    }
  }, [idSeminar]);

  //Should dispatch an action only on page initial render
  useEffect(() => {
    if (isFirstSubscriptionPlanRender) {
      //Set the language according with the Configuration
      dispatch(
        applicationActions.switchLanguage(
          process.env.REACT_APP_DEFAULT_LANGUAGE
        )
      );
      dispatch(actions.setIsFirstSubscriptionPlanRender(false));
    }
  }, []);

  useEffect(() => {
    if (typeof countries !== "undefined" && countries != null) {
      setCountriesFilter(countries.split(","));
    } else {
      setCountriesFilter([]);
    }
  }, [countries]);

  const computeSubTotal = () => {
    if (cart.length <= 0) {
      return (0).toFixed(2);
    }

    const subT = cart.reduce((accum, item) => accum + calculatePrice(item), 0);

    subTotal = subT;

    if (subTotal <= 0) {
      return (0).toFixed(2);
    }

    return subTotal;
  };

  const computeTotal = () => {
    const prevSubtotal = (computeSubTotal() as unknown) as number;
    if (prevSubtotal + shippingFeeInCents + taxAmountInCents <= 0) {
      return (0).toFixed(2);
    }

    return (
      (prevSubtotal + shippingFeeInCents + taxAmountInCents) /
      100
    ).toFixed(2);
  };

  const checkErrorsWhenSubmitting = () => {
    let htmlErrors = [] as Array<string>;
    if (cart.length <= 0) {
      htmlErrors.push("subscription.error.message.your.cart.is.empty");
    }

    setShowNameError(String(shippingName?.trim()) === "");
    setShowLastNameError(String(shippingLastName?.trim()) == "");
    setShowShippingCountryError(String(shippingSelectedCountry).trim() === "");
    setShowEmailNotMatchError(shippingEmail !== shippingEmailConfirmation);
    return htmlErrors;
  };

  const onSubmitOrder = (event) => {
    const form = event.currentTarget;

    dispatch(actions.setSubmittingOrderFlag(true));

    if (childRef.current) {
      childRef.current.triggerValidations();
    }

    setOrderErrors(checkErrorsWhenSubmitting());

    if (form.checkValidity() === false || orderErrors.length > 0) {
      event.preventDefault();
      event.stopPropagation();
      dispatch(actions.setSubmittingOrderFlag(false));
    }

    setValidated(true);
  };

  const calculatePrice = (product) => {
    if (product.isFree) return 0;
    if (productPrices != null) {
      const price = productPrices.find(
        (pp) =>
          pp.fkIdProduct == product.fkSubscriptionProduct &&
          ((pp.fromQuantity <= product.quantity &&
            product.quantity <= (pp.toQuantity as number)) ||
            (product.quantity >= pp.fromQuantity && pp.toQuantity == null))
      );

      if (price != null) {
        return parseInt(product.quantity) * parseInt(price.unitCostInCents);
      } else {
        return parseInt(product.quantity) * parseInt(product.price);
      }
    }

    return parseInt(product.quantity) * parseInt(product.price);
  };

  return (
    <ELTComponent>
      <div className="top-100 subscription-plan padding-all-60">
        <Form
          noValidate
          validated={validated}
          className="container bottom-20"
          onSubmit={onSubmitOrder}
          action={`${process.env.REACT_APP_API_TGR_IAM_URL}/Checkout/SubscriptionToCourseCheckout?idSubscriptionPlan=${cart[0]?.productId}&quantity=${cart[0]?.quantity}&email=${shippingEmail}&firstName=${shippingName}&lastName=${shippingLastName}&countryCode=${shippingSelectedCountry}&selectedLanguage=${shippingPreferredLanguage}`}
          method="post"
        >
          <div className="row">
            <div className="col-12 col-sm-12 col-md-7 col-lg-8">
              <div>
                <CustomSubscriptionPlan cart={cart}></CustomSubscriptionPlan>
              </div>

              <AddressCollector
                screenTitle={
                  <Translate>
                    subscription.plan.address.collector.personal.information
                  </Translate>
                }
                businessName={shippingBusinessName}
                setBusinessName={setShippingBusinessName}
                name={shippingName}
                setName={setShippingName}
                lastName={shippingLastName}
                setLastName={setShippingLastName}
                address={shippingAddress}
                setAddress={setShippingAddress}
                address2={shippingAddress2}
                setAddress2={setShippingAddress2}
                email={shippingEmail}
                setEmail={setShippingEmail}
                emailConfirmation={shippingEmailConfirmation}
                setEmailConfirmation={setShippingEmailConfirmation}
                city={shippingCity}
                setCity={setShippingCity}
                selectedCountry={shippingSelectedCountry}
                setSelectedCountry={setShippingSelectedCountry}
                selectedState={shippingSelectedState}
                setSelectedState={setShippingSelectedState}
                zipCode={shippingZipCode}
                setZipCode={setShippingZipCode}
                displayEmail={isShippingSameAsBilling}
                lang={lang}
                countriesFilter={countriesFilter}
                preferredLanguage={shippingPreferredLanguage}
                setPreferredLanguage={setShippingPreferredLanguage}
                showNameError={showNameError}
                showEmailNotMatchError={showEmailNotMatchError}
                showShippingCountryError={showShippingCountryError}
                showLastNameError={showLastNameError}
              />
            </div>
            <div className="col-12 col-sm-12 col-md-5 col-lg-4">
              <OrderSummary
                cart={cart}
                taxAmountInCents={taxAmountInCents}
                computeSubTotal={computeSubTotal}
                shippingFeeInCents={shippingFeeInCents}
                computeTotal={computeTotal}
                errorsWhenSubmitting={errorsWhenSubmitting}
                salesTaxError={salesTaxError}
                requestDeparted={requestDeparted}
                requestArrived={requestArrived}
                shippingMethod={shippingMethod}
                setShippingMethod={setShippingMethod}
                supportLargeOrders={supportLargeOrders}
                setSupportLargeOrders={setSupportLargeOrder}
                isDigitalProduct={isDigitalProduct}
              />
              {errorManager ? (
                <div className="card width-100p padding-all-10 order-summary text-warning top-10 hidden">
                  <ul>
                    <li>{errorManager}</li>
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </Form>
      </div>
    </ELTComponent>
  );
};

export default SubscriptionPlan;
