import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams } from "react-router-dom";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { decodeNumber } from "../../utils/strings";

/* ********* Components ********* */
import ELTComponent from "../shared/ELTComponent";
import ELTReaderComponent from "./ELTReader";

/* ********* Actions ********* */
import * as actions from "../../data/Reading/readingActions";
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";
import * as landingActions from "../../data/Landing/landingActions";

const EpubViewer = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const { courseId, guideId } = useParams();

  /* ********* Reducer Variables ********* */
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const course = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
  );
  const highlightsWereSentByEmail = useSelector(
    (state: RootStateOrAny) => state.reading.sendHighlightsByEmail
  );
  const bookmarks = useSelector(
    (state: RootStateOrAny) => state.reading.bookmarks
  );
  const highlights = useSelector(
    (state: RootStateOrAny) => state.reading.highlights
  );
  const configuration = useSelector(
    (state: RootStateOrAny) => state.reading.configuration
  );

  /* ********* Effects ********* */
  useEffect(() => {
    if (userSession != null) {
      //Get the Information of the Course
      const userId = userSession.userId;

      if (courseId != null && userId != null) {
        dispatch(
          dashboardActions.getUserModuleCourse(
            decodeNumber(courseId),
            userId,
            lang
          )
        );
        dispatch(actions.getBookmarks(userId, decodeNumber(courseId), lang));
        dispatch(actions.getHighlights(userId, decodeNumber(courseId), lang));
        dispatch(actions.getConfiguration(userId));
      }
    }
  }, [userSession]);

  /* ********* Functions ********* */
  const updateBookmarks = (pbookmarks) => {
    if (courseId != null) {
      const mapedBookmarks = pbookmarks.map((h) => {
        return { location: h };
      });
      dispatch(
        actions.saveReadingBookmarks(
          userSession.userId,
          decodeNumber(courseId),
          mapedBookmarks,
          lang
        )
      );
    }
  };

  const updateHighligts = (phighligts) => {
    const mapedHighliths = phighligts.map((h) => {
      return { text: h.text, location: h.cfiRange, comment: h.comment };
    });
    dispatch(
      actions.saveReadingHighlights(
        userSession.userId,
        decodeNumber(courseId),
        mapedHighliths,
        lang
      )
    );
  };

  const updateConfiguration = (pconfiguration) => {
    pconfiguration.idEmployee = userSession.userId;
    dispatch(actions.saveReadingConfiguration(pconfiguration));
  };

  const completeCourse = () => {
    dispatch(
      landingActions.setCourseCompleted(
        userSession.userId,
        decodeNumber(courseId)
      )
    );
  };

  const saveLastLocation = (location) => {
    dispatch(
      actions.saveLastLocation(
        userSession.userId,
        decodeNumber(courseId),
        location,
        lang
      )
    );
  };

  const sendHighlightsByEmail = (phighlights) => {
    const mapedHighligths = phighlights.map((h) => {
      return { text: h.text, location: h.cfiRange, comment: h.comment };
    });
    dispatch(
      actions.sendHighlightsByEmail(
        userSession.userId,
        decodeNumber(courseId),
        lang,
        mapedHighligths
      )
    );
  };

  const turnOffHighlights = () => {
    dispatch(actions.setSendHighlightsByEmailOff());
  };

  return (userSession != null &&
    course != null &&
    "previewVideoUrl" in course) ||
    guideId != null ? (
    <ELTComponent hideHeaderFooter={true}>
      <ELTReaderComponent
        pbookmarks={bookmarks}
        phighligths={highlights}
        pconfiguration={configuration}
        pcourse={course}
        //update state
        pupdateBookmarks={updateBookmarks}
        pupdateHighligts={updateHighligts}
        pupdateConfiguration={updateConfiguration}
        pupdateLastLocation={saveLastLocation}
        pcompleteCourse={completeCourse}
        psendHighlightsByEmail={sendHighlightsByEmail}
        guideId={decodeNumber(guideId)}
        highlightsWereSentByEmail={highlightsWereSentByEmail}
      ></ELTReaderComponent>
      {highlightsWereSentByEmail && (
        <>
          <Modal
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={turnOffHighlights}
            show={true}
          >
            <Modal.Header closeButton>
              <Modal.Title id="contained-modal-title-vcenter">
                <Translate>reading.highligts.were.sent</Translate>
              </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
              <Button className="btn-danger" onClick={turnOffHighlights}>
                <Translate>close</Translate>
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      )}
    </ELTComponent>
  ) : null;
};

export default EpubViewer;
