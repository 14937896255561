import moment from "moment";

export function simpleEncryption(text) {
  if (text === null) return null;
  var encrypted = btoa(text);

  return encrypted;
}

export function simpleDecryption(encrypted) {
  if (encrypted === null) return null;
  var decrypted = atob(encrypted);

  return decrypted;
}

export function Encrypt(stringToEncryp) {
  var CryptoJS = require("crypto-js");
  var day = moment().format("DD");
  var month = moment().format("MM");

  var thePhrase = "506" + day + month + "841113244";

  var key = CryptoJS.enc.Utf8.parse(thePhrase);
  var iv = CryptoJS.enc.Utf8.parse(thePhrase);

  var encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(stringToEncryp),
    key,
    {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return encrypted.toString();
}

//Truncate Utility
String.prototype.trunc = function(n) {
  return this.substr(0, n - 1) + (this.length > n ? "..." : "");
};

//This method encode a number in base 64 format without an special characters
export function encodeNumber(number) {
  if (number == null) return undefined;
  const encodedString = btoa(String(number))
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, ""); // Remove trailing equals signs
  return encodedString;
}

//This method decodes a number in base 64 format without an special characters
export function decodeNumber(encodedString) {
  if (encodedString == null) return undefined;
  // Add padding back if needed
  const padding = (4 - (encodedString.length % 4)) % 4;
  const paddedString = encodedString + "=".repeat(padding);

  const decodedNumber = parseInt(atob(paddedString), 10);
  return decodedNumber;
}
