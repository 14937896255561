import axios from "axios";
//import querystring from 'querystring';

class GroupServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  GetBySupportedSystem(plang, pemployeeId) {
    var employeeId = pemployeeId != null ? pemployeeId : 0;
    var lang = plang == "" ? "en" : plang;
    const theUrl = `${this.tgrApi}/InstituteGroup/GetBySupportedSystem/${this.supportedSystem}/${employeeId}/${lang}`;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default GroupServices;
