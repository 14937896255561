import sliderimage1 from "../../assets/images/slider/Landingslider4.png";
import sliderimage2 from "../../assets/images/slider/Landingslider5.png";
import sliderimage3 from "../../assets/images/slider/Landingslider6.png";
import sliderimage4 from "../../assets/images/slider/Landingslider7.png";
import sliderimage5 from "../../assets/images/slider/Landingslider2.png";
import sliderimage6 from "../../assets/images/slider/Landingslider3.png";
export const materials = {
  supportedSytem6: {
    grades: [
      {
        key: "tgrViveListo",
        name: "system1_dashboard_tgr_vive_listo_title",
        curriculumns: [
          {
            key: "tgrViveListoC1",
            name: "system1_dashboard_tgr_vive_listo_ecommerce",
            enable: true,
            link: "https://institutotgr.com",
          },
          {
            key: "tgrViveListoC2",
            name: "system1_dashboard_tgr_vive_listo_webapp",
            enable: true,
            link: "https://app.institutotgr.com",
          },
        ],
      },
    ],
    othersMaterials: [
      {
        key: "brainmodelTeacherGuideSection1",
        name: "brainmodelTeacherGuideSection1",
        enable: true,
        availableModule: 20,
        link: "/guide/1",
      },
      {
        key: "brainmodelTeacherGuideSection2",
        name: "brainmodelTeacherGuideSection2",
        enable: true,
        availableModule: 20,
        link: "/guide/2",
      },
      {
        key: "brainmodelTeacherGuideSection3",
        name: "brainmodelTeacherGuideSection3",
        enable: true,
        availableModule: 20,
        link: "/guide/3",
      },
      {
        key: "brainmodelTeacherGuideSection4",
        name: "brainmodelTeacherGuideSection4",
        enable: true,
        availableModule: 20,
        link: "/guide/4",
      },
    ],
    tgrAppLinks: [
      {
        key: "vivelisto_android",
        name: "system1_dashboard_tg_app_links_tgr_vive_listo_android",
        enable: true,
        link:
          "https://play.google.com/store/apps/details?id=com.institutotgr.app.vivelisto2.app",
      },
      {
        key: "vivelisto_ios",
        name: "system1_dashboard_tg_app_links_tgr_vive_listo_ios",
        enable: true,
        link: "https://apps.apple.com/us/app/tgr-vive-listo/id1507575765",
      },
      {
        key: "vivelisto_seminar_android",
        name: "system1_dashboard_tg_app_links_tgr_vive_listo_seminar_android",
        enable: true,
        link:
          "https://play.google.com/store/apps/details?id=com.institutotgr.seminario",
      },
      {
        key: "vivelisto_seminar_ios",
        name: "system1_dashboard_tg_app_links_tgr_vive_listo_seminar_ios",
        enable: true,
        link:
          "https://apps.apple.com/us/app/tgr-vive-listo-seminario/id1492357726",
      },
    ],
    mainCarrosel: [
      {
        img: sliderimage1,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals",
        title2: "main.screen.successstories.choosetolife",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage2,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals1",
        title2: "main.screen.successstories.choosetolife1",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage3,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals2",
        title2: "main.screen.successstories.choosetolife2",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage4,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals4",
        title2: "main.screen.successstories.choosetolife4",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage5,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals5",
        title2: "main.screen.successstories.choosetolife5",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage6,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals6",
        title2: "main.screen.successstories.choosetolife6",
        title3: "main.screen.successstories.getcertified",
      },
    ],
  },
  supportedSytem2: {
    grades: [],
    othersMaterials: [
      {
        key: "brainmodelTeacherGuideSection1",
        name: "brainmodelTeacherGuideSection1",
        enable: true,
        availableModule: 20,
        link: "/guide/1",
      },
      {
        key: "brainmodelTeacherGuideSection2",
        name: "brainmodelTeacherGuideSection2",
        enable: true,
        availableModule: 20,
        link: "/guide/2",
      },
      {
        key: "brainmodelTeacherGuideSection3",
        name: "brainmodelTeacherGuideSection3",
        enable: true,
        availableModule: 20,
        link: "/guide/3",
      },
      {
        key: "brainmodelTeacherGuideSection4",
        name: "brainmodelTeacherGuideSection4",
        enable: true,
        availableModule: 20,
        link: "/guide/4",
      },
    ],
    mainCarrosel: [
      {
        img: sliderimage1,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals",
        title2: "main.screen.successstories.choosetolife",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage2,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals1",
        title2: "main.screen.successstories.choosetolife1",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage3,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals2",
        title2: "main.screen.successstories.choosetolife2",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage4,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals4",
        title2: "main.screen.successstories.choosetolife4",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage5,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals5",
        title2: "main.screen.successstories.choosetolife5",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage6,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals6",
        title2: "main.screen.successstories.choosetolife6",
        title3: "main.screen.successstories.getcertified",
      },
    ],
  },
  supportedSytem3: {
    grades: [],
    othersMaterials: [],
    mainCarrosel: [
      {
        img: sliderimage1,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals",
        title2: "main.screen.successstories.choosetolife",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage2,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals1",
        title2: "main.screen.successstories.choosetolife1",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage3,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals2",
        title2: "main.screen.successstories.choosetolife2",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage4,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals4",
        title2: "main.screen.successstories.choosetolife4",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage5,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals5",
        title2: "main.screen.successstories.choosetolife5",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage6,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals6",
        title2: "main.screen.successstories.choosetolife6",
        title3: "main.screen.successstories.getcertified",
      },
    ],
  },
  supportedSytem5: {
    grades: [],
    othersMaterials: [],
    mainCarrosel: [
      {
        img: sliderimage1,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals",
        title2: "main.screen.successstories.choosetolife",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage2,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals1",
        title2: "main.screen.successstories.choosetolife1",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage3,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals2",
        title2: "main.screen.successstories.choosetolife2",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage4,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals4",
        title2: "main.screen.successstories.choosetolife4",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage5,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals5",
        title2: "main.screen.successstories.choosetolife5",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage6,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals6",
        title2: "main.screen.successstories.choosetolife6",
        title3: "main.screen.successstories.getcertified",
      },
    ],
  },
  supportedSytem6: {
    grades: [],
    othersMaterials: [],
    mainCarrosel: [
      {
        img: sliderimage1,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals",
        title2: "main.screen.successstories.choosetolife",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage2,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals1",
        title2: "main.screen.successstories.choosetolife1",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage3,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals2",
        title2: "main.screen.successstories.choosetolife2",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage4,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals4",
        title2: "main.screen.successstories.choosetolife4",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage5,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals5",
        title2: "main.screen.successstories.choosetolife5",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage6,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals6",
        title2: "main.screen.successstories.choosetolife6",
        title3: "main.screen.successstories.getcertified",
      },
    ],
  },
  supportedSytem7: {
    grades: [],
    othersMaterials: [],
    mainCarrosel: [
      {
        img: sliderimage1,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals",
        title2: "main.screen.successstories.choosetolife",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage2,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals1",
        title2: "main.screen.successstories.choosetolife1",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage3,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals2",
        title2: "main.screen.successstories.choosetolife2",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage4,
        interval: 10000,
        title1: "main.screen.successstories.archievegoals4",
        title2: "main.screen.successstories.choosetolife4",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage5,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals5",
        title2: "main.screen.successstories.choosetolife5",
        title3: "main.screen.successstories.getcertified",
      },
      {
        img: sliderimage6,
        interval: 15000,
        title1: "main.screen.successstories.archievegoals6",
        title2: "main.screen.successstories.choosetolife6",
        title3: "main.screen.successstories.getcertified",
      },
    ],
  },
};
