import * as types from "../constants/actionTypes";

const initialState = {
  error: null,

  products: [],
  coursesList: [],
  courseSelectedId: 1,
  courseSelected: {},
  courseSelectedVideo: {},
  notJoinedModules: [],
  wishlist: [],
  feedback: {},
  showFeedback: true,
  isAllowed: true,
};

export default function dashBoardReducer(state = initialState, action) {
  let errorMessage = "You've found an unkown glitch! Please contact support.";

  switch (action.type) {
    case types.DASHBOARD_GET_LIST_USER_COURSES_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        coursesList: [],
        products: [],
        error: errorMessage,
      };
    }

    case types.DASHBOARD_GET_LIST_USER_COURSES_FULFILLED: {
      return {
        ...state,
        products:
          action.payload.data === null ? [] : action.payload.data.products,
        coursesList:
          action.payload.data === null ? [] : action.payload.data.modules,
      };
    }
    case types.DASHBOARD_GET_MODULE_FULFILLED: {
      const { isAllowed, selectedModule } = action.payload.data;

      return {
        ...state,
        courseSelected: selectedModule,
        isAllowed: isAllowed,
      };
    }
    case types.DASHBOARD_GET_MODULE_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        courseSelected: {},
        error: errorMessage,
      };
    }
    case types.DASHBOARD_GET_MODULE_COURSE_FULFILLED: {
      return {
        ...state,
        courseSelectedVideo:
          action.payload.data === null
            ? {}
            : action.payload.data.selectedCourse,
        feedback:
          action.payload.data === null ||
          action.payload.data.selectedCourse.feedback === null
            ? { description: "", score: 5 }
            : action.payload.data.selectedCourse.feedback,
      };
    }
    case types.DASHBOARD_GET_MODULE_COURSE_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        courseSelectedVideo: {},
        error: errorMessage,
      };
    }
    case types.DASHBOARD_UPDATE_FEEDBACK_SCORE: {
      const feedback =state.feedback as any;
      const newFeedback = {
        score: action.payload !== feedback.score ? action.payload : 0,
        description: feedback.description,
      };
      return {
        ...state,
        feedback: newFeedback,
      };
    }

    case types.DASHBOARD_UPDATE_FEEDBACK_DESCRIPTION: {
      const feedback =state.feedback as any;
      const newFeedback = {
        score: feedback.score,
        description: action.payload,
      };
      return {
        ...state,
        feedback: newFeedback,
      };
    }

    case types.DASHBOARD_ADD_FEEDBACK: {
      return {
        ...state,
        feedback: { score: 0, description: "" },
        showFeedback: false,
      };
    }

    case types.DASHBOARD_DISMISS_FEEDBACK: {
      return {
        ...state,
        showFeedback: false,
      };
    }

    case types.QUIZ_COMPLETE_QUIZ_BY_ID: {
      return {
        ...state,
        courseSelected: null,
      };
    }

    case types.DASHBOARD_GET_USER_NOT_JOINED_COURSES_FULFILLED: {
      return {
        ...state,
        notJoinedModules: action.payload.data,
      };
    }
    case types.DASHBOARD_GET_USER_WISHLIST_FULFILLED: {
      return {
        ...state,
        wishlist: action.payload.data,
      };
    }
    case types.DASHBOARD_ADD_USER_WISHLIST_FULFILLED: {
      const course = state.notJoinedModules.find(
        (c : any) => c.id == action.payload.data.idModule && c.isInWishlist == false
      );
      if (course) {
        let changedCourse = Object.assign({}, course) as any;
        changedCourse.isInWishlist = true;
        const index = state.notJoinedModules.indexOf(course);
        const newItems = [...state.notJoinedModules];
        newItems.splice(index, 1, changedCourse as never);
        return {
          ...state,
          notJoinedModules: newItems,

          wishlist: [...state.wishlist, changedCourse],
        };
      }

      return {
        ...state,

        wishlist: [...state.wishlist, action.payload.data],
      };
    }

    case types.DASHBOARD_REMOVE_USER_WISHLIST_FULFILLED: {
      const course = state.notJoinedModules.find(
        (c: any) => c.id == action.payload.data.idModule && c.isInWishlist == true
      );
      const filterList = state.wishlist.filter(
        (w: any) => w.id != action.payload.data.idModule
      );
      if (course) {
        let changedCourse = Object.assign({}, course) as any;
        changedCourse.isInWishlist = false;
        const index = state.notJoinedModules.indexOf(course);
        const newItems = [...state.notJoinedModules];
        newItems.splice(index, 1, changedCourse as never) ;
        return {
          ...state,
          notJoinedModules: newItems,
          wishlist: filterList,
        };
      }

      return {
        ...state,
        wishlist: filterList,
      };
    }

    default:
      return state;
  }
}
