import axios from "axios";
import { simpleDecryption } from "../../utils/strings";

class Service {
  constructor(productWT) {
    this.serviceUrl = process.env.REACT_APP_API_TGR_IAM_URL;
    this.controller = "/Subscription";
    this.productWebToken = "";

    if (productWT !== undefined && productWT !== "") {
      this.productWebToken = productWT;
    } else {
      this.productWebToken = simpleDecryption(localStorage.getItem("at"));
    }

    this.axiosClient = axios.create({
      baseURL: this.serviceUrl + this.controller,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Bearer " + this.productWebToken,
      },
    });
  }

  getByDescriptionSubscriptionPlan(description) {
    return this.axiosClient
      .get(`/GetByDescriptionSubscriptionPlan/${description}`)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getByIdSeminar(idSeminar) {
    return this.axiosClient
      .get(`/GetByIdSeminar/${idSeminar}`)
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getProductPrices() {
    return this.axiosClient.get(`/GetProductPrices`).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default Service;
