import React, { useEffect, useState } from "react";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";

import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";

import * as quizActions from "../../data/Quiz/quizActions";
const BackAdvertisementComponent = ({ show, onClose, courseId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const quiz = useSelector((state:RootStateOrAny) => state.quiz.instituteQuizPage);
  const isCompleted = useSelector((state: RootStateOrAny) => state.quiz.isCompleted);
  const isBackButton = useSelector((state: RootStateOrAny) => state.quiz.isBackButton);
   const [enableBackButton, setEnableBackButton]= useState(true);
  const onAcceptBack = () => {
    setEnableBackButton(false);
    dispatch(
      quizActions.completeQuizByQuizId(quiz.idInstituteQuizByEmployee, true)
    );
  };
  useEffect(() => {
    isCompleted &&
      isBackButton &&
      navigate(`/course/${courseId}/quiz`);
  }, [isCompleted]);
  return (
    <>
          <Modal
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            onHide={()=>enableBackButton?onClose():null}
            show={show}
            >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-vcenter">
                  <Translate>question.back.advertisement</Translate>
                  </Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                {enableBackButton== true ?<Button  
                  className="btn-danger"
                  onClick={()=>onClose()}>
                  <Translate>close</Translate>
                </Button>:null}
                <Button
                  className={enableBackButton? "btn-success":""}
                  onClick={()=>enableBackButton ? onAcceptBack():null}
                >
                 { enableBackButton?<Translate>accept</Translate>:<Translate>Loading</Translate>}
                </Button>
                </Modal.Footer>
          </Modal>
    </>



  );
};

export default BackAdvertisementComponent;
