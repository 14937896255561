import React, { useState, useEffect, useRef } from "react";

/* ********* Components ********* */
import AudioControls from "./AudioControls";
import AudioBar from "../epub-viewer-components/AudioBar";

type AudioPlayerProps={
  mustCompleteCourse:boolean,
  completeCourse:Function,
  isPlugin?:boolean,
  audioImage:string,
  audioTitle:string,
  audioArtist:string,
  audioClassName?:string,
  audioDuration:string,
  audioSource:string
}

const AudioPlayer = ({audioSource, mustCompleteCourse, completeCourse, isPlugin,audioImage,audioTitle, audioArtist, audioClassName, audioDuration}:AudioPlayerProps) => {

  /* ********* Uses ********* */
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef(new Audio(audioSource));
  const intervalRef = useRef<NodeJS.Timer | null>(null) ;

  /* ********* Effects ********* */
  useEffect(() => {
    // Pause and clean up on unmount
    return () => {
      audioRef.current.pause();
      clearInterval(intervalRef.current  as NodeJS.Timer);
    };
  }, []);

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      startTimer();
    } else {
      audioRef.current.pause();
    }
  }, [isPlaying]);

  /* ********* Functions ********* */
  const startTimer = () => {
    // Clear any timers already running
    clearInterval(intervalRef.current as NodeJS.Timer);

    intervalRef.current = setInterval(() => {
      if (audioRef.current.ended) {
        if (mustCompleteCourse) {
          completeCourse();
        }
        clearInterval(intervalRef.current as NodeJS.Timer);
        audioRef.current.currentTime = 0;
        setIsPlaying(false);
      }
    }, 1000);
  };

  return isPlugin == null ? (
    <div className="audio-player">
      <div className="audio-track-info">
        <img className="audio-artwork" src={audioImage} />
        <h2 className="audio-title">{audioTitle}</h2>
        <h3 className="audio-artist">{audioArtist}</h3>
        <AudioControls isPlaying={isPlaying} onPlayPauseClick={setIsPlaying} />
      </div>
    </div>
  ) : (
    <>
      <div className={audioClassName}>
        <div className="audio-player-plugin">
          <div className="audio-track-info">
            <AudioControls
              isPlaying={isPlaying}
              onPlayPauseClick={setIsPlaying}
            />
          </div>
        </div>
      </div>
      <div className="d-none d-md-flex audioBar ">
        <AudioBar duration={audioDuration} isrunning={isPlaying} />
      </div>
    </>
  );
};

export default AudioPlayer;
