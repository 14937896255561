import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import Translate from "translate-components";
import "../../utils/strings";

/* ********* Actions ********* */
import * as actions from "../../data/Dashboard/dashBoardActions";

// @ts-ignore
import imgCourse from "../../assets/images/imgmancourse.png";
import imgWarnCourse from "../../assets/images/warning-course.png";

type WhatToLearnProps = {
  userId: number;
  lang: string;
  showPreview: Function;
};

const WhatToLearnNextComponent = ({
  userId,
  lang,
  showPreview,
}: WhatToLearnProps) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();

  /* ********* Reducer Variables ********* */
  const notRelatedModules = useSelector(
    (state: RootStateOrAny) => state.dashboard.notJoinedModules
  );

  /* ********* Effects ********* */
  useEffect(() => {
    dispatch(actions.getUserNotJoinedCourses(userId, lang));
  }, [userId, lang]);

  return (
    <div className="bkg-papergray border-b-lightgray padding-top-10">
      <div className="container">
        <div className="row bottom-20">
          <div className="col-12">
            <h1 className="dash smaller ">
              <Translate>Dashboard_What_To_Learn_More</Translate>
            </h1>
            <h5 className="top-0">
              <Translate>Dashboard_Recommended_For_You</Translate>
            </h5>
          </div>
        </div>
        {notRelatedModules != null && notRelatedModules.length <= 0 ? (
          <div className="row">
            <div className="d-md-flex align-items-center justify-content-center col-12">
              <div className="col-12 col-md-7 col-lg-5">
                <h5>
                  <span className="right-10">
                    <img src={imgWarnCourse} className="img-fluid"></img>
                  </span>
                  <Translate>keepAnEyeOut</Translate>
                </h5>
                <h2 className="dash smaller">
                  <Translate>GreatCoursesAreComing</Translate>
                </h2>
              </div>
              <div className="col-12 col-md-4 col-lg-3">
                <img src={imgCourse} className="img-fluid"></img>
              </div>
            </div>
          </div>
        ) : (
          <div className="row bottom-20">
            {notRelatedModules?.map((m, i) => {
              const buyUrl =
                m.buyInformation != null && m.buyInformation.length > 0
                  ? m.buyInformation[0].buyUrl
                  : "";
              return (
                <div
                  className="d-flex col-12 col-sm-6 col-md-4 col-lg-3"
                  key={i}
                >
                  <div className="card d-flex align-items-end flex-column mb-4">
                    <div className="dashPrevThumb">
                      <a
                        className={m.previewVideoUrl == "" ? "nopreview" : ""}
                        onClick={
                          m.previewVideoUrl != ""
                            ? () => showPreview(m)
                            : () => {}
                        }
                      >
                        <img src={m.img} className="splash" />
                      </a>
                    </div>
                    <div className="w-100 flex-2">
                      <div className="padding-all-10 LNcourseTitle">
                        <h5>{m.name}</h5>
                      </div>
                      <div className="padding-all-10 LNcourseDesc">
                        {m.description.trunc(60)}
                      </div>
                    </div>
                    <div className="w-100 flex-1 mt-auto">
                      {!m.isInWishlist && (
                        <a
                          onClick={() => {
                            dispatch(actions.addToWishlist(userId, m.id));
                          }}
                        >
                          <span className="btn btn-flat-background btn-sm text-secondary width-100p top-5">
                            <Translate>Dashboard_Add_Wishlist</Translate>
                          </span>
                        </a>
                      )}

                      <a href={buyUrl}>
                        <span className="btn btn-flat-papergray text-success width-100p top-5 text-capitalize bottom-0">
                          <Translate>Dashboard_Buy_Course</Translate>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default WhatToLearnNextComponent;
