import * as types from "../constants/actionTypes";
import ReadingServices from "../services/readingServices";

const readingServicesInstance = new ReadingServices();

export function saveReadingBookmarks(idEmployee, idCourse, bookmarks, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .saveBookmarks(idEmployee, idCourse, bookmarks, lang)
      .then((response) => {
        dispatch({
          type: types.SAVE_READING_BOOKMARKS,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function saveReadingHighlights(idEmployee, idCourse, highlights, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .saveHighlights(idEmployee, idCourse, highlights, lang)
      .then((response) => {
        dispatch({
          type: types.SAVE_READING_HIGHLIGHTS,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function saveReadingConfiguration(config) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .saveConfiguration(config)
      .then((response) => {
        dispatch({
          type: types.SAVE_READING_CONFIGURATION,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function saveLastLocation(idEmployee, idCourse, location, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .saveLastLocation(idEmployee, idCourse, location, lang)
      .then((response) => {
        dispatch({
          type: types.SAVE_READING_LAST_LOCATION,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function sendHighlightsByEmail(idEmployee, idCourse, lang, highlights) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .sendHighlightsByEmail(idEmployee, idCourse, lang, highlights)
      .then((response) => {
        dispatch({
          type: types.READING_SEND_HIGHLIGHTS_EMAIL,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function setSendHighlightsByEmailOff() {
  return function(dispatch) {
    dispatch({
      type: types.READING_SEND_HIGHLIGHTS_EMAIL_OFF,
    });
  };
}

export function getBookmarksByEmployeeAsync(idEmployee, idCourse, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .getBookmarks(idEmployee, idCourse, lang)
      .then((response) => {
        dispatch({
          type: types.GET_READING_BOOKMARKS,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getBookmarks(idEmployee, idCourse, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .getBookmarks(idEmployee, idCourse, lang)
      .then((response) => {
        dispatch({
          type: types.GET_READING_BOOKMARKS,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getHighlights(idEmployee, idCourse, lang) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .getHighlights(idEmployee, idCourse, lang)
      .then((response) => {
        dispatch({
          type: types.GET_READING_HIGHLIGHTS,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getConfiguration(idEmployee) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    readingServicesInstance
      .getConfiguration(idEmployee)
      .then((response) => {
        dispatch({
          type: types.GET_READING_CONFIGURATION,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
