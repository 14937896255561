import * as types from "../constants/actionTypes";
import { simpleDecryption, simpleEncryption } from "../../utils/strings";

const selectedLanguageCode = () => {
  if (typeof window !== "undefined") {
    if (
      window.localStorage.getItem("language") === null ||
      window.localStorage.getItem("language") === undefined
    ) {
      return process.env.REACT_APP_DEFAULT_LANGUAGE ?? "en";
    } else {
      return simpleDecryption(window.localStorage.getItem("language"));
    }
  }
};

const initialState = {
  isLoading: false,
  selectedLanguageCode: selectedLanguageCode(),
  isPaymentRequired: false,
};

export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTH_ERROR: {
      if (
        action.payload !== undefined &&
        action.payload.status !== undefined &&
        action.payload.status == 402
      ) {
        return { ...state, isPaymentRequired: true };
      } else return { ...state, isPaymentRequired: false };
    }

    case types.FLIGHT_STARTED: {
      return { ...state, isLoading: true };
    }

    case types.FLIGHT_ARRIVED: {
      return {
        ...state,
        isLoading: false,
      };
    }

    case types.SWITCH_LANGUAGE_FULFILLED: {
      localStorage.setItem(
        "language",
        simpleEncryption(action.payload) as string
      );
      return { ...state, selectedLanguageCode: action.payload };
    }

    default:
      return state;
  }
}
