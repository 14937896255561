import React from "react";

const AudioControls = props => {
  return (
    <div className="audio-controls">
      {props.isPlaying ? (
        <button
          type="button"
          className="pause"
          onClick={() => props.onPlayPauseClick(false)}
          aria-label="Pause"
        >
          {/* <Pause /> */}
          <span className="material-icons">pause</span>
        </button>
      ) : (
        <button
          type="button"
          className="play"
          onClick={() => props.onPlayPauseClick(true)}
          aria-label="Play"
        >
          {/* <Play /> */}
          <span className="material-icons">play_arrow</span>
        </button>
      )}
    </div>
  );
};

export default AudioControls;
