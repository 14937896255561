import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector , RootStateOrAny } from "react-redux";
import { useParams, Link } from "react-router-dom";

import ELTComponent from "../shared/ELTComponent";
import Carousel from "react-bootstrap/Carousel";

//---------------------Translation
import Translate from "translate-components";

import * as landingActions from "../../data/Landing/landingActions";

const CategoryPreviewComponent = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  let { categoryId } = useParams();

  /* ********* Reducer Variables ********* */
  const coursesByCategory = useSelector(
    (state : RootStateOrAny) => state.landing.courseByCategories
  );
  const allCategories = useSelector((state: RootStateOrAny) => state.landing.products);
  const courses = useSelector((state: RootStateOrAny) => state.landing.coursesList);
  const selectedCategory = useSelector(
    (state: RootStateOrAny) => state.landing.selectedProduct
  );
  const lang = useSelector((state: RootStateOrAny) => state.application.selectedLanguageCode);

  const fetchData = useCallback(() => {
    dispatch(landingActions.getProductById(categoryId, lang));
    dispatch(landingActions.getAllProducts(lang));
    // dispatch(landingActions.getCoursesByCategory(categoryId, lang));
    dispatch(landingActions.getFeaturedCourses(lang));
  }, [dispatch, categoryId, lang]);

  useEffect(() => {
    fetchData();
  }, [fetchData, lang]);

  /* ********* Functions ********* */
  const coursesByCategoryToRender = courses
    .filter((cr) => cr.fkInstituteCourseType == 1)
    .map((c, i) => (
      <div key={i} className="course">
        <div className="img-cours">
          <img src={c.img} alt="" />
        </div>
        <span className="courseTitle">
          <p>{c.name}</p>
        </span>
      </div>
    ));
  const allCategoriesToRender = allCategories.map((c, i) => (
    <Link key={i} to={`/landing/category/${c.id}`}>
      <div className="course">
        <div className="img-cours">
          <img src={c.img} alt="" />
        </div>
        <span className="courseTitle">
          <h2>{c.name}</h2>
        </span>
      </div>
    </Link>
  ));

  /**
   * Returns an array with arrays of the given size.
   *
   * @param myArray {Array} array to split
   * @param chunk_size {Integer} Size of every group
   */
  function chunkArray(myArray, chunk_size) {
    var index = 0;
    var arrayLength = myArray.length;
    var tempArray = [] as Array<any>;

    for (index = 0; index < arrayLength; index += chunk_size) {
      var myChunk = myArray.slice(index, index + chunk_size);
      // Do something if you want with the group
      tempArray.push(myChunk);
    }

    return tempArray;
  }

  // Split in group of 3 items
  var chunksResults = chunkArray(
    courses.filter((cr) => cr.fkInstituteCourseType == 1),
    4
  );

  // Split in group of 3 items
  var chunksResultsCategories = chunkArray(allCategories, 3);
  const categoriesToRender = chunksResultsCategories.map((c, i) => (
    <Carousel.Item key={i}>
      {c.map((e) => (
        <Link
          key={i}
          to={"/landing/category/" + e.id}
          className="carousel-items"
        >
          <img className="d-block w-100" src={e.img} alt="Third slide" />
          <Carousel.Caption>
            <h3>{e.name}</h3>
          </Carousel.Caption>
        </Link>
      ))}
    </Carousel.Item>
  ));
  return (
    <ELTComponent>
      <div className="land-container">
        <div className="slider-health">
          <div className="row">
            <div className="col-12">
              <h2>
                <Translate>main.categorypreview.category</Translate>
              </h2>
              <h1>{selectedCategory != null ? selectedCategory.name : ""}</h1>
            </div>
          </div>
        </div>
        <div className="landing-section">
          <div className="container">
            <h1 className="landing-title">
              <Translate>main.categorypreview.featuredcourses</Translate>
            </h1>

            <Carousel className="slider courses-slider">
              {chunksResults.map((c, i) => (
                <Carousel.Item key={"k" + i}>
                  {c.map((e, ie) => (
                    <Link
                      key={"kc" + ie}
                      to={`/landing/course/${e.fkInstituteModule}`}
                      className="carousel-items"
                    >
                      <img className="d-block w-100" src={e.img} alt={e.name} />
                      <Carousel.Caption>
                        <h3>{e.name}</h3>
                      </Carousel.Caption>
                    </Link>
                  ))}
                </Carousel.Item>
              ))}
            </Carousel>

            <div className="carousel-small">{coursesByCategoryToRender}</div>
          </div>
        </div>
        <div className="landing-section bkg-accent-4">
          <div className="container">
            <h1 className="landing-title">
              <Translate>main.categorypreview.courses</Translate>
            </h1>
            <table className="category-table">
              <tr>
                <th>
                  <Translate>main.categorypreview.coursename</Translate>
                </th>
                <th>
                  <Translate>main.categorypreview.category</Translate>
                </th>
                <th>
                  <Translate>main.categorypreview.status</Translate>
                </th>
              </tr>
              {coursesByCategory.map((c, i) => (
                <tr key={i}>
                  <td>{c.name}</td>
                  <td>
                    <h1>
                      {selectedCategory != null ? selectedCategory.name : ""}
                    </h1>
                  </td>
                  <td>
                    <span className="material-icons success">check_circle</span>
                  </td>
                </tr>
              ))}
            </table>
          </div>
        </div>
        <div className="landing-section slider-video">
          <div className="container">
            <h1 className="landing-title">Browse Our Categories</h1>
            <Carousel className="slider">{categoriesToRender}</Carousel>
            <div className="carousel-small">{allCategoriesToRender}</div>
          </div>
        </div>
      </div>
    </ELTComponent>
  );
};

export default CategoryPreviewComponent;
