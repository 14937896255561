import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../data/Dashboard/dashBoardActions";
import VideoPlayer from "../course-video-player-components/VideoPlayer";

type PreviewModalProps= {
  previewItem:any,
  onClose:()=>void,
  userId:string
}
const PreviewModalComponent = ({ previewItem, onClose, userId } : PreviewModalProps) => {

  /* ********* Uses ********* */
  const dispatch = useDispatch();

  /* ********* Constants ********* */
  const buyUrl =
    previewItem && previewItem.buyInformation != null && previewItem.buyInformation.length >0
      ? previewItem.buyInformation[0].buyUrl
      : "";
  /* ********* Effects ********* */
  // useEffect(() => {
  //   console.log(JSON.stringify(previewItem));
  // }, [previewItem]);

  return (
    previewItem && (
      <div className="modal">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <button type="button" className="btn-close" onClick={onClose} ></button>
            <VideoPlayer
              videoSource={previewItem.previewVideoUrl}
              mustCompleteCourse={false}
            ></VideoPlayer>
            <div className="modal-body">
              <h2>{previewItem.name}</h2>
              <p>{previewItem.description}</p>
            </div>
            <div className="modal-footer">
              <div className="w-100 d-flex justify-content-between">
                <a
                  className="btn btn-flat-papergray text-warning"
                  onClick={onClose}
                >
                  Close
                </a>

                {!previewItem.isInWishlist && (
                  <a
                    onClick={() => {
                      onClose();
                      dispatch(
                        actions.addToWishlist(userId, previewItem.id)
                      );
                    }}
                  >
                    <a className="btn btn-flat-papergray text-secondary">
                      Add to Wish List
                    </a>
                  </a>
                )}

                  <a href={buyUrl}>
                    <a className="btn btn-flat-papergray text-success width-100p">
                      Buy Course
                    </a>
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default PreviewModalComponent;
