import React, { useState, useContext } from "react";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

type DeleteAdvertisementProps={
  show: boolean,
  onClose: ()=>void,
  onAccept:Function,
  selectedHighlight:any
}

const DeleteAdvertisementComponent = ({
  show,
  onClose,
  onAccept,
  selectedHighlight
}:DeleteAdvertisementProps ) => {
  return (
    <>
      <Modal
        size="lg"
        centered
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={() => onClose()}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          <Translate>reading.highligts.warningDeleteMessge</Translate>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
        <Button  
          className="btn-danger"
          onClick={() => onClose()}>
          <Translate>close</Translate>
        </Button>
        <Button
          className="btn-success"
          onClick={() => onAccept(selectedHighlight)}
        >
          <Translate>accept</Translate>
        </Button>
        </Modal.Footer>
      </Modal>


    {/* <Dialog
      open={show}
      onClose={() => onClose()}
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
    >
      <DialogContent>
        <div className="main-container-modal">
          <button className="close-btn" onClick={() => onClose()}>
            <img src={"../../assets/icons/close.png"} alt="" />
          </button>
          <div className="home-center-modal">
            <Translate>reading.highligts.warningDeleteMessge</Translate>
          </div>
        </div>
      </DialogContent>
      <DialogActions className="passwor-button-submit bottom-20">
        <Button  onClick={() => onClose()}>
          <Translate>close</Translate>
        </Button>
        <Button
          className=""
          onClick={() => onAccept(selectedHighlight)}
        >
          <Translate>accept</Translate>
        </Button>
      </DialogActions>
    </Dialog> */}
    </>

  );
};

export default DeleteAdvertisementComponent;
