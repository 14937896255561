import React, { createRef, useEffect, useRef, useState } from "react";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Translate from "translate-components";

/* ********* Actions ********* */
import { materials } from "../../data/Static/MyMaterialsData";
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";

/* ********* Components ********* */
import ELTComponentAuth from "../shared/ELTComponentAuth";
import CourseVideoItem from "./CourseVideoItem";
import CourseDiscussionItem from "./CourseDiscussionItem";
import CourseQuizItem from "./CourseQuizItem";
import CourseReadingItem from "./CourseReadingItem";
import CourseDownloadMaterialComponent from "./CourseDownloadMaterial";

import WhatToLearnNextComponent from "../dashboard-components/WhatToLearnNextComponent";
import LoadingComponent from "../shared/Loading";
import CourseMaterials from "../dashboard-components/Materials";
import PreviewModalComponent from "../dashboard-components/PreviewModal";
import { decodeNumber, encodeNumber } from "../../utils/strings";

import "../../styles/styles.scss";
const CourseVideosComponent = () => {
  const [state, setState] = useState({
    selectedGrade: "tgrViveListo",
    userId: 0,
    pageIndex: 1,
    pageSize: 2,
    filter: "",
    selectedCategory: 0,
    selectedVersion: 0,
    supportedSystem: process.env.REACT_APP_SUPPORTED_SYSTEM,
  });

  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { courseId } = useParams();
  const location = useLocation();
  const elementRefs = useRef({});
  
  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const userId = useSelector(
    (state: RootStateOrAny) => state.userSession.userId
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const course = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelected
  );
  const isAllowed = useSelector(
    (state: RootStateOrAny) => state.dashboard.isAllowed
  );

  const materialsData = materials["supportedSytem" + state.supportedSystem];
  const [selectedPreviewItem, setSelectedPreviewItem] = useState(null);

  /* ********* Effects ********* */
  useEffect(() => {
    if (!userSession.isLoggedIn()) {
      navigate("/");
    }
    getUserModule(decodeNumber(courseId), userId, lang);
  }, [lang]);

  useEffect(() => {
    if (isAllowed == false) {
      navigate(-1);
    }
  }, [isAllowed]);

 
  const isLoaded = course != null && typeof course.courses != "undefined";
  const coursesList = isLoaded ? course.courses : [];

  /* ********* Functions ********* */
  const getUserModule = (idModule, employeeId, lang) => {
    dispatch(dashboardActions.getUserModule(idModule, employeeId, lang, 0));
  };

  const haveMoreMaterials =
    materialsData?.othersMaterials.find(
      (o) => o.availableModule == decodeNumber(courseId)
    ) != null;

    useEffect(() => {
      const hash = location.hash.replace('#', '');
      if (hash) {
        const decodeId = decodeNumber(hash);
        if (decodeId != undefined && elementRefs.current[decodeId] &&  elementRefs.current[decodeId].current) {
          elementRefs.current[decodeId].current.scrollIntoView({ behavior: 'smooth' });
        }
      }
    }, [location,coursesList, isLoaded]);
  
  return (
    <ELTComponentAuth>
      <PreviewModalComponent
        previewItem={selectedPreviewItem}
        onClose={() => setSelectedPreviewItem(null)}
        userId={userSession.userId}
      ></PreviewModalComponent>
      <div id="CourseVideos">
        <div className="header-spacer">&nbsp;</div>
        {isLoaded ? (
          <div className="main-content">
            <div className="d-flex align-items-center right-20">
              <a id="right-btn-top" href="#root">
                <div className="d-flex align-items-center">
                  <span className="bottom-0 right-5 text-main">
                    Back to Top
                  </span>
                  <span className="content-type-marker bkg-primary">
                    <i className="material-icons">expand_less</i>
                  </span>
                </div>
              </a>
            </div>
            <div className="section-tilte">
              <h1>{course.name}</h1>
            </div>
            <div className="course-section-description">
              {course.description}
            </div>
            <div className="course-completion">
              <Translate>youhavecompleted</Translate>&nbsp;{" "}
              <span>
                {course.completedVideos > course.coursesCount
                  ? course.coursesCount
                  : course.completedVideos}
              </span>{" "}
              &nbsp;
              <Translate>off</Translate>&nbsp;<span>{course.coursesCount}</span>
              &nbsp;
              <Translate>lessonsinthiscourse</Translate>
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-center d-none d-md-block">
              <div className="d-flex flex-wrap align-items-center justify-content-between">
                {/* <div className="d-flex flex-wrap align-items-center">
                  <div className="d-flex align-items-center left-20">
                    <div className="content-type-marker bkg-accent4">
                      <i className="material-icons">ondemand_video</i>
                    </div>
                    <h6 className="bottom-0 left-5">
                      <Translate>title_video</Translate>
                    </h6>
                  </div>

                  <div className="d-flex align-items-center left-20">
                    <div className="content-type-marker bkg-warning">
                      <i className="material-icons">menu_book</i>
                    </div>
                    <h6 className="bottom-0 left-5">
                      <Translate>title_reading</Translate>
                    </h6>
                  </div>

                  <div className="d-flex align-items-center left-20">
                    <div className="content-type-marker bkg-secondary">
                      <i className="material-icons">fact_check</i>
                    </div>
                    <h6 className="bottom-0 left-5">
                      <Translate>title_quiz</Translate>
                    </h6>
                  </div>

                  <div className="d-flex align-items-center left-20">
                    <div className="content-type-marker bkg-success">
                      <i className="material-icons">supervised_user_circle</i>
                    </div>
                    <h6 className="bottom-0 left-5">
                      <Translate>title_discussion</Translate>
                    </h6>
                  </div>
                </div> */}
                {haveMoreMaterials && (
                  <div className="d-flex align-items-center right-20">
                    <a
                      href="#teacherResources"
                      className="  d-flex  align-items-center right-btn-resources"
                    >
                      <div className="content-type-marker bkg-accent5">
                        <i className="material-icons">note_add</i>
                      </div>
                      <h6 className="bottom-0 left-5 text-main">
                        Teacher Resources
                      </h6>
                    </a>
                  </div>
                )}
              </div>

              <div className="top-back-area">
                <div
                  onClick={() => navigate("/dashboard")}
                  className="btn btn-flat-background"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100"
                    height="100"
                    viewBox="0 0 100 100"
                  >
                    <polygon points="21.5 50 39.1 67.7 42.7 64.1 31 52.5 78.5 52.5 78.5 47.5 31 47.5 42.7 35.9 39.1 32.3 21.5 50 " />
                    <path d="M50 0C22.4 0 0 22.4 0 50c0 27.6 22.4 50 50 50 27.6 0 50-22.4 50-50C100 22.4 77.6 0 50 0zM50 95C25.2 95 5 74.8 5 50S25.2 5 50 5s45 20.2 45 45S74.8 95 50 95z" />
                  </svg>
                  <span>
                    <Translate>backtodahsboard</Translate>
                  </span>
                </div>
              </div>
            </div>
            <div className="courses-list">
              {coursesList.map((course, index) =>
              {
                if (!elementRefs.current[course.id]) 
                  elementRefs.current[course.id] = createRef();
                return course.fkInstituteCourseType == 1 ? (
                  <CourseVideoItem
                    idComponent={encodeNumber(course.id)}
                    refComponent={elementRefs.current[course.id]}
                    key={index}
                    course={course}
                    coursesList={coursesList}
                    goToReference={`/course/${courseId}/lesson/${encodeNumber(
                      course.id
                    )}`}
                  ></CourseVideoItem>
                ) : course.fkInstituteCourseType === 2 ? (
                  <CourseReadingItem
                    idComponent={encodeNumber(course.id)}
                    refComponent={elementRefs.current[course.id]}
                    key={index}
                    course={course}
                    coursesList={coursesList}
                    goToReference={`/course/${encodeNumber(course.id)}/epub`}
                  ></CourseReadingItem>
                ) : course.fkInstituteCourseType === 3 ? (
                  <CourseQuizItem
                    idComponent={encodeNumber(course.id)}
                    refComponent={elementRefs.current[course.id]}
                    key={index}
                    course={course}
                    coursesList={coursesList}
                    goToReference={`/course/${encodeNumber(course.id)}/quiz`}
                  ></CourseQuizItem>
                ) : course.fkInstituteCourseType === 4 ? (
                  <CourseDiscussionItem
                    idComponent={encodeNumber(course.id)}
                    refComponent={elementRefs.current[course.id]}
                    key={index}
                    course={course}
                    coursesList={coursesList}
                    goToReference={`${course.href}`}
                  ></CourseDiscussionItem>
                ):
                (
                  <CourseDownloadMaterialComponent     
                    idComponent={encodeNumber(course.id)}
                    refComponent={elementRefs.current[course.id]}
                    key={index}
                    course={course}
                    coursesList={coursesList}
                    goToReference={`${course.href}`}
                    />
                  )
              }
              )}
            </div>
          </div>
        ) : (
          <LoadingComponent isList={true} count={3} />
        )}
        {haveMoreMaterials && (
          <div className="bkg-papergray border-b-lightgray border-t-lightgray padding-bottom-20 padding-top-20 top-40">
            <div className="container">
              <div className="row bottom-0">
                <div className="col-12">
                  <div className="d-flex flex-wrap align-items-center justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="content-type-marker bkg-accent5 right-10">
                        <i className="material-icons">note_add</i>
                      </div>
                      <h1 className="dash smaller top-0 bottom-0 left-10">
                        Teacher Resources
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <CourseMaterials
              id="teacherResources"
              data={materialsData}
              selectedGrade={materialsData.grades.find(
                (g) => g.key === state.selectedGrade
              )}
              version={state.selectedVersion}
              module={course.id}
              hidelinks={true}
            />
          </div>
        )}
        <WhatToLearnNextComponent
          userId={userId}
          lang={lang}
          showPreview={setSelectedPreviewItem}
        ></WhatToLearnNextComponent>
        <div className="footer-spacer"></div>
      </div>
    </ELTComponentAuth>
  );
};

export default CourseVideosComponent;
