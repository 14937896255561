import React, { useState, useEffect } from "react";
import Translate from "translate-components";
import moment from "moment";
const TimeHeader = ({
  timeToFinish,
  isLoaded,
  onSaveAndCloseQuiz,
  disabled,
}) => {
  const [stop, setStop] = useState(false);
  const [date, setDate] = useState(timeToFinish);
  const [isLoading, setIsLoading] = useState(true);
  const getDifference = () => {
    return +moment.utc(date) - + moment.utc(new Date()); //dateNow.utc();
  };
  const calculateTimeLeft = () => {
    if (isLoading || stop) return 0;
    //let difference = +new Date(`11/26/${year} 13:25:00`) - +new Date();
    let difference = getDifference();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      setStop(true);
      onSaveAndCloseQuiz();
    }

    return timeLeft;
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
  const initDate = () => {
    setTimeLeft(calculateTimeLeft());
    setDate(timeToFinish);
    if (isLoaded == true) {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
      initDate();
    }, 1000);
    // Clear timeout if the component is unmounted
    return () => clearTimeout(timer);
  });

  const timerComponents = [] as Array<any>;

  //Hours
  timerComponents.push(
    <>
      {("0" + (timeLeft["hours"] != null ? timeLeft["hours"] : "00")).slice(
        -2
      ) + ":"}
    </>
  );
  //Minutes
  timerComponents.push(
    <>
      {("0" + (timeLeft["minutes"] != null ? timeLeft["minutes"] : "00")).slice(
        -2
      ) + ":"}
    </>
  );
  //Seconds
  timerComponents.push(
    <>
      {("0" + (timeLeft["seconds"] != null ? timeLeft["seconds"] : "00")).slice(
        -2
      )}
    </>
  );

  return disabled ? (
    <div className="remaining-time">
      <h3>
        <Translate>quiz_remaining_time</Translate>
      </h3>
      <h3>00:00:00</h3>
    </div>
  ) : (
    <div className="remaining-time">
      {isLoading == true ? (
        <>
          <h3>
            <Translate>quiz_remaining_time</Translate>
          </h3>
          <h3>00:00:00</h3>
        </>
      ) : (
        <>
          <h3>
            <Translate>quiz_remaining_time</Translate>
          </h3>
          <h3>
            {getDifference() > 0 ? (
              timerComponents
            ) : (
              <span>
                <Translate>quiz_times_up</Translate>
              </span>
            )}
          </h3>
        </>
      )}
    </div>
  );
};

export default TimeHeader;
