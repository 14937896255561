import * as types from "../constants/actionTypes";

const initialState = {
  error: null,
  certificateLoading:false,
  data: null,
};

export default function readingReducer(state = initialState, action) {
  switch (action.type) {
    case types.AUTHENTICATION_CERTIFICATES_GET_BY_ID: {
      const { data } = action.payload;
      return {
        ...state,
        data: data,
      };
    }
    case types.AUTHENTICATION_CERTIFICATES_END_DOWNLOAD_CERTIFICATE:{
      return{...state,certificateLoading:false}
    }
    case types.AUTHENTICATION_CERTIFICATES_ON_CERTIFICATE_DOWNLOAD:{
      return{...state,certificateLoading:true}
    }

    default:
      return state;
  }
}
