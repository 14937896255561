import axios from "axios";
//import querystring from 'querystring';

class ResetTokenPasswordService {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi + "/PasswordResetToken/",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  validateResetPassword(token) {
    const theUrl = `ValitateResetToken/${token}`;
    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  updatePassword(token, model) {
    const theUrl = `UpdatePasswordWithToken/${token}`;
    return this.axiosClient.post(`${theUrl}`, model).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default ResetTokenPasswordService;
