import React from "react";
import WishlistItemComponent from "./WishlistItemComponent";

type WishListProps={
  wishList:[any],
  showPreview:Function
}

const WishlistComponen = ({ wishList, showPreview }:WishListProps) => {
  return (
    <>
      <div className="dashcoursesArea">
        <div className="container">
          <WishlistItemComponent
            items={wishList}
            showPreview={showPreview}
          ></WishlistItemComponent>
        </div>
      </div>
    </>
  );
};

export default WishlistComponen;
