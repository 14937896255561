import React from 'react';
//import TopNavigationBar from './TopNavigationBar';
//import PropTypes from 'prop-types';

const UnderConstructionPage = (/*props*/) => {

    return (
        <div>
            <img src="../../assets/images/offline.svg" alt="" className="center-under-construction" />
        </div>
    );
};

UnderConstructionPage.propTypes = {
    
};

export default UnderConstructionPage;
