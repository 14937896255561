import * as types from "../constants/actionTypes";

const initialState = {
  error: null,

  highlights: [],
  bookmarks: [],
  configuration: null,

  isLoaded: false,
  isBookmarksSaved: false,
  isHighligtsSaved: false,
  isConfigurationSaved: false,
  lastLocation: "",
  sendHighlightsByEmail: false
};

export default function readingReducer(state = initialState, action) {
  switch (action.type) {
    case types.SAVE_READING_BOOKMARKS: {
      return {
        ...state,
        isBookmarksSaved: true
      };
    }
    case types.SAVE_READING_HIGHLIGHTS: {
      return {
        ...state,
        isHighligtsSaved: true
      };
    }
    case types.SAVE_READING_CONFIGURATION: {
      return {
        ...state,
        isConfigurationSaved: true
      };
    }

    case types.SAVE_READING_LAST_LOCATION: {
      const { data } = action.payload;
      return {
        ...state,
        lastLocation: data
      };
    }

    case types.GET_READING_BOOKMARKS: {
      const { data } = action.payload;
      return {
        ...state,
        bookmarks: data
      };
    }

    case types.GET_READING_HIGHLIGHTS: {
      const { data } = action.payload;
      return {
        ...state,
        highlights: data
      };
    }

    case types.GET_READING_CONFIGURATION: {
      const { data } = action.payload;
      return {
        ...state,
        configuration: data
      };
    }

    case types.READING_SEND_HIGHLIGHTS_EMAIL: {
      return {
        ...state,
        sendHighlightsByEmail: true
      };
    }

    case types.READING_SEND_HIGHLIGHTS_EMAIL_OFF: {
      return {
        ...state,
        sendHighlightsByEmail: false
      };
    }

    default:
      return state;
  }
}
