import React, { useEffect } from "react";
import Translate from "translate-components";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";
import { useParams, useNavigate } from "react-router-dom";
import ELTComponentAuth from "../shared/ELTComponentAuth";
import { decodeNumber, encodeNumber } from "../../utils/strings";
const MainQuizComponent = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const course = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
  );
  const { courseId } = useParams();
  useEffect(() => {
    dispatch(
      dashboardActions.getUserModuleCourse(
        decodeNumber(courseId),
        userSession.userId,
        lang
      )
    );
  }, [lang]);

  return (
    <ELTComponentAuth>
      <div className="top-100">&nbsp;</div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="card p-2">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="p-3 border-bottom">{course.name}</h1>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-4 min-height-200">
                  <div className="startquiz-image"></div>
                </div>
                <div className="col-12 col-md-8">
                  <div className="padding-all-10 text-center">
                    <h1 className="center text-success top-10">
                      <Translate>quiz_welcome</Translate>
                    </h1>
                    <h4 className="text-center">
                      <Translate>quiz_about_start_quiz</Translate>
                    </h4>

                    <h3 className="text-center text-accent4 top-40 bottom-40">
                      {course.name}
                    </h3>

                    <p>
                      <Translate>quiz_take_quiz_many_time</Translate>
                    </p>
                    <p>
                      <Translate>quiz_higuest_achieved_score</Translate>
                    </p>
                    <hr />
                    <div className="row">
                      <div className="col-12 col-sm-6 top-10">
                        <a
                          // href={`/course/${courseId}`}
                          onClick={() => navigate("/course/" + encodeNumber(course.fkInstituteModule)+"#"+encodeNumber(course.id))}
                          className="btn btn-primary width-100p"
                        >
                          <Translate>back</Translate>
                        </a>
                      </div>
                      <div className="col-12 col-sm-6 top-10">
                        <a
                          href={`/course/${courseId}/quiz/questionary`}
                          className="btn btn-success width-100p"
                        >
                          <Translate>start_quiz</Translate>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="top-30" />
    </ELTComponentAuth>
  );
};

export default MainQuizComponent;
