import React, { useEffect, useState } from "react";
import Translate from "translate-components";

/* ********* Components ********* */
import ELTComponent from "../shared/ELTComponent";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import * as actions from "../../data/EmployeeLicense/employeeLicenseActions";
import * as userActions from "../../data/UserSession/userSessionActions.js";
const LicenseActivation = () => {
  const dispatch = useDispatch();
  const { activationToken } = useParams();
  const navigate = useNavigate();

  const passwordUpdated = useSelector(
    (state: RootStateOrAny) => state.userSession.passwordUpdated
  );
  const requestDeparted = useSelector(
    (state: RootStateOrAny) => state.employeeLicense.requestDeparted
  );
  const requestArrived = useSelector(
    (state: RootStateOrAny) => state.employeeLicense.requestArrived
  );
  const employeeActivated = useSelector(
    (state: RootStateOrAny) => state.employeeLicense.employeeToActive
  );
  useEffect(() => {
    //Dispatch the Activation'
    if (activationToken) {
      dispatch(actions.activateEmployeeSeat(activationToken));
    }
  }, [activationToken]);

  useEffect(() => {
    if (passwordUpdated) navigate("/login");
  }, [passwordUpdated]);
  const [showModal, setShowModal] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isPasswordCharactersValid, setIsPasswordCharactersValid] = useState(false);
  const [isPasswordConfirmationValid, setIsPasswordConfirmationValid] = useState(false);
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordNumberOrSpecialCharValid, setIsPasswordNumberOrSpecialCharValid] = useState(false);
  const [isPasswordUpperAndLowerCaseValid, setIsPasswordUpperAndLowerCaseValid] = useState(false);
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  

  const updatePassword = () => {
    if(!checkIsPasswordValid(password, confirmPassword))
      return;

      //Todo update the password
      dispatch(
        userActions.updatePasswordForSeat(
          employeeActivated.employeeId,
          password,
          confirmPassword
        )
      );
  };

  useEffect(()=>{

    setIsPasswordUpperAndLowerCaseValid(checkDoesPasswordContainUpperAndLowerCase(password));
    setIsPasswordNumberOrSpecialCharValid(checkDoesPasswordContainNumberOrSpecialChar(password));
    setIsPasswordLengthValid(checkPasswordLengthValid(password));
    setIsPasswordConfirmationValid(checkIsPasswordConfirmationValid(password, confirmPassword));
    setIsPasswordValid(checkIsPasswordValid(password,confirmPassword));

  },[password, confirmPassword])


  const checkPasswordLengthValid = (password) => password.length >= 8;
  const checkIsPasswordConfirmationValid = (password, passwordConfirmation) => password === passwordConfirmation;
  const checkDoesPasswordContainUpperAndLowerCase = (password: string) => {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    return hasUpperCase && hasLowerCase;
  };
  const checkDoesPasswordContainNumberOrSpecialChar = (password: string) => {
    const hasNumberOrSpecialChar = /[0-9!@#$%^&*]/.test(password);
    return hasNumberOrSpecialChar;
  };
  const checkIsPasswordValid = (password: string, passwordConfirmation: string) => {
    return checkPasswordLengthValid(password) && 
           checkIsPasswordConfirmationValid(password, passwordConfirmation) &&
           checkDoesPasswordContainUpperAndLowerCase(password) &&
           checkDoesPasswordContainNumberOrSpecialChar(password);
  };

  return (
    <ELTComponent>
      <div className="padding-top-100 bkg-papergray border-b-lightgray">
        <div className="container">
          <div className="row">
            <div className="col-12 py-4">
              <h1 className="dash">
                <Translate>LicenseActivation</Translate>
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="adminLicenses py-3">
        <div className="container">
          {requestDeparted && !requestArrived && (
            <div className="row  justify-content-center">
              <div className="col-12">
                <p className="row">
                  <Translate>LicenseYouWereInvited</Translate>
                </p>
                <h2 className="row">{employeeActivated?.courseName}</h2>
                <p className="row">
                  <Translate>LicensePleaseWaitWhile</Translate>
                </p>

                <div className="loading-icon">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            </div>
          )}

          {requestArrived && employeeActivated != null && (
            <div className="row  justify-content-center">
              <div className="col-10 col-sm-6 my-3 ">
                <div className="card p-4 text-center">
                  <h2>
                    <Translate>LicenseActivateSuccessfully</Translate>
                  </h2>

                  {/* <p>
                    <Translate>LicenseInstructionAndCredentials</Translate>
                  </p> */}
                  <h2>{employeeActivated?.courseName}</h2>

                  {employeeActivated.isNew ? (
                    <p className="mt-4">
                      <a className=" btn btn-flat-success" href="/login">
                        <Translate>LicenseClickHereLogin</Translate>
                      </a>
                    </p>
                  ) : (
                    <Modal
                      size="lg"
                      centered
                      aria-labelledby="contained-modal-title-vcenter"
                      show={showModal}
                      onHide={() => setShowModal(false)}
                    >
                      <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                          <Translate>Set a password</Translate>
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <div className="d-flex flex-column top-20">
                          <Translate>LicenseActivationPassword</Translate>

                          <div className="d-flex flex-column top-20">
                            <label>
                              <Translate>Enter.New.Password.title</Translate>
                            </label>
                            <input
                              type="password"
                              value={password}
                              className="passworText"
                              required={true}
                              onChange={(v) => setPassword(v.target.value)}
                            ></input>
                          </div>
                          <div className="d-flex flex-column top-20">
                            <label>
                              <Translate>Enter.Confirmation.title</Translate>
                            </label>
                            <input
                              type="password"
                              className="passworText"
                              required={true}
                              value={confirmPassword}
                              onChange={(v) =>
                                setConfirmPassword(v.target.value)
                              }
                            ></input>
                          </div>
                          {password != confirmPassword && (
                            <div>
                              <p className="text-warning top-20">
                                <Translate>
                                  LicenseActivation_PassDoesntMatch
                                </Translate>
                              </p>
                            </div>
                          )}

                        <ul className="passwordCheckList">
                          <li className={isPasswordUpperAndLowerCaseValid?"valid":"invalid"}><span className="icon"></span><span> </span> Include both lower and upper case characters</li>
                          <li className={isPasswordNumberOrSpecialCharValid?"valid":"invalid"}><span className="icon"></span><span> </span>  At least one number or special character</li>
                          <li className={isPasswordLengthValid?"valid":"invalid"}><span className="icon"></span><span> </span>  A least 8 characters long</li>
                          <li className={isPasswordConfirmationValid?"valid":"invalid"}><span className="icon"></span><span> </span>  Match confirmation</li>
                        </ul>
                        </div>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button
                          className="btn-danger"
                          onClick={() => setShowModal(false)}
                        >
                          <Translate>close</Translate>
                        </Button>
                        <Button
                          className={isPasswordValid ? "btn-success" : "btn"}
                          onClick={() => updatePassword()}
                        >
                          <Translate>accept</Translate>
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  )}
                </div>
              </div>
            </div>
          )}

          {requestArrived && employeeActivated == null && (
            <div className="row  justify-content-center">
              <div className="col-10 col-sm-6 my-3 ">
                <div className="card p-4 text-center line-warning">
                  <h2 className="text-warning">
                    <Translate>LicenseCannotActive</Translate>
                  </h2>
                  <p>
                    <Translate>LicenseInvitationLicense</Translate>
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </ELTComponent>
  );
};

export default LicenseActivation;
