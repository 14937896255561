import * as types from "../constants/actionTypes";

const initialState = {
  error: null,
  products: null,
  employeeToActive: null,
  requestDeparted: false,
  requestArrived: false,
};

export default function employeeReducer(state = initialState, action) {
  switch (action.type) {
    case types.REQUEST_DEPARTED: {
      return {
        ...state,
        requestDeparted: true,
        requestArrived: false,
      };
    }
    case types.REQUEST_ARRIVED: {
      return {
        ...state,
        requestDeparted: false,
        requestArrived: true,
      };
    }
    case types.RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_FULFILLED: {
      return {
        ...state,
        products: action.payload.data,
        error: null,
      };
    }
    case types.ENROLL_EMPLOYEELINCENSE_FULFILLED: {
      var resultProducts = JSON.parse(JSON.stringify(state.products));
      var employeeSeat = action.payload.data;
      var pIndex = resultProducts.findIndex(
        (p) => (p.fkInstituteProduct = employeeSeat.productId)
      );

      var oldProduct = resultProducts[pIndex];
      oldProduct.employeeSeats.push({
        fkIdEmployee: employeeSeat.employeeId,
        fullname: employeeSeat.guestName,
        email: employeeSeat.guestEmail,
        idEmployeeLicenseSeat: employeeSeat.idEmployeeLicenseSeat,
        status: 0,
      });

      oldProduct.availableSeats = oldProduct.availableSeats - 1;
      resultProducts.splice(pIndex, 1, oldProduct);
      return {
        ...state,
        products: resultProducts,

        error: null,
      };
    }

    case types.ACTIVATE_EMPLOYEE_FULFILLED: {
      return {
        ...state,
        employeeToActive: action.payload.data,
        error: null,
      };
    }
    case types.DELETE_LICENSE_FULFILLED: {
      var { productId, seatId } = action.payload.data;
      var rProducts = JSON.parse(JSON.stringify(state.products));
      var rIndex = rProducts.findIndex(
        (p) => (p.fkInstituteProduct = productId)
      );
      var prevProduct = JSON.parse(JSON.stringify(rProducts[rIndex]));
      var pSeatIndex = prevProduct.employeeSeats.findIndex(
        (es) => es.idEmployeeLicenseSeat == seatId
      );
      prevProduct.employeeSeats.splice(pSeatIndex, 1);

      prevProduct.availableSeats = prevProduct.availableSeats + 1;
      rProducts.splice(rIndex, 1, prevProduct);
      return {
        ...state,
        products: rProducts,
        error: null,
      };
    }
    case types.DELETE_LICENSE_EMPLOYEE_ERROR:
    case types.ACTIVATE_EMPLOYEE_ERROR:
    case types.ENROLL_EMPLOYEELINCENSE_ERROR:
    case types.RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_ERROR: {
      return {
        ...state,
        products: null,
        error: action.payload,
      };
    }
    default:
      return state;
  }
}
