import jwt_decode from "jwt-decode";

const userSessionExtension = {
  hasExpired(expireDate) {
    const now = Date.now().valueOf() / 1000;
    if (typeof expireDate !== "undefined" && expireDate < now) {
      return true;
    }

    return false;
  },

  isLoggedIn(token, expireDate) {
    if (token === undefined || expireDate === undefined) {
      return false;
    }

    return Boolean(token && !userSessionExtension.hasExpired(expireDate));
  },

  saveClaims(authResponse) {
    localStorage.setItem("email", authResponse.email);
    localStorage.setItem("username", authResponse.username);
    localStorage.setItem("firstName", authResponse.firstName);
    localStorage.setItem("lastName", authResponse.lastName);
    localStorage.setItem("imagePath", authResponse.imagePath);
    localStorage.setItem(
      "userHasLogged",
      authResponse.userHasLogged === null
        ? null
        : Boolean(authResponse.userHasLogged)
    );
    localStorage.setItem(
      "hasLicenses",
      authResponse.hasLicenses == null
        ? false
        : Boolean(authResponse.hasLicenses)
    );
    localStorage.setItem("token", authResponse.token);
    localStorage.setItem("curriculumVersion", authResponse.curriculumVersion);
    localStorage.setItem("securityProfiles", authResponse.securityProfiles);
    localStorage.setItem(
      "availableCurriculumns",
      authResponse.availableCurriculumns
    );
    let claims = jwt_decode(authResponse.token);

    localStorage.setItem("expire", parseInt(claims.exp));
    localStorage.setItem("uid", parseInt(claims.uid));
  },
};

export default userSessionExtension;
