import React from "react";
import { useDispatch, useSelector,RootStateOrAny } from "react-redux";
import Translate from "translate-components";
import "../../utils/strings";

/* ********* Actions ********* */
import * as actions from "../../data/Dashboard/dashBoardActions";

type WishlistProps={
  items:[any],
  showPreview:Function
}
const WishlistItemComponent = ({ items, showPreview }:WishlistProps) => {

  /* ********* Uses ********* */
  const dispatch = useDispatch();

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state:RootStateOrAny) => state.userSession);

  return (
    <div className="row">
      {items.length > 0 ? (
        items.map((item, index) => {
          const buyUrl =
            item.buyInformation != null && item.buyInformation.length > 0
              ? item.buyInformation[0].buyUrl
              : "";
          return (
            <div className="col-12 col-lg-6 " key={index}>
              <div className="dashCourse">
                <div className="dashCourseThumb">
                  <a onClick={() => showPreview(item)}>
                    <img
                      src={item.previewThumbnailVideo}
                      className="splash"
                      alt=""
                    />
                  </a>
                </div>
                <div className="dashCourseDetailsContainer">
                  <div className="dashCourseDetails">
                    <div className="courseText">
                      <h6 className="bottom-0 top-5 text-truncate">
                        {item.name}
                      </h6>
                      <p>{item.description.trunc(100)}</p>
                    </div>
                  </div>

                  <div className="courseFooter">
                    <div className="d-flex flex-column flex-column-reverse flex-sm-row justify-content-between">
                      <a href={buyUrl} target="_top" className="startCourse">
                        <Translate>Dashboard_Buy_Course</Translate>
                      </a>
                      <a
                        className="startCourse"
                        onClick={() =>
                          dispatch(
                            actions.removeToWishlist(
                              userSession.userId,
                              item.id
                            )
                          )
                        }
                      >
                        <Translate>Remove_Wishlist</Translate>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="no-courses-message">
          <span>
            <Translate>coursestoshow</Translate>
          </span>
        </div>
      )}
    </div>
  );
};

export default WishlistItemComponent;
