import * as React from "react";
import Translate from "translate-components";
import CourseItemComponent from "./CourseItem";
import GetPriceByModuleOrGroup from "../../data/UpfrontPrices/UpfrontPrices";
interface GroupProps {
  group: any;
  showModal: any;
}

const renderGroupPrice = (groupId: any) => {
  var price = GetPriceByModuleOrGroup(groupId, false);

  if (price) {
    return (
      <>
        <Translate>Group_All_For_Just</Translate> {price} <sup>USD</sup>
      </>
    );
  }
};

const Group = ({ group, showModal }: GroupProps) => {
  return (
    <div className="row">
      <div className="col-12 py-3">
        <div className="landing-Section-Header">
          <div>
            <h1>
              {" "}
              <Translate>{group.name}</Translate>
            </h1>
            <p>
              <Translate>{group.description}</Translate>
            </p>
          </div>
          {group.buyAllgroup == true && (
            <div className="d-flex align-items-center">
              <div className="fs-5 px-2">{renderGroupPrice(group.id)}</div>
              <a className="btn btn-success h-auto" href={group.buyUrl}>
                {" "}
                <Translate>Dashboard_Buy_Course</Translate>
              </a>
            </div>
          )}
        </div>
        <div className="bkg-lightgray p-3">
          <div className="row learn-more-courses justify-content-center">
            {group.modules.map((m, i) => (
              <CourseItemComponent
                key={i}
                {...m}
                buyAllgroup={group.buyAllgroup}
                showModal={showModal}
              ></CourseItemComponent>
            ))}

            <div className="col-8 py-4">
              {group.buyAllgroup == true && (
                <a className="btn btn-success h-auto w-100" href={group.buyUrl}>
                  {" "}
                  <Translate>Dashboard_Buy_Course</Translate>
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Group;
