import * as types from "../constants/actionTypes";
import update from "immutability-helper";
import appHelper from "../constants/applicationHelper";
import { simpleDecryption, simpleEncryption } from "../../utils/strings";

import jwt_decode from "jwt-decode";

const initialState = {
  subscriptionPlan: null,
  userInfo: null,
  listOfProducts: null,
  selectedProduct: null,
  cart: [],
  submittingOrder: false,
  purchaseSuccessful: false,
  invoiceId: "",
  orderId: "",
  order: null,
  errorFromSalesTaxService: null,
  taxAmountInCents: 0,
  shippingFeeInCents: 0,
  requestDeparted: false,
  requestArrived: true,
  signInError: "",
  signUpError: "",
  backEndError: null,
  errorManager: null,
  isFirstSubscriptionPlanRender: true,
  productPrices: [],
  refreshToken: simpleDecryption(localStorage.getItem("rt")),
  accessToken: simpleDecryption(localStorage.getItem("at")),
  accessTokenExpiration: simpleDecryption(localStorage.getItem("ex")),
  isAuthenticated: appHelper.isLoggedIn(
    simpleDecryption(localStorage.getItem("at")),
    simpleDecryption(localStorage.getItem("ex")),
    simpleDecryption(localStorage.getItem("u")),
    simpleDecryption(localStorage.getItem("e"))
  ),
  firstName: "",
  lastName: "",
  userName: "",
  email: "",
  error: "",
  showSignUpConfirmation: false,
};

export default function subscriptionPlanReducer(
  state = initialState,
  action = null
) {
  switch (action.type) {
    case types.GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_FULFILLED: {
      let arr = [];
      let item = action.payload;

      arr.push({
        productId: item.idSubscriptionPlan,
        description: item.description,
        fkSubscriptionStatus: item.fkSubscriptionStatus,
        isDigitalProduct: false,
        quantity: 1,
        price: item.subscriptionPlanDetail.amountInCents,
        name: item.name,
        cycle: item.subscriptionPlanDetail.cycle,
        recurrence: item.subscriptionPlanDetail.recurrence,
        amountDays: item.subscriptionPlanDetail.amountDays,
        isFree: item.subscriptionPlanDetail.isFree,
        stopCycleNumber: item.subscriptionPlanDetail.stopCycleNumber,
        fkSubscriptionProduct: item.fkSubscriptionProduct,
        stripeProductId: item.stripeProductId,
        stripePriceId: item.stripePriceId,
        daysOfTrial: item.subscriptionPlanDetail.daysOfTrial,
      });
      return {
        ...state,
        cart: arr,
        errorManager: null,
      };
    }
    case types.GET_BY_DESCRIPTION_SUBSCRIPTION_PLAN_REJECTED: {
      return {
        ...state,
        cart: [],
        errorManager: action.payload.detail,
      };
    }

    case types.ADD_USER_WITH_SUBSCRIPTION_PLAN_FULFILLED: {
      return {
        ...state,
        submittingOrder: false,
        subscriptionPlan: action.payload,
        order: action.payload,
        backEndError: null,
      };
    }
    case types.ADD_USER_WITH_SUBSCRIPTION_PLAN_REJECTED: {
      return {
        ...state,
        submittingOrder: false,
        subscriptionPlan: null,
        order: null,
        backEndError: action.payload.detail,
      };
    }

    case types.CLEAR_BACKEND_ERROR: {
      return {
        ...state,
        backEndError: null,
      };
    }

    case types.CLEAR_CART: {
      return {
        ...state,
        cart: [],
      };
    }

    case types.CLEAR_ORDER: {
      return {
        ...state,
        submittingOrder: false,
        subscriptionPlan: null,
        order: null,
        backEndError: null,
      };
    }

    case types.CLEAR_SIGN_UP_CONFIRMATION: {
      return {
        ...state,
        showSignUpConfirmation: false,
      };
    }

    case types.DELETE_PRODUCT_FROM_CART: {
      let productId = action.payload.productId;

      if (state.cart.length > 0) {
        return {
          ...state,
          cart: state.cart.filter((item) => item.productId !== productId),
          purchaseSuccessful: false, // purchase not done yet
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case types.PURCHASE_SUCCESSFUL_FLAG: {
      return {
        ...state,
        purchaseSuccessful: action.payload,
      };
    }

    case types.SUBMITTING_ORDER_FLAG: {
      return {
        ...state,
        submittingOrder: action.payload,
      };
    }

    case types.UPDATE_CART_QUANTITY: {
      let productId = action.payload.productId;
      let newQuantity = action.payload.quantity;

      if (state.cart.length > 0) {
        let updatedCart = [...state.cart];

        const productInCartIndex = updatedCart.findIndex(
          (item) => item.productId === productId
        );

        if (productInCartIndex !== -1) {
          updatedCart = update(updatedCart, {
            [productInCartIndex]: {
              quantity: { $set: parseInt(newQuantity) },
            },
          });
        }

        return {
          ...state,
          cart: updatedCart,
          purchaseSuccessful: false, // purchase not done yet
        };
      }

      return { ...state };
    }

    case types.GET_PRODUCT_PRICES_FULFILLED: {
      return {
        ...state,
        productPrices: action.payload,
      };
    }

    case types.GET_PRODUCT_PRICES_REJECTED: {
      let theError = "";
      if (action.payload.response.data.error[0]) {
        theError = action.payload.response.data.error[0];
      } else {
        theError = "Email and/or password are not correct.";
      }
      return {
        ...state,
        productPrices: null,
        error: theError,
      };
    }
    case types.REQUEST_DEPARTED: {
      return {
        ...state,
        requestDeparted: true,
        requestArrived: false,
      };
    }

    case types.REQUEST_ARRIVED: {
      return {
        ...state,
        requestDeparted: false,
        requestArrived: true,
      };
    }

    case types.LOGIN_FULFILLED: {
      let authResponse = JSON.parse(action.payload.data);

      localStorage.setItem("rt", simpleEncryption(authResponse.refresh_token));
      localStorage.setItem("at", simpleEncryption(authResponse.access_token));
      localStorage.setItem("st", simpleEncryption(authResponse.simple_token));
      localStorage.setItem("ex", simpleEncryption(authResponse.expires));

      let otherClaims = jwt_decode(authResponse.access_token);

      const isAuthenticated = appHelper.isLoggedIn(
        simpleDecryption(localStorage.getItem("at")),
        simpleDecryption(localStorage.getItem("ex")),
        otherClaims.nameid,
        otherClaims.email
      );

      if (isAuthenticated) {
        localStorage.setItem("fn", simpleEncryption(authResponse.firstName));
        localStorage.setItem("ln", simpleEncryption(authResponse.lastName));
        localStorage.setItem("u", simpleEncryption(otherClaims.nameid));
        localStorage.setItem("e", simpleEncryption(otherClaims.email));
        localStorage.setItem("un", simpleEncryption(authResponse.userName));

        return {
          ...state,
          isAuthenticated: isAuthenticated,
          accessToken: authResponse.access_token,
          refreshToken: authResponse.refresh_token,
          accessTokenExpiration: authResponse.expires,

          firstName: authResponse.firstName,
          lastName: authResponse.lastName,
          userName: authResponse.userName,
          userId: otherClaims.nameid,
          email: otherClaims.email,
          signInError: "",
        };
      } else {
        return {
          ...state,
          isAuthenticated: isAuthenticated,
          accessToken: "",
          refreshToken: "",
          accessTokenExpiration: "",
          firstName: "",
          lastName: "",
          userName: "",
          userId: "",
          email: "",
          signInError: "",
        };
      }
    }

    case types.LOGIN_REJECTED: {
      let theError = "";
      if (action.payload.response.data.error[0]) {
        theError = action.payload.response.data.error[0];
      } else {
        theError = "Email and/or password are not correct.";
      }

      localStorage.setItem("rt", "");
      localStorage.setItem("at", "");
      localStorage.setItem("ex", "");
      localStorage.setItem("fn", "");
      localStorage.setItem("ln", "");

      const isAuthenticated = appHelper.isLoggedIn(
        simpleDecryption(localStorage.getItem("at")),
        simpleDecryption(localStorage.getItem("ex")),
        simpleDecryption(localStorage.getItem("u")),
        simpleDecryption(localStorage.getItem("e"))
      );

      return {
        ...state,
        isAuthenticated: isAuthenticated,
        accessToken: "",
        refreshToken: "",
        accessTokenExpiration: "",
        firstName: "",
        lastName: "",
        userName: "",
        userId: "",
        email: "",
        signInError: theError,
      };
    }

    case types.CLEAR_SIGN_IN_ERROR: {
      return {
        ...state,
        signInError: null,
      };
    }

    case types.CLEAR_SIGN_UP_ERROR: {
      return {
        ...state,
        signUpError: null,
      };
    }

    case types.SET_IS_FIRST_SUBSCRIPTION_PLAN_RENDER: {
      return {
        ...state,
        isFirstSubscriptionPlanRender: action.payload,
      };
    }
  }

  return state;
}
