import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import Translate from "translate-components";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { decodeNumber } from "../../utils/strings";

import "../../styles/styles.scss";

/* ********* Actions ********* */
import * as dashboardActions from "../../data/Dashboard/dashBoardActions";
import * as landingActions from "../../data/Landing/landingActions";

/* ********* Components ********* */
import LoadingComponent from "../shared/Loading";
import ELTComponentAuth from "../shared/ELTComponentAuth";

import { encodeNumber } from "../../utils/strings";
const CourseVideoItem = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, courseId } = useParams();
  const [videoEnded, setVideoEnded] = useState(false);
  const feedbackDescription = useRef() as React.MutableRefObject<
    HTMLTextAreaElement
  >;

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const userId = useSelector(
    (state: RootStateOrAny) => state.userSession.userId
  );
  const isLoadingState = useSelector(
    (state: RootStateOrAny) => state.application.isLoading
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const video = useSelector(
    (state: RootStateOrAny) => state.dashboard.courseSelectedVideo
  );
  const feedback = useSelector(
    (state: RootStateOrAny) => state.dashboard.feedback
  );
  const showFeedbackState = useSelector(
    (state: RootStateOrAny) => state.dashboard.showFeedback
  );

  /* ********* Constants ********* */
  const isLoading = video.previewVideoUrl == "undefined";
  const showFeedback = showFeedbackState && videoEnded;

  /* ********* Effects ********* */
  useEffect(() => {
    //If the Users is not longer Signed In
    if (!userSession.isLoggedIn()) {
      navigate("/");
    }
    dispatch(
      dashboardActions.getUserModuleCourse(decodeNumber(id), userId, lang)
    );
  }, []);

  /* ********* Functions ********* */
  const handleOnEnded = () => {
    //Call to Set The Course as Completed
    dispatch(landingActions.setCourseCompleted(userId, decodeNumber(id)));
    setVideoEnded(true);
  };

  const handleUpdateDescription = () =>
    dispatch(
      dashboardActions.updateFeedbackDescription(
        feedbackDescription.current.value
      )
    );

  const handleUploadFeedback = () =>
    dispatch(
      dashboardActions.uploadFeedback({
        ...feedback,
        fkUserId: userId,
        fkCourseId: decodeNumber(id),
      })
    );

  const handleFeedbackDismiss = () =>
    dispatch(dashboardActions.dismissFeedback());

  const updateScore = (pscore) =>
    dispatch(dashboardActions.updateFeedbackScore(pscore));

  return (
    <ELTComponentAuth>
      <div id="VideoItem">
        <div className="header-spacer" />

        {!isLoading ? (
          <>
            <div className="videoTitle">
              <h1>{video.name}</h1>
            </div>

            <div className="video">
              <ReactPlayer
                controls={true}
                className="react-player"
                width="640"
                height="360"
                onEnded={handleOnEnded}
                url={video.previewVideoUrl}
                playing
              />
            </div>

            <div className="video-footer w-100 border-bottom">
              {showFeedback ? (
                <div className="video-rating col-12 col-md-8 order-2 order-sm-1">
                  <div>
                    <Translate>ratethislesson</Translate>&nbsp;
                  </div>
                  <div className="rating rating2">
                    <div
                      title="Give 5 stars"
                      className={feedback.score === 5 ? "selected" : ""}
                      onClick={() => updateScore(5)}
                    >
                      <i className="material-icons">star</i>
                    </div>
                    <div
                      title="Give 4 stars"
                      className={feedback.score >= 4 ? "selected" : ""}
                      onClick={() => updateScore(4)}
                    >
                      <i className="material-icons">star</i>
                    </div>
                    <div
                      title="Give 3 stars"
                      className={feedback.score >= 3 ? "selected" : ""}
                      onClick={() => updateScore(3)}
                    >
                      <i className="material-icons">star</i>
                    </div>
                    <div
                      title="Give 2 stars"
                      className={feedback.score >= 2 ? "selected" : ""}
                      onClick={() => updateScore(2)}
                    >
                      <i className="material-icons">star</i>
                    </div>
                    <div
                      title="Give 1 star"
                      className={feedback.score >= 1 ? "selected" : ""}
                      onClick={() => updateScore(1)}
                    >
                      <i className="material-icons">star</i>
                    </div>
                  </div>
                </div>
              ) : null}
              { showFeedback &&  <div className="col-12 col-md-4 order-1 order-sm-2">
                <div
                  onClick={() => navigate("/course/" + courseId+"#"+id)}
                  className="btn btn-success w-100 top-10"
                >
                  <span>
                    <Translate>gotocourse</Translate>
                  </span>
                </div>
              </div>}
            </div>
            <div className="bottom-section w-100">
              {showFeedback ? (
                <div className="lesson-comments">
                  <div className="comments-header">
                    <Translate>commentsoptional</Translate>
                  </div>
                  <textarea
                    ref={feedbackDescription}
                    value={feedback.description}
                    onChange={handleUpdateDescription}
                  />
                  <div className="comment-buttons">
                    <button
                      className="btn btn-flat-accent2"
                      onClick={handleFeedbackDismiss}
                    >
                      <span className="text-accent3">
                        <Translate>dismiss</Translate>
                      </span>
                    </button>
                    &nbsp;
                    <button
                      className="btn btn-secondary"
                      onClick={handleUploadFeedback}
                    >
                      <Translate>submit</Translate>
                    </button>
                  </div>
                </div>
              ) : null}
            </div>
          </>
        ) : (
          <LoadingComponent isList={false} count={0} />
        )}
      </div>
    </ELTComponentAuth>
  );
};

export default CourseVideoItem;
