import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams } from "react-router-dom";

/* ********* Actions ********* */
import * as actions from "../../data/Certificates/certificatesActions";

/* ********* Components ********* */
import Translate from "translate-components";
import ELTComponent from "../shared/ELTComponent";
import LoadingComponent from "../shared/Loading";

// @ts-ignore
import autenticityEs from "../../assets/images/autenticity-esp.png";
import authenticityEn from "../../assets/images/autenticity.png";
const CertificatesComponent = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const { uuid } = useParams();

  /* ********* Reducer Variables ********* */
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );
  const certificateData = useSelector(
    (state: RootStateOrAny) => state.certificate.data
  );
  const isLoading = useSelector(
    (state: RootStateOrAny) => state.application.isLoading
  );

  /* ********* Effects ********* */
  useEffect(() => {
    dispatch(actions.getCertificate(uuid));
  }, []);

  return (
    <ELTComponent>
      {isLoading == false ? (
        <>
          <div className="header-spacer" />
          <div className="container top-80 bottom-40 certificatesContainer">
            {certificateData != null ? (
              <div className="certificatesBorders padding-all-5 ">
                <div className="secBack line-lightgray">
                  <>
                    <div className="row justify-content-center">
                      <div className="col-11 col-md-8 text-center">
                        {lang == "es" ? (
                          <img
                            src={autenticityEs}
                            className="img-fluid top-40"
                          />
                        ) : (
                          <img
                            src={authenticityEn}
                            className="img-fluid top-40"
                          />
                        )}

                        <p className="top-10">
                          <Translate>Certificate_ReferenceNumber</Translate>{" "}
                          <span className="text-accent4">
                            {certificateData.id}
                          </span>
                        </p>
                        <hr />
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-10">
                        <div className="row sec-item">
                          <div className="col-12 text-center">
                            <div className="padding-all-20">
                              <p>
                                <Translate>
                                  Certificate_ValidatesCertificateGivenTo
                                </Translate>
                              </p>
                              <h1 className="bottom-0">
                                {certificateData.userName}
                              </h1>
                              <p className="bottom-20">
                                {" "}
                                {certificateData.email}
                              </p>
                              <p>
                                <Translate>
                                  Certificate_UponTheCompletionOf
                                </Translate>{" "}
                                <span className="text-accent4 italics">
                                  &#34;{certificateData.moduleName}&#34;
                                </span>
                                <br />
                                <Translate>Certificate_On</Translate>{" "}
                                <span className="text-accent4 italics">
                                  {certificateData.completionDate}
                                </span>
                              </p>
                              {certificateData.ExpireDate && (
                                <p className="top-30 exp-date">
                                  <Translate>
                                    Certificate_ExpirationDate
                                  </Translate>
                                  {certificateData.ExpireDate}
                                  {/* Jan 15, 2032 */}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="bottom-40"></div>
                      </div>
                    </div>
                  </>
                </div>
              </div>
            ) : (
              <div className="row justify-content-center  padding-all-5">
                <div className="col-11 col-md-8 text-center">
                  <h1 className="top--20">
                    <Translate>Certificate_NotVerified</Translate>
                  </h1>
                </div>
              </div>
            )}
          </div>
        </>
      ) : (
        <LoadingComponent />
      )}
    </ELTComponent>
  );
};

export default CertificatesComponent;
