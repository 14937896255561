import * as types from "../constants/actionTypes";
import CertificateServices from "../services/certificateServices";

export function getCertificate(puuid) {
  const CertificateServicesInstance = new CertificateServices();

  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    CertificateServicesInstance.getByUUID(puuid)
      .then((response) => {
        dispatch({
          type: types.AUTHENTICATION_CERTIFICATES_GET_BY_ID,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function downloadCertificate(user, courseId, lang) {
  const token = localStorage.getItem("token");
  console.log("**Download the Certificate, the token is: " + token);
  const CertificateServicesInstance = new CertificateServices();

  const fileName = user.firstName + user.lastName + ".pdf";
  return function(dispatch) {
    dispatch({
      type: types.AUTHENTICATION_CERTIFICATES_ON_CERTIFICATE_DOWNLOAD,
    });
    CertificateServicesInstance.gerateCertificate(
      user.userId,
      fileName,
      courseId,
      lang,
      token
    )
      .then((response) => {
        dispatch({
          type: types.AUTHENTICATION_CERTIFICATES_END_DOWNLOAD_CERTIFICATE,
          payload: response,
        });
      })
      .catch((error) => {
        console.log("the error: " + error);
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.AUTHENTICATION_CERTIFICATES_END_DOWNLOAD_CERTIFICATE,
          payload: false,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
