import * as types from "../constants/actionTypes";
import QuizServicesInstance from "../services/quizServices";

const quizServices = new QuizServicesInstance();

export function createQuiz(
  idCourse,
  idEmployee,
  lang,
  countQuestions,
  pageCount,
  pageNumber,
  showIsCorrectAnswer
) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    quizServices
      .createQuiz(
        idCourse,
        idEmployee,
        lang,
        countQuestions,
        pageCount,
        pageNumber,
        showIsCorrectAnswer
      )
      .then((response) => {
        dispatch({
          type: types.QUIZ_CREATE_QUIZ,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function saveAndcompleteQuizByQuizId(
  idInstituteQuizByEmployee,
  currentPage,
  answerQuestionsByQuiz
) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    quizServices
      .answerQuestionsByQuiz(
        idInstituteQuizByEmployee,
        currentPage,
        answerQuestionsByQuiz
      )
      .then((response) => {
        dispatch({
          type: types.QUIZ_ANSWER_QUESTIONS_BY_QUIZ,
          payload: response,
        });
        quizServices
          .completeQuizByQuizId(idInstituteQuizByEmployee)
          .then((r) => {
            dispatch({
              type: types.QUIZ_COMPLETE_QUIZ_BY_ID,
              payload: r,
            });
          });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
export function retrieveTimeRemaining(idInstituteQuizByEmployee) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    quizServices
      .retrieveTimeRemaining(idInstituteQuizByEmployee)
      .then((response) => {
        dispatch({
          type: types.QUIZ_CREATE_QUIZ,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function saveQuizQuestionsAnswer(
  idInstituteQuizByEmployee,
  currentPage,
  answerQuestionsByQuiz
) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    quizServices
      .answerQuestionsByQuiz(
        idInstituteQuizByEmployee,
        currentPage,
        answerQuestionsByQuiz
      )
      .then((response) => {
        dispatch({
          type: types.QUIZ_ANSWER_QUESTIONS_BY_QUIZ,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function answerQuestion(
  fkInstituteQuizQuestion,
  fkfkInstituteQuizAnswer
) {
  return function(dispatch) {
    dispatch({
      type: types.QUIZ_ANSWER_QUESTIONS,
      payload: { fkInstituteQuizQuestion, fkfkInstituteQuizAnswer },
    });
  };
}

export function completeQuizByQuizId(
  idInstituteQuizByEmployee,
  isBackButton = false
) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });

    quizServices
      .completeQuizByQuizId(idInstituteQuizByEmployee)
      .then((r) => {
        dispatch({
          type: types.QUIZ_COMPLETE_QUIZ_BY_ID,
          payload: { data: r.data, isBackButton: isBackButton },
        });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
    dispatch({ type: types.FLIGHT_ARRIVED });
  };
}

export function retrieveQuizByEmployeeByQuizId(
  idInstituteQuizByEmployee,
  pageCount,
  pageNumber,
  language,
  showIsCorrectAnswer
) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });

    quizServices
      .retrieveQuizByEmployeeByQuizId(
        idInstituteQuizByEmployee,
        pageCount,
        pageNumber,
        language,
        showIsCorrectAnswer
      )
      .then((response) => {
        dispatch({
          type: types.RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_FULFILLED,
          payload: { data: response.data },
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          // type: types.RETRIEVE_QUIZ_BY_EMPLOYEE_BY_QUIZ_ID_EJECTED,
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}
