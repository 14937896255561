import React from 'react';
//import PropTypes from 'prop-types';

const Offline = (/*props*/) => {

    const styles = {
        bodyStyle: {
            backgroundColor: "#EFEFEF",
            color: "#2E2F30",
            textAlign: "center",
            fontFamily: "roboto, sans-serif",
            margin: 0
        },
        dialog: {

        },

        h1: {
            fontSize: "1.5em",
            color: "#999",
            lineHeight: "1.5em",
            fontWeight:"400",
            width:"80%",
            margin: "0 auto",
            marginTop: "20px",
        },
        h2:{
            color: "#646464",
            width:"80%",
            margin: "0 auto",
        },
        p: {
            color: "#666",
            display: "inline-block",
            padding: "10px",
            margin: "0",
            width:"80%"
        },
        rbTopNav: {
            background: "#1499D3",
            width: "100%",
            height: "auto",
            boxShadow: " 0 1px 2px rgba(0, 0, 0, 0.24)",
            textAlign: "center",
            padding:"10px 0"
        },
        rbTopNavLogo: {
            height:"30px",
            width: "auto",
        },
        offlineImg: {
            width: "100%",
            height: "auto",
        },
        retryContent: {
            width: "100%",
            padding: "10px 0",
            position: "fixed",
            bottom: "0",
            textAlign: "center",
            left: "0",
            borderTop: "1px solid #ccc",
            background: "#cccccc",
        },
        roundedBlueBtn: {
            display: "inline-block",
            background: "#1499D3",
            padding: "10px 30px",
            marginLeft: "10px 20px",
            border: "0",
            boxShadow: "0",
            color: "#fff",
            cursor: "pointer",
        },
    };

    return (
        <div style={styles.bodyStyle}>
            <div style={styles.dialog}>
                <div style={styles.dialogInner}>
                    <div style={styles.rbTopNav}>
                        <img style={styles.rbTopNavLogo} src="" alt=""/>
                    </div>
                    <h1 style={styles.h1}>Uh oh!</h1>
                    <h2 style={styles.h2}>It looks like you have lost your Internet connection</h2>
                    <img style={styles.offlineImg} src="../assets/images/offline.svg" alt="" />
                    <div style={styles.retryContent}>
                    <p style={styles.p}>Yes! Our Kids Can needs internet to work</p>
                    <button style={styles.roundedBlueBtn}>Retry</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offline;
