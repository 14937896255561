import * as types from "../constants/actionTypes";

const initialState = {
  error: null,

  passwordValidation: null,
  onValidation: true,
  error: null,
  passwordUpdated: false,
};

export default function readingReducer(state = initialState, action) {
  switch (action.type) {
    case types.RESET_PASSWORD_VALIDATDION: {
      const { data } = action.payload;

      return {
        ...state,
        onValidation: false,
        passwordValidation: data,
      };
    }
    case types.RESET_PASSWORD_VALIDATDION_ERROR: {
      return {
        ...state,
        onValidation: false,
        passwordValidation: null,
        error: true,
      };
    }
    case types.RESET_PASSWORD_UPDATE: {
      return {
        ...state,
        onValidation: false,
        passwordValidation: null,
        error: false,
        passwordUpdated: true,
      };
    }

    case types.RESET_PASSWORD_UPDATE_ERROR: {
      return {
        ...state,
        onValidation: false,
        passwordValidation: null,
        error: true,
        passwordUpdated: false,
      };
    }

    default:
      return state;
  }
}
