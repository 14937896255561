import React from "react";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import ELTComponent from "../shared/ELTComponent";
import Translate from "translate-components";

import ConfettiExplosion from "react-confetti-explosion";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const PaymentResult = () => {
  let query = useQuery();
  const success = query.get("success");
  const canceled = query.get("canceled");
  const code = query.get("code");
  const message = query.get("message");

  const [paymentCode, setPaymentCode] = useState("");

  useEffect(() => {
    if (code) {
      switch (code) {
        case "NO_ORIGIN_DOMAIN":
          setPaymentCode("subscription.plan.error.noOriginDomain");
          break;
        case "NO_SUBSCRIPTION_PLAN_FOUND":
          setPaymentCode("subscription.plan.error.noSubscriptionPlanFound");
          break;
        case "NO_STRIPE_PRICE_ID_OR_PRODUCT_ID":
          setPaymentCode("subscription.plan.error.noStripePriceIdOrProductId");
          break;
        case "SESSION_CREATE_ERROR":
          setPaymentCode("subscription.plan.error.sessionCreateError");
          break;
        case "UNHANDLED_EXCEPTION":
          setPaymentCode("subscription.plan.error.unhandledException");
          break;
        default:
          setPaymentCode("");
      }
    }
  }, [code]);

  const renderSuccess = () => {
    return (
      <ELTComponent>
        <ConfettiExplosion
          force={0.8}
          duration={3000}
          particleCount={250}
          width={1600}
        />
        <div className="top-100 padding-all-60 min-height-700">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6 col-xl-4">
              <div className="card p-4">
                <div className="text-center mb-4 text-success">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="width-60 height-60">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                  </svg>
                </div>


                <h1 className="text-center text-success">
                  <Translate>subscription.plan.success.header</Translate>
                </h1>
                <p className="text-center">
                  <Translate>subscription.plan.success.message</Translate>
                </p>
                <hr />
                <h3 className="text-center">
                  <Translate>subscription.plan.success.details</Translate>
                </h3>
                <hr />
                <p className="text-center">
                  <Translate>subscription.plan.success.contact</Translate>
                  <a href="mailto:support@eleadertech.com">
                    support@eleadertech.com
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </ELTComponent>
    );
  };

  const renderCanceled = () => {
    return (
      <ELTComponent>
        <div className="top-100 padding-all-60 min-height-700">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6 col-xl-4">
              <div className="card p-4">
                <div className="text-center mb-4 text-warning">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="width-60 height-60">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>

                <h1 className="text-center text-warning">
                  <Translate>subscription.plan.canceled.header</Translate>
                </h1>  
                <hr />
                <h2 className="text-center">
                  <Translate>subscription.plan.canceled.message</Translate>
                </h2>
                <hr />
                <h6 className="text-center">
                  <Translate>subscription.plan.success.contact</Translate>
                  <a href="mailto:support@eleadertech.com">
                    support@eleadertech.com
                  </a>
                </h6>
              </div>


            </div>
          </div>
        </div>
      </ELTComponent>
    );
  };

  const renderError = () => {
    return (
      <ELTComponent>
        <div className="top-100 padding-all-60 min-height-700">
          <div className="row justify-content-center">
            <div className="col-12 col-md-10 col-lg-6 col-xl-4">
              <div className="card p-4">
                <div className="text-center mb-4 text-warning">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="width-60 height-60">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>

                </div>
                <h2 className="text-center text-warning">
                  <Translate>subscription.plan.error.header</Translate>
                </h2>
                <hr />
                <h5 className="text-center text-primary m-0">
                  <Translate>subscription.plan.error.message</Translate>
                </h5>
                <p className="text-center text-primary m-0">
                  <Translate>{paymentCode}</Translate>
                </p>
                <hr />
                {message && message !== "" ? (
                  <p className="text-center text-primary m-0">
                    <Translate>{message}</Translate>
                  </p>
                ) : null}
                <h6 className="text-center">
                  <Translate>subscription.plan.error.contact</Translate>
                  <a href="mailto:support@eleadertech.com">
                    support@eleadertech.com
                  </a>
                </h6>

              </div>
              
            </div>
          </div>
        </div>
      </ELTComponent>
    );
  };

  if (success && success === "true") {
    return renderSuccess();
  } else if (canceled && canceled === "true") {
    return renderCanceled();
  } else {
    return renderError();
  }
};

export default PaymentResult;
