import * as types from "../constants/actionTypes";
import ResetTokenInstance from "../services/resetTokenPasswordService";

const resetTokenServices = new ResetTokenInstance();

export function validateToken(token) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    resetTokenServices
      .validateResetPassword(token)
      .then((response) => {
        dispatch({
          type: types.RESET_PASSWORD_VALIDATDION,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.RESET_PASSWORD_VALIDATDION_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function updatePassword(token, model) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    resetTokenServices
      .updatePassword(token, model)
      .then((response) => {
        dispatch({
          type: types.RESET_PASSWORD_UPDATE,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.RESET_PASSWORD_UPDATE_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
