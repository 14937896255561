import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Translate from "translate-components";
import Form from "react-bootstrap/Form";
import { RootStateOrAny, useSelector } from "react-redux";

type CustomSubscriptionProps = {
  cart: Array<any>;
};

const CustomSubscriptionPlan = ({ cart }: CustomSubscriptionProps) => {
  const productPrices = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.productPrices
  );

  const calculatePrices = (product) => {
    return resolvePrice(product).toFixed(2);
  };

  const resolvePrice = (product) => {
    if (product.price === 0) {
      return 0;
    }

    if (productPrices != null) {
      const price = productPrices.find(
        (pp) =>
          pp.fkIdProduct == product.fkSubscriptionProduct &&
          ((pp.fromQuantity <= product.quantity &&
            product.quantity <= (pp.toQuantity as number)) ||
            (product.quantity >= pp.fromQuantity && pp.toQuantity == null))
      );

      if (price != null) {
        return (
          (parseInt(product.quantity) * parseInt(price.unitCostInCents)) / 100
        );
      } else {
        return (parseInt(product.quantity) * parseInt(product.price)) / 100;
      }
    }

    return (parseInt(product.quantity) * parseInt(product.price)) / 100;
  };

  return (
    <div className="card width-100p padding-all-10 order-summary bottom-10">
      <h4 className="bottom-0">
        <Translate>subscription.plan.order.Review.items</Translate>
      </h4>
      <hr className="top-0" />
      <div className="row">
        <div className="col-9">
          <h6 className="bottom-0">
            <Translate>subscription.plan.order.summary.items</Translate>
          </h6>
        </div>
        <div className="col-3 text-right">
          <h6 className="bottom-0">
            <Translate>subscription.plan.order.price</Translate>
          </h6>
        </div>
      </div>
      <hr className="top-0" />
      {cart.map((product) => {
        return (
          <div className="row" key={product.productId}>
            <div className="col-9">{product.name}</div>
            <div className="col-3 text-right">${calculatePrices(product)}</div>
            <div className="col-12 ">
              {product.price === 0 ? (
                <Translate>subscription.plan.custom.plan.free</Translate>
              ) : (
                <>
                  <Translate>subscription.plan.custom.plan.charge</Translate>
                  <b>${calculatePrices(product)}</b>
                  {/* active */}
                  {product.fkSubscriptionStatus === 1 ? (
                    <>
                      <Translate>
                        subscription.plan.custom.plan.one.every
                      </Translate>
                      <b>{product.cycle}</b>
                      {product.recurrence === "M" ? (
                        <Translate>
                          subscription.plan.custom.plan.month
                        </Translate>
                      ) : null}
                      {product.recurrence === "Y" ? (
                        <Translate>
                          subscription.plan.custom.plan.year
                        </Translate>
                      ) : null}
                      {product.cycle != 1 ? (
                        <>
                          {product.recurrence === "M" ? (
                            <Translate>
                              subscription.plan.custom.plan.months
                            </Translate>
                          ) : null}
                          {product.recurrence === "Y" ? (
                            <Translate>
                              subscription.plan.custom.plan.years
                            </Translate>
                          ) : null}
                        </>
                      ) : null}
                      {product.daysOfTrial != null &&
                      product.daysOfTrial > 0 ? (
                        <>
                          <Translate>
                            subscription.plan.custom.plan.trial.after
                          </Translate>
                          <b>{product.daysOfTrial}</b>
                          <Translate>
                            subscription.plan.custom.plan.trial.days
                          </Translate>
                        </>
                      ) : null}
                    </>
                  ) : null}
                  {/* trial */}
                  {product.fkSubscriptionStatus === 4 ? (
                    <>
                      <Translate>subscription.plan.custom.plan.free</Translate>
                    </>
                  ) : null}
                  {/* Lifetime */}
                  {product.fkSubscriptionStatus === 5 ? (
                    <>
                      <Translate>
                        subscription.plan.custom.plan.one.time
                      </Translate>
                    </>
                  ) : null}
                  {/* Installments */}
                  {product.fkSubscriptionStatus === 6 ? (
                    <>
                      <Translate>
                        subscription.plan.custom.plan.one.every
                      </Translate>
                      <b>{product.cycle}</b>
                      {product.recurrence === "M" ? (
                        <Translate>
                          subscription.plan.custom.plan.month
                        </Translate>
                      ) : null}
                      {product.recurrence === "Y" ? (
                        <Translate>
                          subscription.plan.custom.plan.year
                        </Translate>
                      ) : null}
                      {product.cycle != 1 ? (
                        <>
                          {product.recurrence === "M" ? (
                            <Translate>
                              subscription.plan.custom.plan.months
                            </Translate>
                          ) : null}
                          {product.recurrence === "Y" ? (
                            <Translate>
                              subscription.plan.custom.plan.years
                            </Translate>
                          ) : null}
                        </>
                      ) : null}
                      <br />
                      <Translate>subscription.plan.custom.plan.until</Translate>
                      <b>{product.stopCycleNumber}</b>
                      {product.recurrence === "M" ? (
                        <Translate>
                          subscription.plan.custom.plan.month
                        </Translate>
                      ) : null}
                      {product.recurrence === "Y" ? (
                        <Translate>
                          subscription.plan.custom.plan.year
                        </Translate>
                      ) : null}
                      {product.stopCycleNumber != 1 ? (
                        <>
                          {product.recurrence === "M" ? (
                            <Translate>
                              subscription.plan.custom.plan.months
                            </Translate>
                          ) : null}
                          {product.recurrence === "Y" ? (
                            <Translate>
                              subscription.plan.custom.plan.years
                            </Translate>
                          ) : null}
                        </>
                      ) : null}
                    </>
                  ) : null}
                </>
              )}
            </div>
            <div className="col-12 hidden">
              <div className="d-flex justify-start align-items-baseline">
                <div>Quantity:&nbsp;</div>

                <Form.Group>
                  <Form.Control as="select">
                    <option>1</option>
                    <option>5</option>
                    <option>10</option>
                    <option>50</option>
                    <option>100</option>
                  </Form.Control>
                </Form.Group>
              </div>
              <hr />
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default CustomSubscriptionPlan;
