import * as types from "../constants/actionTypes";
import AuthenticationServicesInstance from "../services/authenticationServices";
import WishlistServices from "../services/wishlistServices";

const authenticationServices = new AuthenticationServicesInstance();
const wishlistServices = new WishlistServices();

export function getLisUserCourses(userId, lang) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getLisUserCourses(userId, token, lang, 0)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_LIST_USER_COURSES_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_GET_LIST_USER_COURSES_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserModule(idModule, idEmployee, lang, courseType) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getUserModule(idModule, idEmployee, token, lang, courseType)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_MODULE_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });

        dispatch({
          type: types.DASHBOARD_GET_MODULE_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserModuleCourse(idCourse, idEmployee, lang) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getUserCourse(idCourse, idEmployee, token, lang)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_MODULE_COURSE_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_GET_MODULE_COURSE_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function updateFeedbackScore(score) {
  return function(dispatch) {
    dispatch({
      type: types.DASHBOARD_UPDATE_FEEDBACK_SCORE,
      payload: score,
    });
  };
}

export function updateFeedbackDescription(description) {
  return function(dispatch) {
    dispatch({
      type: types.DASHBOARD_UPDATE_FEEDBACK_DESCRIPTION,
      payload: description,
    });
  };
}

export function uploadFeedback(feedback) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .addFeedback(feedback, token)
      .then((response) => {
        dispatch({ type: types.DASHBOARD_ADD_FEEDBACK, payload: response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_ADD_FEEDBACK_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function dismissFeedback() {
  return function(dispatch) {
    dispatch({ type: types.DASHBOARD_DISMISS_FEEDBACK });
  };
}

export function getUserNotJoinedCourses(idEmployee, lang) {
  const token = localStorage.getItem("token");
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .getUserNotJoinedCourses(idEmployee, lang, token)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_USER_NOT_JOINED_COURSES_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({
          type: types.DASHBOARD_GET_USER_NOT_JOINED_COURSES_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function getUserWishlist(idEmployee) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    wishlistServices
      .getByEmployeeId(idEmployee)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_GET_USER_WISHLIST_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        //Check The Payment
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function addToWishlist(idEmployee, module) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    wishlistServices
      .addToWishlist(idEmployee, module)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_ADD_USER_WISHLIST_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function removeToWishlist(idEmployee, module) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    wishlistServices
      .removeToWishlist(idEmployee, module)
      .then((response) => {
        dispatch({
          type: types.DASHBOARD_REMOVE_USER_WISHLIST_FULFILLED,
          payload: response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.AUTH_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
