import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Translate from "translate-components";

type MaterialItemProps={
  item:any,
  version?:number,
}


type MaterialCardProps={
  name:any,
  items:[any],
  module?:any,
  version?:number,
}

const MaterialItem = ({ item, version }:MaterialItemProps) => (
  <a
    key={item.key}
    href={
      typeof version != "undefined"
        ? item.link.replace("{{version}}", version > 1 ? "_v" + version : "")
        : item.link
    }
    // target="_blank"
    rel="noopener noreferrer"
    className="tab-link"
  >
    <Translate>{item.name}</Translate>
  </a>
);
const CourseMaterialsCards = ({ name, items, version, module }:MaterialCardProps) => {
  return (
    <div className="tab-card">
      <div className="title">{name}</div>

      {items != null &&
        items.map((item) =>
          item.constructor === Array
            ? item.map(
                (subItem) =>
                  subItem.availableModule == module && (
                    <MaterialItem
                      key={subItem.key}
                      item={subItem}
                      version={version}
                    />
                  )
              )
            : item.availableModule == module && (
                <MaterialItem key={item.key} item={item} version={version} />
              )
        )}
    </div>
  );
};


export default CourseMaterialsCards;
