import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

import Translate from "translate-components";
import ReCAPTCHA from "react-google-recaptcha";
import VideoPlayer from "../course-video-player-components/VideoPlayer";

/* ********* Actions ********* */
import * as actions from "../../data/Landing/landingActions";
interface ModalProps {
  selectedProduct: any;
  softwareToRequest: any;
  closeModal: () => void;
}

const RequestModalComponent = (props: ModalProps) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();

  const [captchaToken, setCaptchaToken] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [message, setMessage] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const validateForm = () => {
    if (
      captchaToken != null &&
      captchaToken != "" &&
      firstName.length > 3 &&
      lastName.length > 3
    )
      return true;
    return false;
  };
  const handleRequestDemo = () => {
    var emailForm = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      country: country,
      phoneNumber: phoneNumber,
      message: message,
      softwaretoRequest: "" + props.softwareToRequest.internalName,
      captchaToken: "" + captchaToken,
    };
    if (validateForm()) {
      dispatch(
        actions.sendRequestDemoEmail(emailForm, () => props.closeModal())
      );
    }
  };
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={true}
      onHide={props.closeModal}
    >
      <Modal.Body className="padding-all-0"></Modal.Body>
      <Modal.Body>
        <div className="container">
          <VideoPlayer
            videoSource={props.selectedProduct?.video}
            mustCompleteCourse={false}
          ></VideoPlayer>
          <>
            <div className="row Productlicense">
              <div className="col-12">
                <h1 className="dash smaller">
                  <Translate>RequestModal.RequestADemo</Translate>
                </h1>
              </div>
            </div>
            <div className="row Productlicense">
              <div className="col-12 col-md-6">
                <label className="mt-2 width-100p">
                  <Translate>firstName</Translate>
                  {firstName.length <= 3 && (
                    <span className="mx-2 mt-2 width-100p text-warning">
                      <Translate>firstNameRequired</Translate>
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="firstName"
                  className="mb-2 width-100p"
                  value={firstName}
                  onChange={(v) => setFirstName(v.target.value)}
                ></input>
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2 width-100p">
                  <Translate>lastName</Translate>

                  {lastName.length <= 3 && (
                    <span className="mx-2 mt-2 width-100p text-warning">
                      <Translate>lastNameRequired</Translate>
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="lastName"
                  className="mb-2 width-100p"
                  value={lastName}
                  onChange={(v) => setLastName(v.target.value)}
                ></input>
              </div>
            </div>
            <div className="row Productlicense">
              <div className="col-12 col-md-6">
                <label className="mt-2 width-100p">
                  <Translate>phoneNumber</Translate>
                </label>
                <input
                  type="text"
                  name="phoneNumber"
                  className="mb-2 width-100p"
                  value={phoneNumber}
                  onChange={(v) => setPhoneNumber(v.target.value)}
                ></input>
              </div>
              <div className="col-12 col-md-6">
                <label className="mt-2 width-100p">
                  <Translate>Country</Translate>
                </label>
                <input
                  type="text"
                  name="country"
                  className="mb-2 width-100p"
                  value={country}
                  onChange={(v) => setCountry(v.target.value)}
                ></input>
              </div>
            </div>
            <div className="row Productlicense">
              <div className="col-12">
                <label className="mt-2 width-100p">
                  <Translate>email</Translate>

                  {email.length <= 3 && (
                    <span className="mx-2 mt-2 width-100p text-warning">
                      <Translate>emailRequired</Translate>
                    </span>
                  )}
                </label>
                <input
                  type="text"
                  name="email"
                  className="mb-2 width-100p"
                  value={email}
                  onChange={(v) => setEmail(v.target.value)}
                ></input>
                <label className="mt-2 width-100p">
                  <Translate>message</Translate>
                </label>
                <textarea
                  name="message"
                  className="mb-2 width-100p line-secondary"
                  value={message}
                  onChange={(v) => setMessage(v.target.value)}
                ></textarea>
              </div>
            </div>
            <div className="row Productlicense ">
              <div className="col-12">
                <ReCAPTCHA
                  sitekey={process.env.REACT_APP_PUBLIC_RECAPTCHA_KEY}
                  onChange={(value) => {
                    setCaptchaToken(value);
                  }}
                  onExpired={() => setCaptchaToken(null)}
                />
              </div>
            </div>
          </>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 d-flex ">
          <a
            className={
              validateForm()
                ? "btn btn-flat-success px-4"
                : "btn btn-flat-lightgray px-4"
            }
            onClick={handleRequestDemo}
          >
            <Translate>Send</Translate>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              className="width-25 height-25 mx-2"
            >
              <path d="M3.105 2.289a.75.75 0 00-.826.95l1.414 4.925A1.5 1.5 0 005.135 9.25h6.115a.75.75 0 010 1.5H5.135a1.5 1.5 0 00-1.442 1.086l-1.414 4.926a.75.75 0 00.826.95 28.896 28.896 0 0015.293-7.154.75.75 0 000-1.115A28.897 28.897 0 003.105 2.289z" />
            </svg>
          </a>
          <a
            className="btn btn-flat-papergray text-warning mx-2"
            onClick={props.closeModal}
          >
            Close
          </a>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default RequestModalComponent;
