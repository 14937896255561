import React from "react";
import Modal from "react-bootstrap/Modal";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import VideoPlayer from "../course-video-player-components/VideoPlayer";
import Translate from "translate-components";
import { encodeNumber } from "../../utils/strings";

/* ********* Actions ********* */
import * as actions from "../../data/Dashboard/dashBoardActions";
interface ModalProps {
  selectedModule: any;
  closeModal: () => void;
}

const LandingModuleModalComponent = (props: ModalProps) => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const userId = userSession?.userId;
  const buyUrl =
    props.selectedModule == null
      ? ""
      : props.selectedModule.buyInformation != null &&
        props.selectedModule.buyInformation.length > 0
      ? props.selectedModule.buyInformation[0].buyUrl
      : "";
  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.selectedModule != null}
      onHide={props.closeModal}
    >
      <Modal.Body className="padding-all-0">
        <div>
          <button
            type="button"
            className="btn-close"
            onClick={props.closeModal}
          ></button>
          <VideoPlayer
            videoSource={props.selectedModule?.previewVideoUrl}
            mustCompleteCourse={false}
          ></VideoPlayer>
        </div>
      </Modal.Body>
      <Modal.Body>
        <h2>{props.selectedModule?.name}</h2>
        <p>{props.selectedModule?.description}</p>
      </Modal.Body>

      <Modal.Footer>
        <div className="w-100 d-flex justify-content-between">
          <a
            className="btn btn-flat-papergray text-warning"
            onClick={props.closeModal}
          >
            Close
          </a>
          {userSession &&
            userSession.isLoggedIn() &&
            props.selectedModule != null &&
            !props.selectedModule.isInWishlist &&
            !props.selectedModule.isEnrolled && (
              <a
                onClick={() => {
                  dispatch(
                    actions.addToWishlist(userId, props.selectedModule.id)
                  );
                }}
                className="btn btn-flat-papergray text-secondary"
              >
                <Translate>Dashboard_Add_Wishlist</Translate>
              </a>
            )}
          {userSession &&
            userSession.isLoggedIn() &&
            props.selectedModule != null &&
            !props.selectedModule.isEnrolled && (
              <a
                href={"/course/" + encodeNumber(props.selectedModule.id)}
                className="btn btn-flat-papergray text-secondary"
              >
                <Translate>GoToCourse</Translate>
              </a>
            )}
          {props.selectedModule != null && !props.selectedModule.isEnrolled && (
            <a href={buyUrl} className="btn btn-flat-papergray text-success">
              <Translate>Dashboard_Buy_Course</Translate>
            </a>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default LandingModuleModalComponent;
