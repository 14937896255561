import React, { useState } from "react";
import AnswerComponent from "./Answer";
import Translate from "translate-components";
// @ts-ignore  
import iconHint from "../../assets/icons/hint.png"
const QuestionComponent = ({
  /* eslint-disable no-unused-vars */
  id,
  fkInstituteQuizByEmployee,
  fkInstituteQuizQuestion,
  instituteQuizQuestion,
  isCorrectAnswer,
  isFlagged,
  count,
  disabled
  /* eslint-enable no-unused-vars */
}) => {
  const [showHints, setShowHints] = useState(false);
  const answersToRender = instituteQuizQuestion.instituteQuizAnswer.map(
    (a, i) => {
      return (
        <AnswerComponent
          disabled={disabled}
          idInstituteQuestionByQuiz={id}
          key={i}
          index={i}
          {...a}
        ></AnswerComponent>
      );
    }
  );
  return (
    <div className="row">
      <div className="col-12">
        <div className="card top-20">
          <div
            className="hintsIconButton"
            onClick={() => setShowHints(!showHints)}
          >
            <img src={iconHint}></img>
            <Translate>question.hint</Translate>
          </div>
          <div className="question">
            {count + ". " + instituteQuizQuestion.description}
          </div>
          <div className="row">{answersToRender}</div>
          {showHints && (
            <div className="row">
              <div className="hintRow">
                <img src={iconHint}></img>
                {instituteQuizQuestion.hints}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionComponent;
