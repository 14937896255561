import React from "react";
import ReactPlayer from "react-player";

type AudioPlayerProps={
  mustCompleteCourse:boolean,
  completeCourse?:() => void,
  videoSource:string
}

const AudioPlayer = ({mustCompleteCourse,completeCourse,videoSource}:AudioPlayerProps) => {
  return (
    <>
      <div className="video">
        <ReactPlayer
          controls={true}
          className="react-player"
          width="640"
          height="360"
          onEnded={mustCompleteCourse ? completeCourse : undefined}
          url={videoSource}
          playing
        />
      </div>
    </>
  );
};

export default AudioPlayer;
