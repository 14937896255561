import axios from "axios";
import moment from "moment";
//import querystring from 'querystring';

class QuizServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  createQuiz(
    idCourse,
    idEmployee,
    lang,
    countQuestions,
    pageCount,
    pageNumber,
    showIsCorrectAnswer
  ) {
    const theUrl = `/InstituteQuiz/CreateQuiz/${idCourse}/${idEmployee}/${lang}/${countQuestions}/${pageCount}/${pageNumber}/${showIsCorrectAnswer}`;
    return this.axiosClient
      .post(`${theUrl}`, { InitDate: moment().utc() })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  retrieveTimeRemaining(idInstituteQuizByEmployee) {
    const theUrl = `/InstituteQuiz/RetrieveTimeRemaining/${idInstituteQuizByEmployee}`;
    return this.axiosClient.post(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  retrieveQuizByEmployeeByQuizId(
    idInstituteQuizByEmployee,
    pageCount,
    pageNumber,
    lang,
    showIsCorrectAnswer
  ) {
    const theUrl = `/InstituteQuiz/RetrieveQuizByEmployeeByQuizId/${idInstituteQuizByEmployee}/${pageCount}/${pageNumber}/${lang}/${showIsCorrectAnswer}`;
    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  completeQuizByQuizId(idInstituteQuizByEmployee) {
    const theUrl = `/InstituteQuiz/CompleteQuizByQuizId/${idInstituteQuizByEmployee}`;
    return this.axiosClient.put(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  answerQuestionsByQuiz(
    idInstituteQuizByEmployee,
    currentPage,
    answerQuestionByQuiz
  ) {
    const theUrl = `/InstituteQuiz/AnswerQuestionsByQuiz/${idInstituteQuizByEmployee}/${currentPage}`;
    return this.axiosClient
      .put(`${theUrl}`, answerQuestionByQuiz)
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}

export default QuizServices;
