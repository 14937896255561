import * as types from "../constants/actionTypes";
import userSessionExtension from "./userSessionExtension";
import jwt_decode from "jwt-decode";

const initialState = {
  error: null,

  userId: localStorage.getItem("uid"),
  email: localStorage.getItem("email"),
  username: localStorage.getItem("username"),
  firstName: localStorage.getItem("firstName"),
  lastName: localStorage.getItem("lastName"),
  imagePath: localStorage.getItem("imagePath"),
  curriculumVersion: localStorage.getItem("curriculumVersion"),
  securityProfiles:
    localStorage.getItem("securityProfiles") != null
      ? Array.from(localStorage.getItem("securityProfiles"))
      : [],
  availableCurriculumns:
    localStorage.getItem("availableCurriculumns") != null
      ? Array.from(localStorage.getItem("availableCurriculumns"))
      : [],
  userHasLogged:
    localStorage.getItem("userHasLogged") === null
      ? null
      : Boolean(localStorage.getItem("userHasLogged")),
  hasLicenses:
    localStorage.getItem("hasLicenses") === null
      ? false
      : Boolean(localStorage.getItem("hasLicenses")),
  token: localStorage.getItem("token"),
  casRefreshToken: localStorage.getItem("casRefreshToken"),
  expire:
    localStorage.getItem("expire") === null
      ? null
      : parseInt(localStorage.getItem("expire")),
  uid:
    localStorage.getItem("uid") === null
      ? null
      : parseInt(localStorage.getItem("uid")),
  passwordUpdated: false,
  isLoggedIn: () =>
    userSessionExtension.isLoggedIn(
      localStorage.getItem("token"),
      localStorage.getItem("expire")
    ),
  currentEnvironment: process.env.REACT_APP_ENVIRONMENT,
};

export default function userSessionReducer(state = initialState, action) {
  let errorMessage = "You've found an unkown glitch! Please contact support.";

  switch (action.type) {
    case types.RESET_PASSWORD_REJECTED:
    case types.COMPLETE_ON_BOARDING_PROCESS_REJECTED:
    case types.AUTH_ERROR: {
      // remove standard error message in auth error
      errorMessage = "";
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        token: "",
        expire: 0,
        error: errorMessage,
      };
    }

    case types.AUTHENTICATE: {
      let authResponse = action.payload.data;
      let casRefreshToken = action.payload.casToken;

      // cas refresh token is saved to allow connection to ExpressCoach
      if (casRefreshToken) {
        localStorage.setItem("casRefreshToken", casRefreshToken);
      }

      let claims = jwt_decode(authResponse.token);
      let isLoggedInUpdated = userSessionExtension.isLoggedIn(
        authResponse.token,
        claims.exp
      );

      if (isLoggedInUpdated) {
        userSessionExtension.saveClaims(authResponse);

        return {
          ...state,
          userId: authResponse.id,
          error: null,
          token: authResponse.token,
          casRefreshToken: casRefreshToken,
          email: authResponse.email,
          username: authResponse.username,
          firstName: authResponse.firstName,
          lastName: authResponse.lastName,
          imagePath: authResponse.imagePath,
          curriculumVersion: authResponse.curriculumVersion,
          availableCurriculumns: authResponse.availableCurriculumns,
          securityProfiles:
            authResponse.securityProfiles != null
              ? authResponse.securityProfiles
              : [],
          userHasLogged:
            authResponse.userHasLogged === null
              ? null
              : Boolean(authResponse.userHasLogged),
          hasLicenses:
            authResponse.hasLicenses === null
              ? false
              : Boolean(authResponse.hasLicenses),
          expire: parseInt(claims.exp),
          uid: parseInt(claims.uid),
        };
      } else {
        localStorage.clear();
        return { ...initialState };
      }
    }

    case types.RESET_PASSWORD_FULFILLED: {
      return {
        ...state,
        error:
          "Password reset request has been received. If this email address exists in our system, an email with the new password has been sent. Use the new information to login to this portal.",
      };
    }

    case types.COMPLETE_ON_BOARDING_PROCESS_FULFILLED:
    case types.UPDATE_PASSWORD_FULFILLED: {
      return {
        ...state,
        userHasLogged:
          action.payload.data === null ? null : Boolean(action.payload.data),
        passwordUpdated: true,
      };
    }

    case types.SIGNOUT: {
      localStorage.clear();
      window.location = "/";
      return { ...initialState };
    }
    default:
      return state;
  }
}
