const GetPriceByModuleOrGroup = (itemId, isModule) => {
  if (isModule) {
    switch (itemId) {
      case 40: // F&I Bootcamp
        return "395";
      case 20: // Pivot to Positive Brain Model
        return "49";
      case 45: // Live Ready - 10 Principles of Success
        return "175";
      case 19: // Beyond Think and Grow Rich - Deep Dive
        return "499";
      case 22: // Stop Drifting eBook
        return "8.99";
      case 23: // The Power of Thought Movie
        return "4.99";
      default:
        return "";
    }
  } else {
    switch (itemId) {
      case 1: // All Leadership Boot Camp
        return "";
      default:
        return "";
    }
  }

  return "";
};

export default GetPriceByModuleOrGroup;
