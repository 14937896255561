import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import parse from "html-react-parser";
import Translate from "translate-components";
import * as actions from "../../data/SubscriptionPlan/subscriptionPlanActions";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";

const OrderSummary = (props) => {
  const productPrices = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.productPrices
  );

  const isSubmittingOrder = useSelector(
    (state: RootStateOrAny) => state.subscriptionPlan.submittingOrder
  );

  const dispatch = useDispatch();

  const calculatePrices = (product) => {
    return resolvePrice(product).toFixed(2);
  };

  const resolvePrice = (product) => {
    if (product.isFree) return 0;
    if (productPrices != null) {
      const price = productPrices.find(
        (pp) =>
          pp.fkIdProduct == product.fkSubscriptionProduct &&
          ((pp.fromQuantity <= product.quantity &&
            product.quantity <= (pp.toQuantity as number)) ||
            (product.quantity >= pp.fromQuantity && pp.toQuantity == null))
      );

      if (price != null) {
        return (
          (parseInt(product.quantity) * parseInt(price.unitCostInCents)) / 100
        );
      } else {
        return (parseInt(product.quantity) * parseInt(product.price)) / 100;
      }
    }

    return (parseInt(product.quantity) * parseInt(product.price)) / 100;
  };

  const handleChange = (
    e: {
      persist: () => void;
      target: { id: string; value: string; select: () => void };
    },
    productId
  ) => {
    e.persist();
    let quantity = parseInt(e.target.value ? e.target.value : "0");
    if (quantity <= 0) {
      quantity = 0;
    }
    dispatch(actions.updateCart(productId, quantity));
  };

  const handleDecrement = (productId, cty) => {
    let quantity = cty - 1;
    if (quantity <= 0) {
      quantity = 0;
    }
    dispatch(actions.updateCart(productId, quantity));
  };

  const handleIncrement = (productId, cty) => {
    const quantity = cty + 1;
    dispatch(actions.updateCart(productId, quantity));
  };

  const submitButtonText = () => {
    if (props.computeSubTotal() <= 0) {
      return <Translate>subscription.plan.review.order.zeroprice</Translate>;
    } else if (isSubmittingOrder === true) {
      return (
        <Translate>
          subscription.plan.review.order.submitting.your.order
        </Translate>
      );
    }

    return <Translate>subscription.plan.place.order</Translate>;
  };

  return (
    <div className="card width-100p padding-all-10 order-summary top-0">
      <h4 className="text-center bottom-0 text-success">
        <Translate>subscription.plan.order.summary.order.summary</Translate>
      </h4>
      <hr className="top-0" />

      <div className="row">
        <div className="col-5 font-weight-bold">
          <h6 className="font-weight-bold">
            <Translate>subscription.plan.order.summary.items</Translate>
          </h6>
        </div>

        <div className="col-4 text-center">
          <h6 className="font-weight-bold">
            <Translate>subscription.plan.order.summary.quantity</Translate>
          </h6>
        </div>

        <div className="col-3 text-right">
          <h6 className="font-weight-bold">
            <Translate>subscription.plan.order.summary.amount</Translate>
          </h6>
        </div>
      </div>

      <div>
        {props.cart && props.cart.length <= 0 ? (
          <Form.Text muted>
            <span className="text-warn">
              <Translate>subscription.plan.order.cart.empty</Translate>
            </span>
          </Form.Text>
        ) : null}

        {props.cart.map((product) => {
          return (
            <div className="row" key={product.productId}>
              <div className="col-5 d-flex" style={{ fontSize: "12px" }}>
                {product.description}
              </div>
              <div className="col-4 d-flex justify-content-center align-items-center">
                <button
                  data-action="decrement"
                  className="btn btn-sm btn-flat-lightgray height-40 width-40 text-main right-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleDecrement(product.productId, product.quantity);
                  }}
                >
                  <span className="m-auto text-xl font-thin">−</span>
                </button>

                <input
                  id={"quantity" + product.productId}
                  type="number"
                  className="text-center width-60"
                  name="custom-input-number"
                  value={product.quantity}
                  onChange={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleChange(e, product.productId);
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                ></input>

                <button
                  data-action="increment"
                  className="btn btn-sm btn-flat-lightgray height-40 width-40 text-main left-4"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleIncrement(product.productId, product.quantity);
                  }}
                >
                  <span className="m-auto text-xl font-thin">+</span>
                </button>
              </div>

              <div className="col-3 text-right" style={{ fontSize: "12px" }}>
                ${calculatePrices(product)}
              </div>

              <div className="col-12">
                <hr className="top-5 bottom-5" />
              </div>
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="col-8">
          <Translate>subscription.plan.order.subtotal</Translate> :
        </div>
        <div className="col-4 text-right ">
          ${(props.computeSubTotal() / 100).toFixed(2)}
        </div>
      </div>
      <div className="row">
        <div className="col-8">
          <Translate>subscription.plan.order.taxes</Translate> :
        </div>
        <div className="col-4 text-right ">
          $
          {!isNaN(props.taxAmountInCents)
            ? (props.taxAmountInCents / 100).toFixed(2)
            : (0).toFixed(2)}
        </div>
      </div>
      <hr className="top-0 bottom-0" />
      <div className="row">
        <div className="col-8 strong text-success">
          <Translate>subscription.plan.order.total</Translate>:
        </div>
        <div className="col-4 text-right strong text-success">
          ${props.computeTotal()}
        </div>
      </div>
      <div style={{ fontSize: "12px", color: "red" }}>
        {parse(props.errorsWhenSubmitting)}
        {props.salesTaxError}
      </div>
      <button
        type="submit"
        className="btn btn-success top-10"
        disabled={
          props.computeSubTotal() <= 0 ||
          isSubmittingOrder === true ||
          (props.requestDeparted === true && props.requestArrived === false)
        }
      >
        {submitButtonText()}
      </button>
    </div>
  );
};

OrderSummary.propTypes = {
  cart: PropTypes.array,
  computeSubTotal: PropTypes.func.isRequired,
  taxAmountInCents: PropTypes.number,
  shippingFeeInCents: PropTypes.number,
  computeTotal: PropTypes.func.isRequired,
  errorsWhenSubmitting: PropTypes.string,
  salesTaxError: PropTypes.string,
  requestDeparted: PropTypes.bool,
  requestArrived: PropTypes.bool,
  shippingMethod: PropTypes.string,
  setShippingMethod: PropTypes.func.isRequired,
  supportLargeOrders: PropTypes.bool,
  setSupportLargeOrders: PropTypes.func.isRequired,
  isDigitalProduct: PropTypes.bool.isRequired,
};

export default OrderSummary;
