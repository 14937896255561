import * as types from "../constants/actionTypes";

const initialState = {
  error: null,
  selectedCourse: null,
  selectedProduct: null,

  products: [],
  groups: [],
  coursesList: [],
  categories: [],
  moduleList: [],
  videos: [],
  areVideosLoading: true,
  areCoursesLoading: true,
  areModulesLoading: true,
  relatedCourses: [],
  areCategoriesLoading: true,
  areGroupsLoading: true,
  courseByCategories: [],
  softwareList: [],
};

export default function dashBoardReducer(state = initialState, action) {
  let errorMessage = "You've found an unkown glitch! Please contact support.";

  switch (action.type) {
    case types.LANDING_GET_FEATURED_COURSES_REJECTED: {
      if (action.payload === undefined) {
        errorMessage = "The post request is not reaching the server.";
      }

      if (
        action.payload !== undefined &&
        action.payload.data !== undefined &&
        action.payload.data.message !== undefined
      ) {
        errorMessage = action.payload.data.message;
      }

      return {
        ...state,
        coursesList: [],
        products: [],
        categories: [],
        error: errorMessage,
      };
    }

    case types.LANDING_GET_FEATURED_COURSES_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        coursesList: data.items,
        areCoursesLoading: false,
      };
    }

    case types.LANDING_GET_SOFTWARE_LIST_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        softwareList: data,
      };
    }

    case types.LANDING_GET_ALL_MODULES_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        moduleList: data.items,
        areModulesLoading: false,
      };
    }

    case types.LANDING_GET_ALL_PRODUCTS_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        products: data.items,
        areCategoriesLoading: false,
      };
    }

    case types.LANDING_GET_ALL_GROUPS_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        groups: data,
        areGroupsLoading: false,
      };
    }

    case types.LANDING_GET_ALL_GROUPS_REJECTED: {
      const { data } = action.payload;
      return {
        ...state,
        groups: [],
        areGroupsLoading: false,
      };
    }

    case types.LANDING_SET_GROUPS_LOADING: {
      return {
        ...state,
        areGroupsLoading: true,
      };
    }

    case types.LANDING_GET_COURSE_BY_ID_FULFILLED: {
      const { data } = action.payload;

      return {
        ...state,
        selectedCourse: data?.selectedModule,
      };
    }

    case types.LANDING_GET_COURSES_BY_CATEGORY_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        courseByCategories: data.items,
      };
    }

    case types.LANDING_GET_PRODUCT_BY_ID_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        selectedProduct: data,
      };
    }

    case types.LANDING_GET_VIDEOS_BY_MODULEID_FULFILLED: {
      const { data } = action.payload;
      return {
        ...state,
        videos: data,
        areVideosLoading: false,
      };
    }

    case types.LANDING_SET_COURSES_LOADING: {
      return {
        ...state,
        areVideosLoading: true,
        areCoursesLoading: true,
        areCategoriesLoading: true,
      };
    }
    case types.LANDING_SET_PRODUCTS_LOADING: {
      return {
        ...state,
        areVideosLoading: true,
        areCoursesLoading: true,
        areCategoriesLoading: true,
      };
    }
    case types.LANDING_SEND_REQUEST_DEMO_EMAIL_FULFILLED: {
      return {
        ...state,
        isRequestDemoSended: true,
      };
    }
    case types.LANDING_SEND_REQUEST_DEMO_EMAIL_FULFILLED: {
      return {
        ...state,
        isRequestDemoSended: true,
      };
    }
    case types.LANDING_SEND_REQUEST_DEMO_EMAIL_REJECTED: {
      return {
        ...state,
        isRequestDemoSended: true,
        errorMessage: action.payload,
      };
    }
    default:
      return state;
  }
}
