import React, { useEffect, useCallback, useState } from "react";
// import { Link } from "react-router-dom";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
// import ContentLoader from "react-content-loader";
/* ********* Actions ********* */
import * as landingActions from "../../data/Landing/landingActions";

/* ********* Components ********* */
import ELTComponent from "../shared/ELTComponent";
// import CourseListComponent from "./MainScreenCourseList";
// import CategoriesListComponent from "./MainScreenCategoriesList";
import Carousel from "react-bootstrap/Carousel";

//---------------------Images
import { materials } from "../../data/Static/MyMaterialsData";
import courseSplashImg from "../../assets/images/imgmancourse-2.png";

//---------------------Translation
import Translate from "translate-components";

// @ts-ignore
import LandingModuleModalComponent from "./LandingModuleModal";
import RequestDemoModal from "./RequestDemoModal";

import Group from "./Group";
import LoadingComponent from "../shared/Loading";

const MainScreenComponent = () => {
  /* ********* Uses ********* */
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(null);
  const [requestDemoProduct, setRequestDemoProduct] = useState<any>(null);
  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);

  const groups = useSelector((state: RootStateOrAny) => state.landing.groups);
  const areGroupsLoading = useSelector(
    (state: RootStateOrAny) => state.landing.areGroupsLoading
  );
  const lang = useSelector(
    (state: RootStateOrAny) => state.application.selectedLanguageCode
  );

  /* ********* Effects ********* */
  const fetchData = useCallback(() => {
    const employeeId = userSession != null ? userSession.userId : 0;
    //  dispatch(landingActions.getFeaturedCourses(lang));
    dispatch(landingActions.getAllProducts(lang));
    dispatch(landingActions.getAllGroups(lang, employeeId));
  }, [dispatch, lang]);

  useEffect(() => {
    fetchData();
  }, [fetchData, lang]);

  /* ********* Constants ********* */
  const materialsData =
    materials["supportedSytem" + process.env.REACT_APP_SUPPORTED_SYSTEM];
  const mainCarousel = materialsData.mainCarrosel.map((e, i) => (
    <Carousel.Item key={"crl-0-k" + i} interval={e.interval}>
      <img className="d-block w-100" src={e.img} />
      <div className="carousel-content">
        <Carousel.Caption>
          <h3>
            <Translate>{e.title1}</Translate>
          </h3>
          <p>
            <Translate>{e.title2}</Translate>
          </p>
          {/* <Link to="/login" className="btn btn-success">
            <Translate>{e.title3}</Translate>
          </Link> */}
        </Carousel.Caption>
      </div>
    </Carousel.Item>
  ));

  const eltProducts = [
    {
      id: 0,
      name: <Translate>ExecutingTheStandardsName</Translate>,
      description: <Translate>ExecutingTheStandardsDescription</Translate>,
      img: "/software/ets.png",
      video: "https://player.vimeo.com/video/892431982",
      internalName: "Executing the Standards ETS",
    },
    {
      id: 3,
      name: <Translate>GuestTrekRotationSystemName</Translate>,
      description: <Translate>GuestTrekRotationSystemDescription</Translate>,
      img: "/software/guestTrek.png",
      video: "https://player.vimeo.com/video/897906252",
      internalName: "GuestTrek Rotation ",
    },
    {
      id: 2,
      name: <Translate>GuestTrekExpressMenuName</Translate>,
      description: <Translate>GuestTrekExpressMenuDescription</Translate>,
      img: "/software/EM.png",
      video: "https://player.vimeo.com/video/476034913?h=7740ac3b68",
      internalName: "Express Menu ",
    },
    {
      id: 5,
      name: <Translate>GuestTrekeTrackerName</Translate>,
      description: <Translate>GuestTrekeTrackerDescription</Translate>,
      img: "/software/eTracker.png",
      video: "https://player.vimeo.com/video/883350608",
      internalName: "ETracker",
    },
    {
      id: 6,
      name: <Translate>GuestTrekTermsDiscoveryName</Translate>,
      description: <Translate>GuestTrekTermsDiscoveryDescription</Translate>,
      img: "/software/termsDiscovery.png",
      video: "https://player.vimeo.com/video/901579313",
      internalName: "Terms Discovery",
    },
  ];
  return (
    <>
      <ELTComponent>
        <div className="main-container">
          {/* <Splash /> */}

          <Carousel className="slider" pause={false} slide={true} wrap={true}>
            {mainCarousel}
          </Carousel>
        </div>
        <div className="container">
          {areGroupsLoading ? (
            <LoadingComponent isList={true} count={4}></LoadingComponent>
          ) : (
            groups?.map((g, i) => (
              <Group
                key={i}
                group={g}
                showModal={(p) => setModalShow(p)}
              ></Group>
            ))
          )}

          <div className="row">
            <div className="col-12 py-3">
              <div className="landing-Section-ec-banner d-flex">
                <div className="w-100 d-flex ">
                  <img src="SVG/expressCoach.svg" className="img-fluid" />
                </div>
                <div className="w-100 d-flex justify-content-end ">
                  <img src="expressCoachSample.png" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>

          {/* Performance Improvement Software Section */}
          <div className="row">
            <div className="col-12 py-3">
              <div className="landing-Section-Header">
                <div>
                  <h1>
                    <Translate>performanceImprovementSoftware</Translate>
                  </h1>
                  <p>{/* Build your pool of future leadership talent! */}</p>
                </div>
                {/* <button className="btn btn-success h-auto">
                  Buy Now!
                </button> */}
              </div>
              <div className="bkg-lightgray p-3">
                <div className="row learn-more-courses justify-content-center">
                  {eltProducts.map((p) => (
                    <div className="col-12 col-sm-6 col-md-4 col-lg-3 course">
                      <div className="card bottom-10 top-10 preview-card">
                        <div className="dashPrevThumb">
                          {/* <img src="course-images/leadership/LiveReadyIntro.jpg" /> */}
                          <a
                            className=""
                            onClick={() => setRequestDemoProduct(p)}
                          >
                            <img src={p.img} className="splash" />
                          </a>
                        </div>
                        <h5 className="padding-all-10 LNcourseTitle">
                          {p.name}
                          <sup>&reg;</sup>
                        </h5>
                        <div className="padding-all-10 LNcourseDesc">
                          {p.description}
                        </div>

                        {/* {!softwareList?.includes(p.id) && ( */}
                        <a onClick={() => setRequestDemoProduct(p)}>
                          <span className="btn btn-flat-background text-secondary width-100p top-5 text-capitalize border-t-papergray">
                            <Translate>moreInfo</Translate>
                          </span>
                        </a>
                        {/* )} */}
                        {/* <a onClick={() => setLearnMoreProduct(p)}>
                          <span className="btn btn-flat-background text-secondary width-100p top-5 text-capitalize border-t-papergray">
                            <Translate>moreInfo</Translate>
                          </span>
                        </a> */}

                        <div className="tooltip-content">
                          <h1 className="tooltip_title">
                            {p.name}
                            <sup>&reg;</sup>
                          </h1>
                          <p className="tooltip_description">{p.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          {/* Yes Our kids Can YOKC */}
          <div className="row">
            <div className="col-12 py-3">
              <div className="landing-Section-Header">
                <div>
                  <h1>
                    <Translate>forOurChildren</Translate>
                  </h1>
                  <p>{/* Build your pool of future leadership talent! */}</p>
                </div>
                {/* <button className="btn btn-success h-auto">
                  Buy Now!
                </button> */}
              </div>
              <div className="bkg-lightgray p-3">
                <div className="row learn-more-courses justify-content-center">
                  <div className="row">
                    <div className="col-8 col-md-4">
                      <img src="yokc-logo.svg" className="img-responsice" />
                    </div>

                    <div className="col-12 top-30">
                      <h5 className="top-0">
                        <Translate>YOKC.story.title</Translate> +{" "}
                        <Translate>YOKC.songs.title</Translate>
                      </h5>
                    </div>
                  </div>

                  <div className="row justify-content-lg-around">
                    <div className="col-12 col-md-6">
                      <div className="card bottom-10 top-10">
                        <div className="dashPrevThumb">
                          <img src="yokc-1.png" />
                        </div>
                        <h5 className="padding-all-10 LNcourseTitle">
                          Yes! Our Kids Can<sup>®</sup>{" "}
                          <Translate>YOKC.story.title</Translate>
                        </h5>
                        <div className="padding-all-10 LNcourseDesc">
                          <Translate>YOKC.storitime.description</Translate>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <div className="card bottom-10 top-10">
                        <div className="dashPrevThumb">
                          <img src="yokc-2.png" />
                        </div>
                        <h5 className="padding-all-10 LNcourseTitle">
                          Yes! Our Kids Can<sup>®</sup>{" "}
                          <Translate>YOKC.songs.title</Translate>
                        </h5>
                        <div className="padding-all-10 LNcourseDesc">
                          <Translate>YOKC.songs.description</Translate>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center '">
                    <div className="col-10 col-md-4 bottom-30 top-30">
                      <a
                        href={process.env.REACT_APP_YOKC_BUY_URL}
                        className="btn btn-success width-100p"
                      >
                        <Translate>YOKC.buy.btn</Translate>
                      </a>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-12">
                      <hr />
                    </div>

                    <div className="col-12 top-30">
                      <h5 className="top-0">
                        <Translate>YOKC.title.games</Translate> +{" "}
                        <Translate>YOKC.title.videos</Translate> +{" "}
                        <Translate>YOKC.title.ebooks</Translate>
                      </h5>
                    </div>
                  </div>

                  <div className="row justify-content-lg-around">
                    <div className="col-12 col-md-4">
                      <div className="card bottom-10 top-10">
                        <div className="dashPrevThumb">
                          <img src="yokc-3.png" />
                        </div>
                        <h5 className="padding-all-10 LNcourseTitle">
                          Yes! Our Kids Can<sup>®</sup>{" "}
                          <Translate>YOKC.title.games</Translate>
                        </h5>
                        <div className="padding-all-10 LNcourseDesc">
                          <Translate>YOKC.games.description</Translate>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="card bottom-10 top-10">
                        <div className="dashPrevThumb">
                          <img src="yokc-4.png" />
                        </div>
                        <h5 className="padding-all-10 LNcourseTitle">
                          Yes! Our Kids Can<sup>®</sup>{" "}
                          <Translate>YOKC.title.videos</Translate>
                        </h5>
                        <div className="padding-all-10 LNcourseDesc">
                          <Translate>YOKC.videos.description</Translate>
                        </div>
                      </div>
                    </div>

                    <div className="col-12 col-md-4">
                      <div className="card bottom-10 top-10">
                        <div className="dashPrevThumb">
                          <img src="yokc-5.png" />
                        </div>
                        <h5 className="padding-all-10 LNcourseTitle">
                          Yes! Our Kids Can<sup>®</sup>{" "}
                          <Translate>YOKC.title.ebooks</Translate>
                        </h5>
                        <div className="padding-all-10 LNcourseDesc">
                          <Translate>YOKC.ebooks.description</Translate>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center '">
                    <div className="col-10 col-md-4 bottom-30 top-30">
                      <a
                        href={process.env.REACT_APP_YOKC_BUY_URL}
                        className="btn btn-success width-100p"
                      >
                        <Translate>YOKC.buy.btn</Translate>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="padding-top-10 padding-bottom-10 bkg-papergray">
          <div className="container">
            <div className="row d-flex flex-row align-items-lg-stretch">
              <div className="col-12 col-sm-6 col-md-3 py-2">
                
                <a href="https://www.eleadertech.com" target="_blank" rel="noopener noreferrer" className="d-block width-100p height-100p bkg-background rounded-2 shadow-sm pt-2 text-decoration-none padding-bottom-80 position-relative">
                  <h5 className="text-accent4 text-center">
                    <Translate>eltAdCard.title</Translate>
                  </h5>
                  <p className="text-center text-main">
                    <Translate>eltAdCard.text</Translate>
                  </p>
                  <span className="adCardElt">
                    <img src="../eLeaderTechLogoWhite.svg" className=" img-fluid" />
                  </span>
                </a>

              </div>
              <div className="col-12 col-sm-6 col-md-3 py-2">
                
                <a href="https://www.livereadyinstitute.com" target="_blank" rel="noopener noreferrer" className="d-block width-100p height-100p bkg-background rounded-2 shadow-sm pt-2 text-decoration-none padding-bottom-80 position-relative">
                  <h5 className="text-accent4 text-center">
                    <Translate>LRAdCard.title</Translate>
                  </h5>
                  <p className="text-center text-main">
                    <Translate>LRAdCard.text</Translate>
                  </p>
                  <span className="adCardLR">
                    <img src="../LiveReadyLogoHorizontalWhiteAndRed.svg" className=" img-fluid" />
                  </span>
                </a>
              </div>
              <div className="col-12 col-sm-6 col-md-3 py-2">
                
                <a href="http://davidribarra.com/" target="_blank" rel="noopener noreferrer" className="d-block width-100p height-100p bkg-background rounded-2 shadow-sm pt-2 text-decoration-none padding-bottom-80 position-relative">
                  <h5 className="text-accent4 text-center">
                    David R. Ibarra
                  </h5>
                  <p className="text-center text-main">
                    <Translate>DIAdCard.text</Translate>
                  </p>
                  <span className="adCardDI">
                    <img src="../DavidLogo.svg" className=" img-fluid" />
                  </span>
                </a>

              </div>
              <div className="col-12 col-sm-6 col-md-3 py-2">
                <a href="https://www.yesourkidscan.com/" target="_blank" rel="noopener noreferrer" className="d-block width-100p height-100p bkg-background rounded-2 shadow-sm pt-2 text-decoration-none padding-bottom-80 position-relative">
                  <h5 className="text-accent4 text-center">
                    Yes! Our Kids Can
                  </h5>
                  <p className="text-center text-main">
                    <Translate>YesOKCAdCard.text</Translate>
                  </p>
                  <span className="adCardYOKC">
                    <img src="../horizontal-logo_yokc.svg" className=" img-fluid" />
                  </span>
                </a>
              </div>

            </div>
          </div>
        </div>


        <div className="bkg-lightgray padding-top-10 padding-bottom-10">
          <div className="container">
            <div className="row align-items-center bottom-0 justify-content-center">
              <div className="col-10 col-md-4 top-20 bottom-20 d-flex bottom-0 justify-content-center align-items-center">
                <span className="material-icons text-success right-10 fs-1">
                  play_circle
                </span>
                <h2 className="sentence fw-bolder bottom-0">
                  <Translate>Landing_LearnInDemand</Translate>
                </h2>
              </div>

              <div className="col-10 col-md-4 top-20 bottom-20 d-flex bottom-0 justify-content-center align-items-center">
                <span className="material-icons text-success right-10 fs-1">
                  groups
                </span>
                <h2 className="sentence fw-bolder bottom-0">
                  <Translate>Landing_ChooseCourse</Translate>
                </h2>
              </div>

              <div className="col-10 col-md-4 top-20 bottom-20 d-flex bottom-0 justify-content-center align-items-center">
                <span className="material-icons text-success right-10 fs-1">
                  all_inclusive
                </span>
                <h2 className="sentence fw-bolder bottom-0">
                  <Translate>Landing_LearnAtYourPace</Translate>
                </h2>
              </div>
            </div>
          </div>
        </div>
        
      </ELTComponent>
      {
        <LandingModuleModalComponent
          closeModal={() => setModalShow(null)}
          selectedModule={modalShow}
        />
      }
      {requestDemoProduct != null && (
        <RequestDemoModal
          softwareToRequest={requestDemoProduct}
          closeModal={() => {
            setRequestDemoProduct(null);
          }}
          selectedProduct={requestDemoProduct}
        />
      )}
    </>
  );
};

export default MainScreenComponent;
