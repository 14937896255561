import { BrowserRouter, Route, Routes } from "react-router-dom";

import LoginPage from "./login-components";
import ResetToken from "./login-components/ResetTokenPasswordChange";

import UnderConstruction from "./shared/UnderConstructionPage";
import SignOutPage from "./shared/Signout";
import Offline from "./shared/Offline";

//Landing Page Components
import MainScreenComponent from "./landing-components";
import LandingNewDesing from "./landing-components";
import CategoryPreviewComponent from "./landing-components/CategoryPreview";
import DashboardComponent from "./dashboard-components/Index";
import CourseDetailsComponent from "./course-details-components/Index";
import CourseVideoComponent from "./course-video-components/Index";
import CourseAudioPlayer from "./course-audio-player/Index";
import CourseVideoPlayer from "./course-video-player-components/Index";
import CourseDetails from "./landing-components/CourseDetails";

import UserAdmin from "./employee-license-components";
import LicenseActivation from "./employee-license-components/LicenseActivation";

//Quiz Components
import QuizComponent from "./quiz-components/Index";
import QuestinaryComponent from "./quiz-components/Questionary";
import QuizCompletedComponent from "./quiz-components/QuizFinish";

import EpubViewerComponent from "./epub-viewer-components";
import HighlightsAccesComponent from "./epub-viewer-components/HighlightsNotesAccess";
import {
  PaymentResult,
  SubscriptionPlan,
} from "./subscription-plan-components";

import Company from "./landing-components/Company";

import CertificatesComponent from "./certificates-components/Index";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainScreenComponent></MainScreenComponent>} />
        <Route path="/new" element={<LandingNewDesing></LandingNewDesing>} />
        <Route path="/coursedetails" element={<CourseDetails />} />
        <Route path="/landing/course/:courseId" element={<CourseDetails />} />
        <Route
          path="/landing/category/:categoryId"
          element={<CategoryPreviewComponent></CategoryPreviewComponent>}
        />

        <Route path="/about" element={<Company></Company>} />
        <Route
          path="/landing"
          element={<MainScreenComponent></MainScreenComponent>}
        />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/login/CAS/:refreshtokenId" element={<LoginPage />} />
        <Route
          path="/subscriptionPlan/:idSeminar"
          element={<SubscriptionPlan></SubscriptionPlan>}
        />
        <Route
          path="/subscriptionPlan/:idSeminar?/:countries?"
          element={<SubscriptionPlan></SubscriptionPlan>}
        />
        <Route
          path="/paymentResult"
          element={<PaymentResult></PaymentResult>}
        />
        <Route
          path="/dashboard"
          element={<DashboardComponent></DashboardComponent>}
        />
        <Route
          path="/course/:courseId"
          element={<CourseDetailsComponent></CourseDetailsComponent>}
        />
        <Route
          path="/course/:courseId/lesson/:id"
          element={<CourseVideoComponent></CourseVideoComponent>}
        ></Route>
        <Route
          path="/course-audio/:courseId"
          element={<CourseAudioPlayer></CourseAudioPlayer>}
        ></Route>
        <Route
          path="/course-video/:courseId"
          element={<CourseVideoPlayer></CourseVideoPlayer>}
        ></Route>
        <Route path="/passwordChange/:token" element={<ResetToken />} />
        {/* Not yet developed/used components/containers */}
        <Route path="/signout" element={<SignOutPage />} />
        <Route path="/underConstruction" element={<UnderConstruction />} />
        <Route path="/offline" element={<Offline />} />
        <Route
          path="/course/:courseId/quiz"
          element={<QuizComponent></QuizComponent>}
        ></Route>
        <Route
          path="/course/:courseId/quiz/questionary"
          element={<QuestinaryComponent></QuestinaryComponent>}
        ></Route>
        <Route
          path="/course/:courseId/quiz/results"
          element={<QuizCompletedComponent></QuizCompletedComponent>}
        ></Route>
        <Route
          path="/course/:courseId/epub"
          element={<EpubViewerComponent />}
        />
        <Route path="/guide/:guideId" element={<EpubViewerComponent />} />
        <Route
          path="/course/:courseId/epub/access"
          element={<HighlightsAccesComponent />}
        />
        <Route path="/certificates/:uuid" element={<CertificatesComponent />} />

        <Route path="/useradmin" element={<UserAdmin />} />
        <Route
          path="/license/:activationToken/activation"
          element={<LicenseActivation />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
