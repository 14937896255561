import React from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Translate from "translate-components";

/* ********* Components ********* */
import ELTComponentAuth from "../shared/ELTComponentAuth";
import VideoPlayer from "./VideoPlayer";

/* ********* Actions ********* */
import * as landingActions from "../../data/Landing/landingActions";

import "../../styles/styles.scss";
import { decodeNumber } from "../../utils/strings";
const CourseVideoPlayer = () => {
  /* ********* Uses ********* */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courseId } = useParams();

  /* ********* Reducer Variables ********* */
  const userSession = useSelector((state: RootStateOrAny) => state.userSession);
  const videoUrl = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaUrl
  );
  const videoLocation = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaLocation
  );
  const videoDuration = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaDuration
  );
  const videoName = useSelector(
    (state: RootStateOrAny) => state.additionalMedia.mediaName
  );

  /* ********* Functions ********* */
  const completeCourse = () => {
    const employeeId = userSession.userId;
    if (employeeId > 0 && courseId != null && courseId != "")
      dispatch(
        landingActions.setCourseCompleted(employeeId, decodeNumber(courseId))
      );
  };

  return (
    <ELTComponentAuth>
      <div id="VideoItem">
        <div className="header-spacer" />
        <>
          <div className="videoTitle">
            <h1>{videoName}</h1>
          </div>

          <div>
            <VideoPlayer
              videoSource={videoUrl}
              //videoTitle={videoName}
              //videoLocation={videoLocation}
              //videoDuration={videoDuration}
              completeCourse={completeCourse}
              mustCompleteCourse={true}
            />
          </div>

          <div className="video-footer w-100"></div>
          <div className="bottom-section d-flex justify-content-end">
            <div className="">
              <div
                onClick={() => {
                  navigate(-1);
                }}
                className="btn btn-success"
              >
                <span>
                  <Translate>gotocourse</Translate>
                </span>
              </div>
            </div>
          </div>
        </>
      </div>
    </ELTComponentAuth>
  );
};
export default CourseVideoPlayer;
