import * as types from "../constants/actionTypes";
//import AuthenticationServicesInstance from "../services/authenticationServices";

//const authenticationServices = new AuthenticationServicesInstance(config);

export function saveMediaProperties(url, name, location, duration) {
  return function(dispatch) {
    dispatch({
      type: types.SAVE_MEDIA_PROPERTIES,
      payload: { url, name, location, duration },
    });
  };
}

export function clearMediaProperties() {
  return function(dispatch) {
    dispatch({ type: types.CLEAR_MEDIA_PROPERTIES });
  };
}
