import * as types from "../constants/actionTypes";
import EmployeeLicenseInstance from "../services/employeeLicenseServices";

const employeeServices = new EmployeeLicenseInstance();

export function retrieveEmployeeLincense(idEmployee) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });

    employeeServices
      .getByEmployeeId(idEmployee)
      .then((response) => {
        dispatch({
          type: types.RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_FULFILLED,
          payload: { data: response.data },
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.RETRIEVE_EMPLOYEELINCENSE_BY_EMPLOYEEID_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}

export function enrollEmployeeSeat(idEmployee, employee) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });

    employeeServices
      .enrollEmployeeSeat(idEmployee, employee)
      .then((response) => {
        dispatch({
          type: types.ENROLL_EMPLOYEELINCENSE_FULFILLED,
          payload: { data: response.data },
          error: null,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.ENROLL_EMPLOYEELINCENSE_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}

export function activateEmployeeSeat(ptoken) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });

    employeeServices
      .activateSeat(ptoken)
      .then(({ data }) => {
        dispatch({
          type: types.ACTIVATE_EMPLOYEE_FULFILLED,
          payload: { data },
          error: null,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.ACTIVATE_EMPLOYEE_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}
export function deleteEmployeeSeat(pproduct, pid) {
  return function(dispatch) {
    dispatch({ type: types.REQUEST_DEPARTED });

    employeeServices
      .deleteEmployeeSeat(pid)
      .then(() => {
        dispatch({
          type: types.DELETE_LICENSE_FULFILLED,
          payload: { data: { productId: pproduct, seatId: pid } },
          error: null,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_LICENSE_EMPLOYEE_ERROR,
          payload: error.response,
        });
        dispatch({ type: types.REQUEST_ARRIVED });
      });
  };
}
