import React, { useState, useContext } from "react";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

import { ThemeContext } from "../../themes/themeContext";
//Images
import LogoDefault from "../../assets/images/Logo.png";
import titleLogoDefault from "../../assets/images/svg/header-corp-white.svg";

const Footer = () => {
  const theme = useContext(ThemeContext);
  const [modalShow, setModalShow] = useState(false);

  return (
    <>
      <div
        className="footer"
        style={{
          background: theme.header.background,
          color: theme.header.color,
        }}
      >
        <div className="d-flex justify-content-end w-100">
          <a
            style={{ color: theme.header.color }}
            onClick={() => setModalShow(true)}
            className=" bottom-5 right-10"
          >
            <Translate>privacy.policy</Translate>
          </a>
        </div>

        <div className="d-block d-flex flex-column flex-md-row justify-content-between align-items-center left-10 right-10 border-t-primary padding-top-10">
          <div className="col-6 col-md-2">
            <Link className="header-logo" to="/">
              <img src={titleLogoDefault} alt="img-fluid" />
            </Link>
          </div>

          <div className="padding-all-10">
            {/* &copy;&nbsp;2022 Beyond Think Grow Rich Institute */}
            <a
              href="https://www.eleadertech.com"
              target="_blank"
              rel="noopener noreferrer"
              className="footerLegend"
              style={{ color: theme.header.color }}
            >
              Powered by eLeaderTech
            </a>
          </div>
        </div>
      </div>

      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={modalShow}
        onHide={() => setModalShow(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            <Translate>privacy.policy</Translate>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ maxHeight: "400px", overflow: "scroll" }}>
          <p>
            <Translate>privacy.policy.protecting.information</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.collection.information</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.in.order</Translate>{" "}
          </p>
          <ul>
            <li>
              <Translate>privacy.policy.name</Translate>
            </li>
            <li>
              <Translate>privacy.policy.company</Translate>
            </li>
            <li>
              <Translate>privacy.policy.mail</Translate>
            </li>
            <li>
              <Translate>privacy.policy.email</Translate>
            </li>
            <li>
              <Translate>privacy.policy.phone</Translate>
            </li>
            <li>
              <Translate>privacy.policy.country</Translate>
            </li>
            <li>
              <Translate>privacy.policy.language</Translate>
            </li>
          </ul>

          <p>
            <Translate>privacy.policy.preference</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.use.information</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.use.information1</Translate>
          </p>
          <p>
            <Translate>privacy.policy.use.information2</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.sharing.information</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.sharing.information1</Translate>
          </p>
          <p>
            <Translate>privacy.policy.sharing.information2</Translate>
          </p>
          <p>
            <Translate>privacy.policy.sharing.information3</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.tracking.behavior</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.tracking.behavior1</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.collected.info</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.collected.info1</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.use.cookies</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.use.cookies1</Translate>
          </p>
          <p>
            <Translate>privacy.policy.use.cookies2</Translate>
          </p>
          <p>
            <Translate>privacy.policy.use.cookies3</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.security.info</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.security.info1</Translate>
          </p>
          <ul>
            <li>
              <Translate>privacy.policy.ssl</Translate>
            </li>
          </ul>

          <p>
            <Translate>privacy.policy.security.info2</Translate>
          </p>
          <p>
            <Translate>privacy.policy.security.info3</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.right.deletion</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.right.deletion1</Translate>
          </p>
          <ul>
            <li>
              <Translate>privacy.policy.right.deletion2</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion3</Translate>
            </li>
          </ul>
          <p>
            <Translate>privacy.policy.right.deletion4</Translate>
          </p>
          <ul>
            <li>
              <Translate>privacy.policy.right.deletion5</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion6</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion7</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion8</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion9</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion10</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion11</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion12</Translate>
            </li>
            <li>
              <Translate>privacy.policy.right.deletion13</Translate>
            </li>
          </ul>

          <h4>
            <Translate>privacy.policy.under13</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.under13.1</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.email.communication</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.email.communication1</Translate>
          </p>
          <p>
            <Translate>privacy.policy.email.communication2</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.data.storage</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.data.storage1</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.changes</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.changes1</Translate>
          </p>
          <h4>
            <Translate>privacy.policy.contact.info</Translate>
          </h4>
          <p>
            <Translate>privacy.policy.contact.info1</Translate>
          </p>
          <p>David Ibarra Enterprises, Inc.</p>
          <p>438 E 200 S</p>
          <p>Salt Lake City, UT 84111</p>
          <p>
            <Translate>privacy.policy.contact.info2</Translate>
          </p>
          <p>
            <Translate>privacy.policy.contact.info3</Translate>
          </p>
          <br></br>
          <p>
            <Translate>privacy.policy.contact.info4</Translate>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>
            <Translate>privacy.policy.close</Translate>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default Footer;
