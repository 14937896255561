import React from "react";
import PropTypes from "prop-types";

import Translate from "translate-components";
import CourseItemComponent from "./CourseItem";
import { encodeNumber } from "../../utils/strings";

type CourseListProps = {
  userName: string;
  courses: [any];
};

const CourseListComponent = ({ courses, userName }: CourseListProps) => {
  return (
    <>
      <div className="dashcoursesArea">
        <div className="container">
          <div className="row">
            {courses.length > 0 ? (
              courses.map((item, index) => (
                <CourseItemComponent
                  userName={userName}
                  key={index}
                  item={item}
                  goToCourse={() => "/course/" + encodeNumber(item.id)}
                ></CourseItemComponent>
              ))
            ) : (
              <div className="no-courses-message">
                <span>
                  <Translate>coursestoshow</Translate>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CourseListComponent;
