import axios from "axios";

class CertificatsServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;

    this.token = localStorage.getItem("token");

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  getByUUID(puuid) {
    const theUrl = this.tgrApi + "/AuthenticityCertificate/GetById/" + puuid;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  gerateCertificate(userId, fileName, courseId, lang, ptoken) {
    const el = this;
    return new Promise(function(resolve, reject) {
      const supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

      const theUrl = `${process.env.REACT_APP_API_URL}/PDF/GetCertificatePhase/V3/${supportedSystem}/${userId}/${courseId}/${lang}`;
      var postData = new FormData();
      var xhr = new XMLHttpRequest();
      xhr.responseType = "blob";
      xhr.open("POST", theUrl);
      xhr.setRequestHeader("Authorization", "Bearer " + ptoken);

      xhr.onload = function(e) {
        if (xhr.status >= 200 && xhr.status < 300) {
          var blob = xhr.response;
          el.saveBlob(blob, fileName.trim());
          resolve(true);
        } else {
          reject({
            status: xhr.status,
            statusText: xhr.statusText,
          });
        }
      }.bind(this);
      xhr.send(postData);
    });
  }

  saveBlob(blob, fileName) {
    var a = document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = fileName;
    a.dispatchEvent(new MouseEvent("click"));
  }
}

export default CertificatsServices;
