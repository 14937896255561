import * as types from "../constants/actionTypes";
import AuthenticationServicesInstance from "../services/authenticationServices";

const authenticationServices = new AuthenticationServicesInstance();

/*****/
/* AUTHENTICATES THE USER USING GIVEN USERNAME AND PASSWORD */
export function authenticateUser(userName, password) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .authenticateWithCredentials(userName, password)
      .then((authResponse) => {
        dispatch({ type: types.AUTHENTICATE, payload: authResponse });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ERROR, payload: error.response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function resetPassword(userName, lang, supportedSystem) {
  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .resetPassword(userName, lang, supportedSystem)
      .then((response) => {
        dispatch({ type: types.RESET_PASSWORD_FULFILLED, payload: response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.RESET_PASSWORD_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

/*****/
/* AUTHENTICATES THE USER USING A SAVED REFRESH TOKEN */
export function authenticateWithToken() {
  const token = localStorage.getItem("token");

  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .authenticateWithToken(token)
      .then((authResponse) => {
        authResponse.data.token = token;
        dispatch({ type: types.AUTHENTICATE, payload: authResponse });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ERROR, payload: error.response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
/*****/
/* AUTHENTICATES THE USER USING TOKEN */
export function authenticateUserTGRToken(token) {
  return function(dispatch) {
    authenticationServices
      .authenticateWithTGRToken(token)
      .then((eltResponse) => {
        dispatch({
          type: types.AUTHENTICATE,
          payload: eltResponse,
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ERROR, payload: error.response });
      })
      .finally(() => {
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
/* AUTHENTICATES THE USER USING THE CAS REFRESH TOKEN */
export function authenticateUserCASToken(token) {
  return function(dispatch) {
    authenticationServices
      .authenticateWithCASRefresh(token)
      .then((eltResponse) => {
        dispatch({
          type: types.AUTHENTICATE,
          payload: { data: eltResponse.data, casToken: token },
        });
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_ERROR, payload: error.response });
      });
  };
}

export function updatePassword(userId, newPassword, newPasswordConfirmation) {
  const token = localStorage.getItem("token");

  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .updatePassword(userId, newPassword, newPasswordConfirmation, token)
      .then((response) => {
        dispatch({ type: types.UPDATE_PASSWORD_FULFILLED, payload: response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PASSWORD_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}

export function updatePasswordForSeat(
  userId,
  newPassword,
  newPasswordConfirmation
) {
  const token = localStorage.getItem("token");

  return function(dispatch) {
    dispatch({ type: types.FLIGHT_STARTED });
    authenticationServices
      .updatePasswordForSeat(
        userId,
        newPassword,
        newPasswordConfirmation,
        token
      )
      .then((response) => {
        dispatch({ type: types.UPDATE_PASSWORD_FULFILLED, payload: response });
        dispatch({ type: types.FLIGHT_ARRIVED });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_PASSWORD_REJECTED,
          payload: error.response,
        });
        dispatch({ type: types.FLIGHT_ARRIVED });
      });
  };
}
export function signOut() {
  return function(dispatch) {
    dispatch({ type: types.SIGNOUT });
  };
}

export function setErrorMessage(errorMessage) {
  return function(dispatch) {
    dispatch({ type: types.AUTH_ERROR, payload: errorMessage });
  };
}
