import * as React from "react";
import * as actions from "../../data/EmployeeLicense/employeeLicenseActions";
import { useDispatch } from "react-redux";


const ProductItemUsedLicense = ({
  employeeSeat,
  productId,
}) => {
  const dispacth = useDispatch();
  return (
    <div className="col-12 col-md-6 my-2">
      <div className="border-b-accent3">
        <div className="row align-items-center py-1">
          <div className="col-12 col-lg-4">{employeeSeat?.fullname}</div>
          <div className="col-12 col-lg-4">{employeeSeat?.email}</div>
          <div className="col-12 col-lg-4 text-right d-flex justify-content-end">
            {employeeSeat.status == 0 && (
              <button
                className="btn btn-sm btn-flat-background text-warning d-flex"
                onClick={() =>
                  dispacth(
                    actions.deleteEmployeeSeat(
                      productId,
                      employeeSeat.idEmployeeLicenseSeat
                    )
                  )
                }
              >
                <span>Remove</span>
                &nbsp;
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="width-20 height-20">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                </svg>

              </button>
            )}
          </div>

        </div>
        
      </div>
    </div>
  );
};

export default ProductItemUsedLicense;
