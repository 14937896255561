import React, { useEffect } from "react";
import { useDispatch, useSelector, RootStateOrAny } from "react-redux";
import { useParams, Navigate } from "react-router-dom";
import Translate from "translate-components";
/* ********* Actions ********* */
import * as resetTokenActions from "../../data/ResetTokenPassword/resetTokenPasswordActions";
/* ********* Components ********* */
import PasswordChange from "./PasswordChange";
const ResetTokenPasswordChange = () => {
  /* ********* Uses ********* */
  const { token } = useParams();
  const dispatch = useDispatch();

  /* ********* Reducer Variables ********* */
  const passwordValidation = useSelector(
    (state: RootStateOrAny) => state.resetToken.passwordValidation
  );
  const onValidation = useSelector((state:RootStateOrAny) => state.resetToken.onValidation);
  const passwordUpdated = useSelector(
    (state:RootStateOrAny) => state.resetToken.passwordUpdated
  );
  
  /* ********* Effects ********* */
  useEffect(() => {
    dispatch(resetTokenActions.validateToken(token));
  }, []);

  const passwordChange =
    passwordValidation != null &&
    onValidation == false &&
    passwordValidation.userId > 0 ? (
      <div>
        <PasswordChange
          pvalidation={passwordValidation}
          ptoken={token}
        ></PasswordChange>
      </div>
    ) : (
      <div className="container top-10">
        <div className="col-12">
          <div className="card p-2">
            <div className="col-12 text-center">
              <p>
                <Translate>Reset_Token_Wait</Translate>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  const passwordUpdatedCompnent = (
    <div className="container top-10">
      <div className="col-12">
        <div className="card p-2">
          <div className="col-12 text-center">
            <div className="row">
              <label>
                <Translate>Reset_Token_SuccessfullyUpdate</Translate>
              </label>
            </div>
            <a className="btn btn-success top-10" href="/login">
              <Translate>Reset_Token_Login</Translate>
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  const redirect =
    passwordValidation != null &&
    onValidation == false &&
    passwordValidation.userId > 0 ? (
      passwordUpdatedCompnent
    ) : (
      <div className="container top-10">
        <div className="col-12">
          <div className="card p-2">
            <div className="col-12 text-center">
              <div className="row">
                <label>
                  <Translate>Reset_Token_Expired</Translate>
                </label>
              </div>

              <a className="btn btn-success top-10" href="/">
                <Translate>Reset_Token_Home</Translate>
              </a>
            </div>
          </div>
        </div>
      </div>
    );

  return passwordUpdated == true
    ? passwordUpdatedCompnent
    : passwordValidation != null &&
      onValidation == false &&
      passwordValidation.userId == 0
    ? redirect
    : passwordChange;
};

export default ResetTokenPasswordChange;
