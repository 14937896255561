import React from "react";
import { Navigate } from "react-router-dom";

import userSessionExtension from "../../data/UserSession/userSessionExtension";
import ELTComponent from "./ELTComponent";
const ELTComponentAuth = (props) => {
  return userSessionExtension.isLoggedIn(
    localStorage.getItem("token"),
    localStorage.getItem("expire")
  ) ? (
    <ELTComponent>{props.children}</ELTComponent>
  ) : (
    <Navigate
      replace
      to={{
        pathname: "/login",
        state: { from: props.location },
      }}
    ></Navigate>
  );
};
export default ELTComponentAuth;
