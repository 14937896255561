import axios from "axios";

class WishlistServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi,
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  getByEmployeeId(employeeId) {
    const theUrl =
      this.tgrApi +
      "/EmployeeWishlist/GetAllByEmployee/" +
      this.supportedSystem +
      "/" +
      employeeId;

    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  addToWishlist(employeeId, idModule) {
    const theUrl = this.tgrApi + "/EmployeeWishlist/AddToWishlist";

    return this.axiosClient
      .post(`${theUrl}`, {
        IdEmployee: employeeId,
        IdModule: idModule,
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
  removeToWishlist(employeeId, idModule) {
    const theUrl =
      this.tgrApi +
      "/EmployeeWishlist/RemoveToWishlist/" +
      employeeId +
      "/" +
      idModule;

    return this.axiosClient.delete(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default WishlistServices;
