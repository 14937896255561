import axios from "axios";
//import querystring from 'querystring';

class ReadingServices {
  constructor(tokenWT) {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;

    if (tokenWT === undefined) {
      this.token = localStorage.getItem("token");
    } else {
      this.tokenWT = tokenWT;
    }

    this.axiosClient = axios.create({
      baseURL: this.tgrApi + "/InstituteReading/",
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        Accept: "application/json",
        Authorization: "Bearer " + this.token,
      },
      credentials: "same-origin",
    });
  }

  getBookmarks(idEmployee, idCourse, lang) {
    const theUrl = `GetBookmarksByEmployeeAsync/${idEmployee}/${idCourse}/${lang}`;
    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getHighlights(idEmployee, idCourse, lang) {
    const theUrl = `GetHighlightsByEmployee/${idEmployee}/${idCourse}/${lang}`;
    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getConfiguration(idEmployee) {
    const theUrl = `GetConfigurationByEmployee/${idEmployee}`;
    return this.axiosClient.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  saveBookmarks(idEmployee, idCourse, bookMarks, lang) {
    const theUrl = `SaveBookmarks/${idEmployee}/${idCourse}/${lang}`;
    return this.axiosClient.post(`${theUrl}`, bookMarks).catch((error) => {
      return Promise.reject(error);
    });
  }

  saveHighlights(idEmployee, idCourse, highlights, lang) {
    const theUrl = `SaveReadingHighlights/${idEmployee}/${idCourse}/${lang}`;
    return this.axiosClient.post(`${theUrl}`, highlights).catch((error) => {
      return Promise.reject(error);
    });
  }

  saveConfiguration(pconfiguration) {
    const theUrl = `SaveReadingConfiguration`;
    return this.axiosClient.post(`${theUrl}`, pconfiguration).catch((error) => {
      return Promise.reject(error);
    });
  }
  saveLastLocation(idEmployee, idCourse, plocation, lang) {
    const theUrl = `SaveLastLocation/${idEmployee}/${idCourse}/${lang}`;
    return this.axiosClient
      .post(`${theUrl}`, { location: JSON.stringify(plocation) })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  sendHighlightsByEmail(idEmployee, idCourse, lang, highlights) {
    const theUrl = `SendHighlightsByEmail/${idEmployee}/${idCourse}/${lang}`;
    return this.axiosClient.post(`${theUrl}`, highlights).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default ReadingServices;
