import imgWhatsapp from "../assets/images/social-media/006-whatsapp.svg";
import imgFacebookLetter from "../assets/images/social-media/001-facebook-letter-logo.svg";
import imgTwitter from "../assets/images/social-media/003-twitter-logo-silhouette.svg";
import imgInstagram from "../assets/images/social-media/005-instagram.svg";

const liveReady = {
  background: null,
  color: null,
  //Header Menu  & Menu Items
  header: {
    color: '#cdcdcd',
    background: '#191919',
    menu_item: {
      color: null,
    },
    inner_item: {
      color: null,
      background: "#000",
    },
  },
  footer: "LandingPage.Footer.PrivacyPolicy",
  mediaLinks: [
    {
      href: "https://wa.me/50660461370",
      target: "_blank",
      title: "whatsapp",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgWhatsapp,
    },
    {
      href: "https://www.facebook.com/institutotgrla/",
      target: "_blank",
      title: "facebook",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgFacebookLetter,
    },
    {
      href: "https://twitter.com/institutotgrla",
      target: "_blank",
      title: "twitter",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgTwitter,
    },
    {
      href: "https://www.instagram.com/institutotgrla/",
      target: "_blank",
      title: "instagram",
      className: "social-media",
      rel: "noopener noreferrer",
      img: imgInstagram,
    },
  ],
  showBrowseCategories: true,
};
export default liveReady;
