import Header from "./Header";
import Footer from "./Footer";
import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../../data/UserSession/userSessionActions";
import Translate from "translate-components";
import Modal from "react-bootstrap/Modal";

const ELTComponent = (props) => {
  const userSession = useSelector((state) => state.userSession);
  const isPaymentRequired = useSelector(
    (state) => state.application.isPaymentRequired
  );
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(userActions.signOut());
  };

  return (
    <>
      {isPaymentRequired && (
        <>
          <Modal
            size="lg"
            centered
            aria-labelledby="contained-modal-title-vcenter"
            show={true}
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                <Translate>subscription.error</Translate>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h3>
                <Translate>subscription.error.description</Translate>
              </h3>
              <a href="/" className="btn btn-success width-120p">
                <Translate>subscription.error.gotodashboard</Translate>
              </a>
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>
        </>
      )}
      <>
        {props.hideHeaderFooter == undefined && (
          <Header
            userSession={userSession}
            logout={logOut}
            redirectUrl={process.env.REACT_APP_SIGNUP_URL}
            showLogin={props.showLogin == null ? true : props.showLogin}
          ></Header>
        )}
        {props.children}
        {props.hideHeaderFooter == undefined && <Footer />}
      </>
    </>
  );
};
export default ELTComponent;
