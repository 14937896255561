import axios from "axios";
//import querystring from 'querystring';

class ProductServices {
  constructor() {
    this.grantType = process.env.REACT_APP_API_GRANT_TYPE;
    this.tgrApi = process.env.REACT_APP_API_URL;
    this.supportedSystem = process.env.REACT_APP_SUPPORTED_SYSTEM;
  }

  getById(id, lang) {
    const theUrl =
      this.tgrApi + "/InstituteProduct/GetProduct/" + id + "/" + lang;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }

  getAllProducts(lang) {
    const theUrl =
      this.tgrApi +
      "/InstituteProduct/GetAllProducts/" +
      this.supportedSystem +
      "/" +
      lang;

    return axios.get(`${theUrl}`).catch((error) => {
      return Promise.reject(error);
    });
  }
}

export default ProductServices;
