import React from "react";
import { useDispatch } from "react-redux";
import * as quizActions from "../../data/Quiz/quizActions";

const AnswerComponent = ({
  /* eslint-disable no-unused-vars */
  id,
  description,
  isCorrectAnswer,
  fkInstituteQuizQuestion,
  idInstituteQuestionByQuiz,
  disabled
  /* eslint-enable no-unused-vars */
}) => {
  const dispatch = useDispatch();
  return (
    <div className="col-12">
      <div className="answer">
        <input
          type="radio"
          id={"answer" + id}
          name={fkInstituteQuizQuestion}
          value={id}
          onClick={() => {
            dispatch(quizActions.answerQuestion(idInstituteQuestionByQuiz, id));
          }}
          disabled={disabled}
        />
        <label htmlFor={"answer" + id}>{description}</label>
      </div>
    </div>
  );
};

export default AnswerComponent;
