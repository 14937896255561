import * as types from "../constants/actionTypes";

const initialState = {
  mediaUrl: null,
  mediaName: null,
  mediaLocation: null,
  mediaDuration: null
};

export default function additionalMediaReducer(state = initialState, action) {
  //let errorMessage = "You've found an unkown glitch! Please contact support.";

  switch (action.type) {
    case types.SAVE_MEDIA_PROPERTIES: {
      let { url, name, location, duration } = action.payload;

      return {
        ...state,
        mediaUrl: url,
        mediaName: name,
        mediaLocation: location,
        mediaDuration: duration
      };
    }

    case types.CLEAR_MEDIA_PROPERTIES: {
      return {
        ...state,
        mediaUrl: null,
        mediaName: null,
        mediaLocation: null,
        mediaDuration: null
      };
    }

    default:
      return state;
  }
}
